import React, { Fragment, useState } from "react";
import "@fortawesome/free-solid-svg-icons";
import { Tabs, Tab } from "react-bootstrap";
import EliteInfo from "./EliteInfo";
import Members from "./Members";
import Leads from "./Leads";
import PaymentSchedule from "./PaymentSchedule";
import PaymentItems from "./PaymentItems";
import util from "../utils/miniUtils";
import AllEliteConnect from "./AllEliteConnect";



function EliteConnectAdmin() {
    const [selectedElite , setSelectedElite ] = useState( null );

    return (
        <Fragment>
            <div className="row mt-4">
                <div className="col-md-12">
                    
                    <Tabs mountOnEnter={true} unmountOnExit={true} defaultActiveKey="about" >
                        
                        <Tab eventKey="about" title="Manage Elite Connects">
                            <AllEliteConnect selectedElite={selectedElite} setSelectedElite={setSelectedElite} />
                        </Tab>
                        <Tab eventKey="payment_items" title="Payment Items">
                            <PaymentItems />
                        </Tab>
                    </Tabs>
                    
                    
                </div>
            </div> 
        </Fragment>
    );
}

export default EliteConnectAdmin;
