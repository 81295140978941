import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import util from '../../utils/miniUtils';
import AmFieldForm from './AmFieldForm';

const AmFieldCategoryList = ( { list, category, deleteItem, createField, getFieldList } ) => {
    const [show, setShow] = useState( false );
    const [isNew, setIsNew] = useState( true );
    const [fieldData, setFieldData] = useState( {} );

    const handleclose = () => {
        setShow( false );
        setIsNew( true );
    };

    const handleEditField = ( data ) => {
        setFieldData( data );
        setShow( true );
        setIsNew( false );
    };

    const updateField = async ( data ) => {
        try {
            const request = await util.axios.post( `amfieldcategory/${data.fieldId}/${data.categoryId}`, { updatedField: { label: data.label } } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getFieldList( category );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    return (
        <>
            <div className="listContainer p-1">
                <div className="col-md-4 col-sm-6 bg-white">
                    <div className="col-md-12">
                                              
                        <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                            <b>Custom Fields</b>
                            <FontAwesomeIcon
                                className="isCursor text-primary"
                                icon={faPlus}
                                onClick={() => setShow( true )}
                            />
                        </div>
                        <div className='d-flex flex-column'>
                            {list.length>0 && list.map( ( el ) => (
                                <span
                                    className="d-flex justify-content-between border p-1 pl-2 m-1 align-items-center isCursor department_item"
                                    key={`${el.AM_Field_ID}${el.am_category_id}`}
                                    onClick={() => handleEditField( el )}>
                                    <ItemCard item={el} />
                                    <button
                                        className="btn btn-sm p-1 btn-outline-danger"
                                        onClick={( e ) => {
                                            e.stopPropagation();
                                            deleteItem( el.field_id, el.am_category_id );
                                        }}>
                                        <FontAwesomeIcon icon={faTrash} title="Delete"/>
                                    </button>
                                </span> )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <AmFieldForm
                isNew={isNew}
                category={category} 
                show={show} 
                handleClose={handleclose} 
                createField={createField}
                updateField={updateField}
                fieldData={fieldData}
            />
        </>
    );
};

export default AmFieldCategoryList;

const ItemCard = ( { item } ) => {
    return <div className="w-100">
        <div className="d-flex align-items-center justify-content-between">
            <b className="text-dark">{item.label}</b>
        </div>
        <div className='d-flex justify-content-between'>
            <span className='text-dark'>{item.Field_Type}</span>
            <span className='text-dark mr-2'>Active</span>
        </div>
    
    </div>;
};