import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';

const InvitationModal = ( { showModal, handleModalClose, handleAddMembers, emails, setEmail,error, isSaving } ) => {
    // console.log( util.user.email );
    return (
        <div>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Invite Members</Modal.Title>
                </Modal.Header>
                {isSaving &&  <div className='circular-prog-overlay'><CircularProgress /></div>   }
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Email
                                <small className="text-muted"> (Please enter email address)</small></Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="e.g. john@example.com,jane@example.com"
                                value={emails}
                                onChange={( e ) => setEmail( e.target.value )}
                            />
                        </Form.Group>
                        {error && <p className="text-danger">{error}</p>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-secondary activeBtn sizeBtn" onClick={handleModalClose}>
                        Cancel
                    </button>
                    <button className="btn btn-sm btn-secondary activeBtn sizeBtn" onClick={handleAddMembers}>
                        Add Members
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};



export default InvitationModal;
