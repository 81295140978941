import React from 'react';
import { Form, Modal } from 'react-bootstrap';

const AddPaymentItem = ( { showModal, handleModalClose, handleAddPayment, error, paymentDetail, handleChange, isEditing,handleUpdatePayment } ) => {
    // console.log( util.user.email );
    return (
        <div>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? "Update Payment Item" : "Add Payment Item"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Payment Item Name</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                type="text"
                                placeholder="Enter Payment Item Name"
                                value={paymentDetail.name}
                                onChange={handleChange}
                                name='name'
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Payment Item Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                type="text"
                                placeholder="Enter Payment Item Description"
                                value={paymentDetail.description}
                                onChange={handleChange}
                                name='description'
                            />
                        </Form.Group>
                        {error && <p className="text-danger">{error}</p>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-secondary activeBtn sizeBtn" onClick={handleModalClose}>
                        Cancel
                    </button>
                    {isEditing ? 
                    
                    
                        <button className="btn btn-sm btn-secondary activeBtn " onClick={handleUpdatePayment}>
                        Update Payment Item
                        </button> :
                        <button className="btn btn-sm btn-secondary activeBtn " onClick={handleAddPayment}>
                        Add Payment Item
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    );
};



export default AddPaymentItem;
