import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import CatalogSQuestionForm from "./CatalogScreeningQC";

const CatalogCreationModal = ( { show, handleClose, actionType = 1, data = {}, settings, reloadData, id,type,name } ) => {
    const { title,  createPath, getDetailPath, editPath } = settings;
   
    const [item, setItemInformation] = useState( data );
    const [isChecked, setIsChecked] = useState( '1' );
      
    const handleCheckboxChange = ( e ) => {
        // Check the value of the clicked radio button
        const newValue = e.target.value;

        // Update the state with the new value
        setIsChecked( newValue );
        console.log( isChecked );
    };
    const handleChange = ( e ) =>{ 
       
        setItemInformation( { ...item, [e.target.name]: e.target.value } );
        console.log( item );
    };

    const getItemDetail = async () => {
        try {
            const request = await util.axios.get( `${getDetailPath}/${type}/${id}` );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            setItemInformation( data[0][0] );
           
          
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const saveInformation = async () => {
        try {
            let requestData = {};
    
            // Check if the type is catalog_job_headline
            if ( type === 'catalog_job_headlines' ) {
                requestData = {
                    headline: item.headline,
                    tagline: item.tagline,
                    status: 1, // default status is 1
                };
            } else {
                // Default case for other types
                requestData = {
                    item: item.name
                };
            }
    
            const request = await util.axios.post( createPath + type, requestData );
            const { msg, error } = request.data;
            if ( error ) throw msg;
    
            util.toast().success( msg );
            reloadData( type );
        } catch ( error ) {
            util.handleError( error );
        }
        handleClose();
    };
    
    const updateInformation = async () => {
        try {
            let editData = item.name;
            let requestData = {};
    
            // Check if the type is catalog_job_headline
            if ( type === 'catalog_job_headlines' ) {
                requestData = {
                    headline: item.headline,
                    tagline: item.tagline,
                    status: isChecked, // Active or Inactive based on the checkbox
                };
            } else {
                // Default case for other types
                requestData = {
                    item: item.name,
                };
            }
    
            const request = await util.axios.patch( `${editPath}/${type}/${id}/${editData}/${isChecked}`, requestData );
            const { msg, error } = request.data;
            if ( error ) throw msg;
    
            util.toast().success( msg );
            reloadData( type );
        } catch ( error ) {
            util.handleError( error );
        }
        handleClose();
    };
    

    // const getRegionList = () => {
    //     let regionList = {};
    //     countryList.forEach( item => {
    //         if( regionList[item.region] ) {
    //             regionList[item.region].push( item.name );
    //         } else {
    //             regionList[item.region] = [item.name];
    //         }
    //     } );
    //     return Object.keys( regionList );
    // };

    const triggerSaveUpdate = () => {
      
        if ( actionType === 1 ) {
            return saveInformation();
        }
        return updateInformation();
    };

    useEffect( () => {
      
        if ( actionType === 2 ) {
            getItemDetail( id );
          
        }
    }, [] );

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {actionType === 1 ? "Create new " : "Update "} {name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor:'#f9f9f9' }}>
                {type === 'job_sq' ? (
                    <CatalogSQuestionForm reloadData={reloadData} actionType={actionType} editId={id} handleClose={handleClose}  />
                ) : type === "catalog_job_headlines" ? (
                    <>
                        <label className="form-label">Headline</label>
                        <input
                            className="form-control"
                            name="headline"
                            value={item.headline || ''}
                            onChange={handleChange}
                        />
                        <label className="form-label mt-3">Tagline</label>
                        <textarea
                            className="form-control"
                            name="tagline"
                            value={item.tagline || ''}
                            onChange={handleChange}
                        />
                    </>
                ) : (
                    <>
                        <label className="form-label">Title</label>
                        <input
                            className="form-control"
                            name="name"
                            value={type === 'job_sq' ? item.question : item.name}
                            onChange={handleChange}
                        />
                        {type === "catalog_customer_status" && (
                            <textarea
                                className="form-control mt-3"
                                name="description"
                                value={item.description}
                                onChange={handleChange}
                            />
                        )}
                        {type === "wl_status" ? null : (
                            <div className="d-flex">
                                <div className="d-flex mt-2">
                                    <input
                                        type="radio"
                                        value="1"
                                        checked={isChecked === '1'}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="pl-2 d-flex align-items-center mb-0">Active</label>
                                </div>
                                <div className="d-flex mt-2 ml-2">
                                    <input
                                        type="radio"
                                        value="0"
                                        checked={isChecked === '0'}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="pl-2 d-flex align-items-center mb-0">Inactive</label>
                                </div>
                            </div>
                        )}
                    </>
                )}
                <div className="d-flex justify-content-end m-2">
                    <button className="btn btn-sm btn-primary" onClick={triggerSaveUpdate}>
                    Save
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    
      
    );
};

export default CatalogCreationModal;
