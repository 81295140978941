import { Chip, MuiThemeProvider } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import util from "../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import PaymentSchedule from "./PaymentSchedule";
import { Tab, Tabs } from "react-bootstrap";
import EliteInfo from "./EliteInfo";
import Members from "./Members";
import Leads from "./Leads";
import { useHistory } from 'react-router-dom';
import InvitationModal from "./InvitationModal";
import InviteManager from "./InviteManager";

const AllEliteConnect = ( { selectedElite, setSelectedElite } ) => {
    const [loading, setLoading] = useState( false );
    const [eliteConnect, setEliteConnect] = React.useState( [] );
    const [error ,setError] = useState( "" );
    const [showModal, setShowModal] = useState( false );
    const [email, setEmail] = useState( "" );
    const history = useHistory();
    const [isSending, setIsSending] = useState( false );

    const fetchEliteConnect = async () => {
        try {
            const response = await util.axios.get( `/eliteconnect/` );
            const { error, data } = response.data;

            if ( error ) {
                throw new Error( "Failed to fetch elite connect data" );
            }
            
            setEliteConnect( data );
        } catch ( err ) {
            console.error( err );
        }
    };
    const handleRowClick = ( event, rowData ) => {
        console.log( rowData );
        setSelectedElite( rowData );
        history.push( "/elite-connect", { eliteData: rowData } );
    };
    

    useEffect( () => {
        fetchEliteConnect();
    }, [] );
    const handleModalClose = () => {
        setShowModal( false );
    };


    const handleAddEliteConnect = async( elite_detail ) => {
                  
        
        try {            
            
            // setLoading( true );
            setIsSending( true );
            const response = await util.axios.post( '/eliteconnectmanager/invitation', { 
                ...elite_detail,
            } );
        
            const { error, msg } = response.data;
        
            if ( error ) throw  msg ;
        
            util.toast().success( msg );
            fetchEliteConnect();
            handleModalClose();
        } catch ( error ) {
            setError( "An error occurred while adding members." );
            console.error( error );
            util.handleError( error );
        } finally {
            setIsSending( false );

        }
    };

    const columns = [
        {
            title: "Elite Connect Id",
            field: "elite_connect_id",
        },
        {
            title: "Elite Name",
            field: "elite_name",
        },
        {
            title: "Elite Email",
            field: "elite_email",
        },
        {
            title: "Elite Status",
            field: "elite_status",
        },
        // {
        //     title: "Elite Email",
        //     field: "elite_email",
        // },
        {
            title: "Elite Admin",
            field: "elite_ej_partner_in_charge",
        },
        {
            title: "Elite POC",
            field: "elite_poc"
        }
    ];

    const tableTitle = `Elite Connect List`;
    // if ( isSending ) return util.LOADING_SCREEN( loading );
    return (
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={columns}
                    title={tableTitle}
                    data={eliteConnect}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        sorting: true,
                        search: true,
                        selection: true,
                        headerStyle: { backgroundColor: "#f5f5f5", fontWeight: "bold" },
                        maxBodyHeight: "60vh",
                    }}
                    actions={
                        [
                            {
                                icon: () => (
                                    <div className="btn btn-secondary btn-sm activeBtn">
                                        <FontAwesomeIcon icon={faPlusCircle} /> Invite Elite Connect
                                    </div>
                                ),
                                tooltip: "Invite Elite Connect Manager",
                                isFreeAction: true,
                                onClick: () => setShowModal( true ),
                            },
                        ]
                                            
                    }
                    onRowClick={handleRowClick}
                />
                <InviteManager 
                    showModal={showModal}
                    email={email}
                    setEmail={setEmail}
                    handleModalClose={handleModalClose}
                    handleAddEliteConnect={handleAddEliteConnect}
                    isSending={isSending}

                />
            
            </MuiThemeProvider>
        </>
        
               
    );
       
};

export default AllEliteConnect;