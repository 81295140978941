import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col,  Card } from "react-bootstrap";
import util from "../../utils/miniUtils";
import CountryFillOne from "./CountryFillOne";
import IncreaseLimitsModal from "./IncreaseLimitModal";
import validator from 'validator';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const AccountInfo = () => {
    // console.log( util.user );
    const [industries, setIndustries] = useState( [] );

    const [employer, setEmployer] = useState( null );
    const [loading, setLoading] = useState( true );
    const [error, setError] = useState( null );
    const [attachmentFiles, setAttachmentFiles] = useState( [] );
    const [logoImage, setLogoImage] = useState( null );

    const isReadOnly = util.user.role === 18;
    const [showModal, setShowModal] = useState( false );
    const [newJobLimit, setNewJobLimit] = useState( "" );
    const [newEmployeeLimit, setNewEmployeeLimit] = useState( "" );
    const [logoUrl, setLogoUrl] = useState( null ); // State to hold the fetched logo URL
    const [errors, setErrors] = useState( {} );


    useEffect( () => {
        const fetchLogoUrl = async () => {
            if ( employer && employer.company_logo_file ) {
                const url = await util.fetchImageUrl( `src/public/employer_logos/${util.user.employer_id}/${employer.company_logo_file}` );
                setLogoUrl( url ); // Set the fetched URL to state
            }
        };

        fetchLogoUrl();
    }, [employer] );
    const fetchIndustries = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/industries` );
            const { error, msg, data } = request.data;
    
            if ( error ) throw new Error( msg );
    
            setIndustries( data ); 
        } catch ( error ) {
            util.handleError( error );
            setError( "Failed to fetch industries." );
        } finally {
            setLoading( false );
        }
    };
    useEffect( ()=>{
        fetchIndustries();
    },[] );

    useEffect( () => {
        const fetchEmployerDetails = async () => {
            try {
                const employerId = util.user.employer_id;
                setLoading( true );
                const request = await util.axios.get( `/employers/${employerId}` );
                const { error, msg, data } = request.data;

                if ( error ) throw new Error( msg );

                setEmployer( data );
                // util.toast().success( msg || "Employer details fetched successfully" );
            } catch ( error ) {
                util.handleError( error );
                setError( "Failed to fetch employer details." );
            } finally {
                setLoading( false );
            }
        };

        fetchEmployerDetails();
    }, [] );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
    
        if ( name === "employer_email" ) {
            const originalEmail = employer.employer_email || ""; // Handle case where employer email is empty
            const originalDomain = originalEmail.includes( "@" ) ? originalEmail.split( "@" )[1] : "";
    
            // If user is typing a new email but hasn't completed it yet
            if ( !value.includes( "@" ) ) {
                setEmployer( ( prevState ) => ( {
                    ...prevState,
                    [name]: value,
                } ) );
                return;
            }
    
            const [localPart, domain] = value.split( "@" );
    
            if ( domain && domain !== originalDomain ) {
                setErrors( ( prevErrors ) => ( {
                    ...prevErrors,
                    employer_email: "Email domain cannot be changed.",
                } ) );
                return;
            } else {
                // Remove error if domain remains the same
                setErrors( ( prevErrors ) => {
                    const updatedErrors = { ...prevErrors };
                    delete updatedErrors.employer_email;
                    return updatedErrors;
                } );
            }
        }
    
        setEmployer( ( prevState ) => ( {
            ...prevState,
            [name]: value,
        } ) );
    };
    
    
    
    // Employer Validation Function
    const validateEmployer = ( employer ) => {
        const errors = {};
        if ( !employer.company_legal_name || employer.company_legal_name.trim() === "" ) {
            errors.company_legal_name = "Company legal name is required.";
        }
        if ( !employer.employer_email || !validator.isEmail( employer.employer_email ) ) {
            errors.employer_email = "Employer email is required.";
        }
        if ( !employer.employer_website || !validator.isURL( employer.employer_website ) ) {
            errors.employer_website = "Employer website is required.";
        }
        if ( !employer.company_tax_id || employer.company_tax_id.trim() === "" ) {
            errors.company_tax_id = "Company tax ID is required.";
        }
        if ( !employer.address || employer.address.trim() === "" ) {
            errors.address = "Address is required.";
        }
        if ( !employer.email_domain || employer.email_domain.trim() === "" ) {
            errors.email_domain = "Email domain is required.";
        }
        if ( !employer.status || employer.status === "" ) {
            errors.status = "Status is required.";
        }
        if ( !employer.industry || employer.industry.trim() === "" ) {
            errors.industry = "Industry is required.";
        }
        return errors;
    };
    const handleSave = async ( e ) => {
        e.preventDefault();
        const validationErrors = validateEmployer( employer );
        if ( Object.keys( validationErrors ).length > 0 ) {
            setErrors( validationErrors );
            return util.toast().error( "Please fill all required fields correctly." );
        }
        try {
            const payload = { 
                ...employer,
                attachments: attachmentFiles.map( file => file.path )
            };
            const employerId = util.user.employer_id;
            const response = await util.axios.put( `/employers/${employerId}`, payload );
            const { error, msg } = response.data;
            if ( error ) throw msg;    
            util.toast().success( msg || "Employer details updated successfully" );
        } catch ( error ) {
            util.toast().error( error || "Failed to update employer details" );
        }
    };

    // const handleSave = async ( e ) => {
    //     e.preventDefault();
    //     try {
    //         const validationError = validateEmployer( employer );
    //         if ( validationError ) {
    //             return util.toast().error( validationError );
    //         }
    //         if (
    //             employer.country === "India" &&
    //             !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test( employer.company_tax_id )
    //         ) {
    //             return util.toast().error( "Invalid PAN Number" );
    //         }
    
    //         if ( !employer.company_tax_id ) {
    //             return util.toast().error(
    //                 employer.country === "India"
    //                     ? "PAN Number is required"
    //                     : "Company Tax ID is required"
    //             );
    //         }
    
    //         // setLoading( true );
    //         const payload = { 
    //             ...employer,
    //             attachments: attachmentFiles.map( ( file ) => file.path ) // Add uploaded file paths
    //         };
    
    //         const employerId = util.user.employer_id;
    //         const response = await util.axios.put( `/employers/${employerId}`, payload );
    //         const { error, msg } = response.data;
    //         if ( error ) throw msg;    
    //         util.toast().success( msg || "Employer details updated successfully" );
    //         // Fetch updated employer data
    //         // const updatedEmployer = await util.axios.get( `/employers/${employerId}` );
    //         // setEmployer( updatedEmployer.data.data );
    //     } catch ( error ) {
    //         util.toast().error( error || "Failed to update employer details" );
    //     } finally {
    //         // setLoading( false );
    //     }
    // };
    function handleImgUpload( e ) {
        const file = e.target.files[0];
        if ( file ) {
            setLogoImage( file );
            console.log( "Selected file:", file.name );
        }
    }
    async function uploadLogo() {
        try {
            if ( !logoImage ) {
                util.toast().error( "Please select a logo to upload." );
                return;
            }
    
            const data = new FormData();
            data.append( "file", logoImage );
    
            const employerId = util.user.employer_id; // Get the employer ID from the current user's data
            const request = await util.axios.post( `/employer/upload_comapny_logo/${employerId}`, data );
            const { error, msg, employerData } = request.data;
    
            if ( error ) throw new Error( msg );
    
            util.toast().success( msg || "Logo uploaded successfully." );
    
            // Update the employer state with the new data
            setEmployer( ( prevEmployer ) => ( {
                ...prevEmployer,
                company_logo_file: employerData.company_logo_file,
            } ) );
    
            setLogoImage( null ); // Clear the selected file
        } catch ( error ) {
            util.handleError( error );
        }
    }
    
    
    async function removeLogo() {
        try {
            const employerId = util.user.employer_id;
            const request = await util.axios.delete( `/employer/delete_company_logo/${employerId}` );
            const { error, msg } = request.data;
    
            if ( error ) throw new Error( msg );
    
            util.toast().success( msg || "Logo removed successfully." );
    
            setEmployer( ( prevEmployer ) => ( {
                ...prevEmployer,
                company_logo_file: null,
            } ) );
        } catch ( error ) {
            util.handleError( error );
        }
    }
    

    const handleShowModal = () => setShowModal( true );
    const handleCloseModal = () => setShowModal( false );

    const handleModalSave = async () => {
        try {
            setLoading( true );
            const response = await util.axios.put( `/update_employers_account_limits`, { employee_limit: newEmployeeLimit, job_limit: newJobLimit } );
            const { error, msg } = response.data;
    
            if ( error ) throw new Error( msg );
            util.toast().success( msg || "Employer limits updated successfully" );
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
            handleCloseModal();
        }
    };

    if ( loading ) {
        return <div>Loading...</div>;
    }

    if ( error ) {
        return <div>{error}</div>;
    }

    if ( !employer ) {
        return <div>No Employer Found</div>;
    }
    const badgeTypes = [
        'primary',
        'secondary',
       
    ];

    const badgeTexts = [
        'Active',
        'Inactive',
        
    ];

    const getBadgeText = ( status ) => {
        if ( !status ) return ""; // Handle empty case
        const formattedStatus = status.toLowerCase();
    
        if ( formattedStatus === "active" ) return "Active";
        if ( formattedStatus === "inactive" ) return "Inactive";
    
        return ""; // Fallback for unexpected values
    };
    

    const getBadgeMarkup = ( status ) => {
        if ( !status ) return null; // Do not render anything if status is empty
    
        const formattedStatus = getBadgeText( status );
        const index = badgeTexts.indexOf( formattedStatus );
    
        if ( index === -1 ) return null; // Safety check, avoid rendering if status is invalid
    
        const badgeType = badgeTypes[index];
    
        return (
            <div className="d-flex justify-content-end">
                <span className={`badge badge-${badgeType}`}>
                    {formattedStatus} {/* Ensures only one status is displayed */}
                </span>
            </div>
        );
    };
    
    return (
        <Container className="mt-3">
            <Card className='ml-4'>
                <Card.Header as="h5">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center">
                            {employer.company_logo_file && (
                                <div>
                                    <img
                                        style={{ 
                                            width: "50px", 
                                            height: "50px", 
                                            maxWidth: "150px", 
                                            objectFit: "contain" 
                                        }}
                                        src={logoUrl} 
                                        alt="logo"
                                    />
                                </div>
                            )}
                            <p className="mt-3 ml-2">{employer.company_legal_name} Details</p>
                        </span>
                        <span>{getBadgeMarkup( employer.status )}</span>
                    </div></Card.Header>
                <Card.Body className='ml-4'>

                    <Form>
                        {isReadOnly?"":
                            <div className="mt-3">
                                <b>Logo</b>
                                <div>
                                    <label htmlFor="company_logo_file" className="btn btn-secondary activeBtn mt-3">
            Add Company Logo
                                    </label>
                                    <input
                                        id="company_logo_file"
                                        title="Logo"
                                        accept="image/*, .svg, .webp"
                                        onChange={handleImgUpload}
                                        name="company_logo_file"
                                        type="file"
                                        style={{ display: 'none' }}
                                    />
                                    {logoImage ? (
                                        <button
                                            type="button"
                                            className="btn btn-secondary sizeBtn activeBtn ml-3"
                                            onClick={uploadLogo}
                                        >
                Upload
                                        </button>
                                    ) : null}
                                </div>

                                {/* {employer.company_logo_file ? (
                                <div>
                                    <label>Preview</label>
                                    <img
                                        style={{ maxWidth: "100px", marginLeft: "10px" }}
                                        src={logoUrl} // Use the fetched logo URL from state
                                        alt={employer.company_legal_name}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-secondary sizeBtn activeBtn ml-2"
                                        onClick={removeLogo}
                                    >
                                    Remove
                                    </button>
                                </div>
                            ) : null} */}

                            </div>}
                        <Row className="mb-3 mt-3">
                            <Col md={6}>
                                <Form.Group controlId="formCompanyName">
                                    <Form.Label>Company Legal Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="company_legal_name"
                                        value={employer.company_legal_name || ""}
                                        placeholder="Enter your organization's name (e.g. ABC Corp.)"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                        style={{ width: "80%" }}
                                    />
                                    {errors.company_legal_name && <small className="text-danger">{errors.company_legal_name}</small>}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formCompanyEmail">
                                    <Form.Label>Employer Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="employer_email"
                                        value={employer.employer_email || ""}
                                        placeholder="Enter your official company email (e.g. info@abc.com)"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                        style={{ width: "80%" }}
                                    />
                                    {errors.employer_email && <small className="text-danger">{errors.employer_email}</small>}
                                </Form.Group>
                            </Col>
                        </Row>

                        

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formCompanyWebsite">
                                    <Form.Label>Employer Website</Form.Label>
                                    <Form.Control
                                        type="url"
                                        name="employer_website"
                                        value={employer.employer_website || ""}
                                        placeholder="https://www.yourcompany.com"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                        style={{ width: "80%" }}
                                    />
                                </Form.Group>
                                {errors.employer_website && <small className="text-danger">{errors.employer_website}</small>}
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formHQAddress">
                                    <Form.Label>HQ Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={employer.address || ""}
                                        placeholder="Enter the main office address (e.g. 123 Main St)"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                        style={{ width: "80%" }}
                                    />
                                    {errors.address && <small className="text-danger">{errors.address}</small>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3}>
                                <Form.Group controlId="formYearFounded">
                                    <Form.Label>Year Founded</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="year_founded"
                                        value={employer.year_founded || ""}
                                        placeholder="Enter the year your company was established"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                        style={{ width: "95%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="formStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="status"
                                        value={employer.status || ""}
                                        onChange={handleChange}
                                        readOnly={isReadOnly}
                                        style={{ width: "60%" }}
                                        disabled={isReadOnly}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control>
                                    {errors.status && <small className="text-danger">{errors.status}</small>}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formEmailDomain">
                                    <Form.Label>Email Domain</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email_domain"
                                        value={employer.email_domain || ""}
                                        placeholder="Enter your company's email domain (e.g. abc.co)"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                        style={{ width: "80%" }}
                                        disabled
                                    />
                                    {errors.email_domain && <small className="text-danger">{errors.email_domain}</small>}
                                </Form.Group>
                            </Col>
                        </Row>

                        

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formSocialMediaLinks">
                                    <Form.Label>Social Media Links</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="social_media"
                                        value={employer.social_media|| ""}
                                        placeholder="Add links to your social media profiles (e.g. LinkedIn)"
                                        onChange={handleChange}
                                        readOnly={isReadOnly} 
                                        style={{ width: "80%" }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formIndustry">
                                    <Form.Label>Industry</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="industry"
                                        value={employer.industry || ""}
                                        onChange={handleChange}
                                        readOnly={isReadOnly}
                                        style={{ width: "80%" }}
                                        disabled={isReadOnly}
                                    >
                                        <option value="">Select Industry</option>
                                        {industries&&industries?.map( ( industry ) => (
                                            <option key={industry.id} value={industry.id}>
                                                {industry.name}
                                            </option>
                                        ) )}
                                    </Form.Control>
                                    {errors.industry && <small className="text-danger">{errors.industry}</small>}
                                </Form.Group>
                            </Col>
                            
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formMaxJobLimit">
                                    <Form.Label>Maximum Job Limit</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="job_limit"
                                        value={employer.job_limit || ""}
                                        placeholder="Max number of jobs this employee can post"
                                        onChange={handleChange}
                                        readOnly
                                        style={{ width: "80%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formStatus">
                                    <Form.Label>Maximum Number of Members</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="limit"
                                        value={employer.employee_limit || 0}
                                        onChange={handleChange}
                                        readOnly
                                        style={{ width: "80%" }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formAvailableJobsToPost">
                                    <Form.Label>Available Jobs To Post</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="job_count"
                                        value={employer.job_count || 0}
                                        placeholder="Available Jobs to Post"
                                        onChange={handleChange}
                                        readOnly
                                        style={{ width: "80%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formEmployeeCount">
                                    <Form.Label>Member Count</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="employee_count"
                                        value={employer.employee_count || ""}
                                        placeholder="Total number of Members"
                                        onChange={handleChange}
                                        readOnly
                                        style={{ width: "80%" }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            
                            <CountryFillOne
                                newState={employer}
                                onChangeHandler={handleChange}
                                isReadOnly={isReadOnly}
                                attachmentFiles={attachmentFiles}
                                setAttachmentFiles={setAttachmentFiles}
                                setNewState={setEmployer}
                                errors={errors}
                            />
                        </Row>
                        {/* <span className="mt-2 likeLink" onClick={handleShowModal}>
                        To upgrade your job posting and member invitation limit, please contact us at +1 (800) 985-7240.
                        </span> */}

                        {util.user.role === 18 && (
                            <div className="d-flex align-items-center mt-2">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning me-2" size={30} />
                                <span className="text-dark fw-medium mt-1 ml-1">
            To post more jobs, please upgrade your plan by contacting us at:{" "}
                                    <span className="text-primary fw-bold">+1 (800) 985-7240</span>.
                                </span>
                            </div>
                        )}

                        {util.user.role === 17 && (
                            <div className="d-flex align-items-center mt-2">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning me-2" size={30} />
                                <span className="text-dark fw-medium mt-1 ml-1">
            To post more jobs and invite additional members, please upgrade your plan by contacting us at:{" "}
                                    <span className="text-primary fw-bold">+1 (800) 985-7240</span>.
                                </span>
                            </div>
                        )}


                        {!isReadOnly && (
                            <div className="float-right" style={{ marginRight:'60px' }}> 
                                <button 
                                    type="button" 
                                    className="btn btn-sm btn-secondary activeBtn sizeBtn" 
                                    onClick={handleSave} 
                                    disabled={loading} 
                                > 
                                    Save
                                </button> 
                            </div> 
                        )} 
                    </Form>
                </Card.Body>
            </Card>

            <IncreaseLimitsModal
                show={showModal}
                handleClose={handleCloseModal}
                employer={employer}
                newJobLimit={newJobLimit}
                setNewJobLimit={setNewJobLimit}
                newEmployeeLimit={newEmployeeLimit}
                setNewEmployeeLimit={setNewEmployeeLimit}
                handleModalSave={handleModalSave}
            />
        </Container>
    );
};

export default AccountInfo;
