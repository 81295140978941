import React, { useEffect, useState } from "react";
import { PositionsStore } from "../../components/positions/stores/PositionsStore";
import TextField from "@material-ui/core/TextField";
import util from "../utils/miniUtils";
import { bake_cookie } from "sfcookies";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/material.css";
import ReactRecaptcha from "react-recaptcha";
import PhoneInput from "react-phone-input-2";
import { CircularProgress, IconButton, InputAdornment, Paper, Tooltip, Typography, withStyles } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import jwt_decode from "jwt-decode";
import axios from "axios";

const CustomTooltip = withStyles( ( theme ) => ( {
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "#333",
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
    arrow: {
        color: "#f5f5f9",
    },
} ) )( Tooltip );

export default function Register( {  email } ) {
    const [errors, setErrors] = useState( {} );
    const [showPassword, setShowPassword] = useState( false );
    const [showConfirmPassword, setShowConfirmPassword] = useState( false );
    const [recaptchaEnabled, setRecaptchaEnabled] = useState( false );
    const [isVerified, setIsVerified] = useState( false );
    const [isSaving, setIsSaving] = useState( false );

    // Function to validate email format
    const isValidEmail = ( email ) => /\S+@\S+\.\S+/.test( email );

    // Initialize formData state
    const [formData, setFormData] = useState( {
        name: "",
        email: isValidEmail( email ) ? email : "", // Validate email upfront
        phone: "",
        password: "",
        confirm_password: "",
    } );

    // Effect to update email if it changes
    useEffect( () => {
        if ( isValidEmail( email ) ) {
            setFormData( ( prevData ) => ( { ...prevData, email } ) );
        }
    }, [email] );

    // Effect to enable reCAPTCHA after a delay
    useEffect( () => {
        setTimeout( () => setRecaptchaEnabled( true ), 1000 );
    }, [] );

    const validate = () => {
        const newErrors = {};
        if ( !formData.name ) newErrors.name = "Name is required.";
        if ( !formData.password ) newErrors.password = "Password is required.";
        else {
            if ( formData.password.length < 12 ) newErrors.password = "Password must be at least 12 characters.";
            if ( !/[A-Z]/.test( formData.password ) ) newErrors.password = "Password must contain an uppercase letter.";
            if ( !/[a-z]/.test( formData.password ) ) newErrors.password = "Password must contain a lowercase letter.";
            if ( !/\d/.test( formData.password ) ) newErrors.password = "Password must contain a number.";
            if ( !/[!@#$%^&*]/.test( formData.password ) )
                newErrors.password = "Password must contain a special character.";
        }
        if ( formData.password !== formData.confirm_password ) newErrors.confirm_password = "Passwords do not match.";
        if ( !formData.phone ) newErrors.phone = "Phone number is required.";
        return newErrors;
    };

    const handleInputChange = ( e ) => {
        const { name, value } = e.target;
        setFormData( ( prev ) => ( { ...prev, [name]: value } ) );
    };

    const handlePhoneChange = ( value ) => {
        setFormData( ( prev ) => ( { ...prev, phone: value } ) );
    };

    const createAccount = async () => {
        try {
            PositionsStore.update( ( s ) => {
                s.loading = true;
            } );
            setIsSaving( true );
            const response = await axios.post( `${util.url}/eliteconnect/new`, { newTalent: formData } );
            const { error, msg, accessToken } = response.data;
    
            if ( error ) {
                PositionsStore.update( ( s ) => {
                    s.loading = false;
                } );
                util.handleError( msg );
                return;
            }
    
            bake_cookie( "tokenServer", accessToken );
            toast.success( msg );
    
            // Update the PositionsStore sequentially
            PositionsStore.update( ( s ) => {
                s.isLogged = true;
                s.loginModal = false;
                s.createAccount = false;
                s.user = jwt_decode( accessToken ); // Decoded token to retrieve user data
            } );
            window.history.replaceState( "", "", `/elite-connect` );
            window.location.reload();
        } catch ( e ) {
            console.error( "Error during account creation:", e );
            util.handleError( e.message || "An error occurred." );
        } finally {
            setIsSaving( true );
        }
        // onComplete();
    };
    
    

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        const validationErrors = validate();
        if ( Object.keys( validationErrors ).length > 0 ) {
            setErrors( validationErrors );
            return;
        }
        if ( !isVerified ) {
            toast.error( "Please verify the reCAPTCHA." );
            return;
        }
        await createAccount();
    };

    const verifyCallBack = ( data ) => {
        if ( data ) setIsVerified( true );
    };

    // If email is invalid, do not render the component
    if ( !isValidEmail( email ) ) {
        return null; // Or return an alternative message: <p>Invalid email</p>
    }

    return (
        <Paper 
            elevation={3} 
            style={{
                maxWidth: "500px",
                margin: "50px auto",
                padding: "30px 20px",
                borderRadius: "12px",
            }}
        >
           
            <div className="container mt-5">
                <div className="text-center mb-4">
                    <h4>Create Account</h4>
                </div>
                <form onSubmit={handleSubmit}>
                    {/* {isSaving &&  <div className='circular-prog-overlay'><CircularProgress /></div>   } */}
                    
                    <div className="form-group">
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="name"
                            name="name"
                            label="Name"
                            value={formData.name}
                            onChange={handleInputChange}
                            error={Boolean( errors.name )}
                            helperText={errors.name}
                        />
                    </div>
                    <div className="form-group">
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="email"
                            name="email"
                            label="Email"
                            value={formData.email}
                            disabled
                            InputProps={{
                                readOnly: true, // Explicitly makes the field immutable
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <CustomTooltip
                            title={
                                <ul>
                                    {[
                                        "At least 12 characters long",
                                        "Contains an uppercase letter",
                                        "Contains a lowercase letter",
                                        "Contains a number",
                                        "Contains a special character",
                                    ].map( ( rule, index ) => (
                                        <li key={index}>{rule}</li>
                                    ) )}
                                </ul>
                            }
                            placement="bottom-end"
                            arrow
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="password"
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                value={formData.password}
                                onChange={handleInputChange}
                                error={Boolean( errors.password )}
                                helperText={errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword( !showPassword )}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </CustomTooltip>
                    </div>
                    <div className="form-group">
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="confirm_password"
                            name="confirm_password"
                            label="Confirm Password"
                            type={showConfirmPassword ? "text" : "password"}
                            value={formData.confirm_password}
                            onChange={handleInputChange}
                            error={Boolean( errors.confirm_password )}
                            helperText={errors.confirm_password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowConfirmPassword( !showConfirmPassword )}>
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <PhoneInput
                            country={"us"}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            specialLabel=""
                            placeholder="Enter Phone Number"
                            inputProps={{ name: "phone", required: true }}
                        />
                        {errors.phone && <div className="text-danger">{errors.phone}</div>}
                    </div>
                    {recaptchaEnabled && (
                        <div className="form-group text-center">
                            <ReactRecaptcha
                                elementID={"recaptcha"}
                                sitekey={process.env["REACT_APP_SITE_KEY"]}
                                render="explicit"
                                verifyCallback={verifyCallBack}
                            />
                        </div>
                    )}
                    <div className="form-group text-center mt-3">
                        <button type="submit" className="btn btn-primary">
                        Create Account
                        </button>
                    </div>
                </form>
            </div>
        </Paper>
    );
}
