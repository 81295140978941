import React, { useState, useEffect } from 'react';
import util from '../utils/miniUtils';
import { Col, Form, Row } from 'react-bootstrap';


const ExtraFields = ( { category, handleChange, workload } ) => {
    const [state, setState] = useState( [] );

    const getFields = async ( categoryId ) => {
        try {
            const request = await util.axios.get( `/wl_fieldCategory/by_category/${categoryId}` );  
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setState( data );   
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleInputType = ( inputType ) => {
        switch( inputType ) {
            case "num":
                return "number";
            case "dateTime":
                return "datetime-local";
            default:
                return "text";
        }
    };

    useEffect( () => {
        getFields( category );
    },[category] );

    return state.length > 0 && <>
        {state.map( el => (
            <Form.Group as={Row} controlId={`form${el.field_name}`} key={el.id}>
                <Form.Label column md={4} >
                    {el.label}
                </Form.Label>
                <Col md={8}>
                    <Form.Control
                        type={handleInputType( el.field_type )}
                        name={el.field_name}
                        value={workload[el.field_name]}
                        onChange={handleChange}
                    />
                </Col>
            </Form.Group>
        ) )}

    </>;
};

export default ExtraFields;