import React from "react";

const EliteAgreement = () => {
    return (
        <div className="elite-agreement-container">
            <h1 className="elite-main-heading">eJElite Connect Agreement</h1>
            
            <div>
                <span className="elite-bold">Terms and conditions: </span>
                <span>
                eJAmerica and eJElite Connect may be referred to in this Agreement individually as a "party" or collectively as the "parties. This Agreement is effective on the date last signed by a party ("Effective Date"). This Agreement is subject to modifications by eJAmerica as per applicable laws and business needs with a 30-day prior written notice to the eJElite Connect.
                </span>
            </div>
            <div>
                <span className="elite-bold">WHEREAS  </span>
                <span>
                eJAmerica is a provider of IT services, including but not limited to Data Center Services, Digital Workplace Services (DWP), Field Support Services, Networks, Security and Cloud Services, Application Management, Platform Development and Support Services ("Services").</span>
            </div>
            <div>
                <span className="elite-bold">WHEREAS  </span>
                <span>
                eJAmerica has the eJElite Connect Program, an exclusive, invite-only partnership initiative by eJAmerica. Designed to empower and reward our most trusted collaborators, this program enables you to leverage your network and expertise to drive meaningful business growth to mutual benefit, while earning commissions and bonuses.
                </span>
            </div>
            <div>
                <span className="elite-bold">WHEREAS  </span>
                <span>
                eJElite Connect desires to refer potential clients to eJAmerica for the sale and delivery of eJAmerica’ s Services, engage eJAmerica’s resources to deliver services to eJElite Connect’s  clients and, in some instances, directly sell eJAmerica’ s Services to those clients. eJAmerica agrees to compensate the eJElite Connect for these services in accordance with the terms set forth in this Agreement. </span>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">1.  </div>
                <div>
                    <span className="elite-bold">
                        Agreement.
                    </span>
                    <span>
                    This Agreement, together with any Special Terms and Conditions to which eJAmerica and eJElite Connect may agree, establishes a relationship between the parties so that eJAmerica may, at its option, engage eJElite Connect to provide Services ("Services") to eJAmerica and, the eJElite Connect can engage eJAmerica to deliver services to their clients
                    </span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">2.  </div>
                <div>
                    <span className="elite-bold">
                        Term.
                    </span>
                    <span>
                    This Agreement will become effective on the last signature date and will be valid for one year and shall be automatically renewed every year unless either party terminates this Agreement by providing the notice period as mentioned under Section 5 below.</span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">3.  </div>
                <div>
                    <span className="elite-bold">
                        The Services.
                    </span>
                    <div className="elite-list">
                        <ol type="a">
                            <li>eJElite Connect shall choose how they will perform their work.</li>
                            <li>eJElite Connect shall choose where they do all or most of their work.</li>
                            <li>eJElite Connect shall decide their hours of work unless agreed otherwise by the eJAmerica, provided eJAmerica should be informed in writing in case of any change of place of work and work timing, as the case may be.</li>
                            <li>eJElite Connect will respond to any queries through email or WhatsApp messages in less than 2 hours; in case of any delays, the reasonable cause of any such delays shall be communicated to eJAmerica, provided the business is unaffected due to such delay.</li>
                            <li>eJElite Connect is responsible for commercial and other risks related to their work.</li>
                            <li>eJElite Connect shall supply their tools and equipment.</li>
                            <li>eJElite Connect shall submit an invoice, and eJAmerica shall pay eJElite Connect based on the price they quoted for their services as mentioned in the "Payment" section of this Agreement.</li>
                            <li>eJElite Connect is contracted to complete specific Services mentioned in Schedule A and is not guaranteed future work.</li>
                            <li>eJElite Connect is contracted to complete specific Services mentioned in Schedule A and is not guaranteed future work.</li>
                        </ol>

                    </div>
                    
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">4. </div>
                <div>
                    <span className="elite-bold">Payment. </span>
                    <span>
            eJElite Connect is entitled to the remuneration and any rights and/or benefits associated with the Services under this Agreement, as stated under Schedule B.
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">5. </div>
                <div>
                    <span className="elite-bold">Termination. </span>
                    <span>
            Either party may terminate this Agreement at any time by giving 30 days' written notice to the other party of the intent to terminate.
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">6. </div>
                <div>
                    <span className="elite-bold">Expenses. </span>
                    <span>
            eJElite Connect shall be responsible for all expenses incurred while performing services under this Agreement. This includes transport, permits, and other travel expenses; vehicle maintenance and repair costs; vehicle and other license fees and permits; insurance premiums; road, fuel, and other taxes; fines; radio, pager, or cell phone expenses; meals; and all salary, expenses, and other compensation paid to employees or contract personnel the eJElite Connect hires to complete the work under this Agreement.
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">7. </div>
                <div>
                    <span className="elite-bold">Vehicles and Equipment. </span>
                    <span>
            eJElite Connect will furnish all vehicles, equipment, tools, and materials to be used to provide the services required by this Agreement. eJAmerica will not require the eJElite Connect to rent or purchase any equipment, product, or Service as a condition of entering into this Agreement.
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">8. </div>
                <div>
                    <span className="elite-bold">Independent eJElite Connect Status. </span>
                    <span>
            eJElite Connect is an independent contractor, and by entering into this Agreement, the eJElite Connect hereby agrees that the Contractor's business is independent of the Client. Neither eJElite Connect nor Contractor's employees or contract personnel are or shall be deemed eJAmerica’s employees. In its capacity as an independent contractor, the eJElite Connect agrees and represents, and the eJAmerica agrees, as follows:
                        <ul>
                            <li>eJElite Connect shall perform the services required by this Agreement.</li>
                            <li>eJElite Connect shall NOT receive any training from eJAmerica in the professional skills necessary to perform the services required by this Agreement.</li>
                        </ul>
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">9. </div>
                <div>
                    <span className="elite-bold">Business Licenses, Permits, and Certificates. </span>
                    <span>
            eJElite Connect represents and warrants that eJElite Connect and Contractor's employees, and contract personnel will comply with all federal, state, and local laws requiring drivers' and other licenses, business permits, and certificates required to carry out the services to be performed under this Agreement. eJAmerica shall not, in any manner, be liable in case of failure to comply with this section.
                    </span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">10. </div>
                <div>
                    <span className="elite-bold">State and Federal Taxes .</span>
                    <span>
                    eJAmerica will <span className="elite-bold">not:</span>
                    </span>
                    <ul>
                        <li>be responsible for any taxes in the USA related to this Contract. eJAmerica will provide tax documentation, such as Form 1099 (for U.S.-based contractors), in accordance with applicable laws. eJElite Connect agrees to provide any necessary tax identification information to facilitate compliance with tax reporting requirements.</li>
                        <li>withhold FICA (Social Security and Medicare taxes) from Contractor's payments or make FICA payments on Contractor's behalf.</li>
                        <li>make state or federal unemployment compensation contributions on the Contractor's behalf, or</li>
                        <li>withhold state or federal income tax from Contractor's payments.</li>
                        <li>eJElite Connect shall pay all taxes incurred while performing services under this Agreement—including all applicable income taxes and if the eJElite Connect is not a corporation, self-employment (Social Security) taxes. Upon demand, the eJElite Connect shall provide eJAmerica with proof that such payments have been made.</li>


                    </ul>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">11.</div>
                <div>
                    <span className="elite-bold">Fringe Benefits.</span>
                    <span>
            The eJElite Connect understands that neither the eJElite Connect nor Contractor's employees or contract personnel are eligible to participate in any employee pension, health, vacation pay, sick pay, or other fringe benefit plan of eJAmerica.
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">12.</div>
                <div>
                    <span className="elite-bold">Unemployment Compensation.</span>
                    <span>
            eJAmerica shall make no state or federal unemployment compensation payments on behalf of the eJElite Connect. Accordingly, eJElite Connect will not be entitled to these benefits in connection with work performed under this Agreement.
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">13.</div>
                <div>
                    <span className="elite-bold">Workers' Compensation.</span>
                    <span>
            eJAmerica shall not be responsible for any kind of workers' compensation insurance on behalf of eJElite Connect and/or Contractor's employees engaged pursuant to this Agreement's terms.
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">14.</div>
                <div>
                    <span className="elite-bold">Indemnification.</span>
                    <span>
            eJElite Connect shall indemnify and hold the eJAmerica harmless from any loss or liability, or encumbrances arising from performing services under this Agreement.
                    </span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">15.</div>
                <div>
                    <span className="elite-bold">Limitation of Liability.</span>
                    <div className="elite-list">
                        <ol type="a">
                            <li>In no case shall eJAmerica be liable to the eJElite Connect for damages caused by Contractor's own acts or omissions in Contractor's performance of the Services.</li>
                            <li>eJAmerica is not liable to the eJElite Connect or to any third party for any negligent act or omission or willful misconduct by the eJElite Connect or Contractor's employees, agents, contractors, representatives, consultants, or subcontractors.</li>
                            <li>
                    eJAmerica is not liable to the eJElite Connect or any third party in connection with:
                                <ol className="ml-5" type="I">
                                    <li>Any third-party claim that the eJElite Connect (and not eJAmerica) has violated any Federal, State, or local law or regulation.</li>
                                    <li>Any delay in the performance of the Services caused by reasons beyond the eJAmerica’s control or that eJAmerica cannot reasonably remedy ("Force Majeure Events").</li>
                                    <li>Any claim arising out of Contractor's failure to name eJAmerica as your subcontractor if the Contractor's Contract with an end-user (or an RFP) requires the eJElite Connect to name Contractor's subcontractors.</li>
                                </ol>
                            </li>
                            <li>eJAmerica’s maximum liability for damages (except for personal injury or death or damage to the Contractor's physical property), regardless of the form of action, will not exceed the total amount paid by eJAmerica for the Services out of which the claim arose.</li>
                            <li>
                                <strong>NEITHER PARTY WILL BE LIABLE TO THE OTHER FOR ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF GOODWILL, LOSS OF BUSINESS ADVANTAGE, OR LOST DATA, EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</strong>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">16. </div>
                <div>
                    <span className="elite-bold">Force Majeure Events. </span>
                    <span>
            Neither party will be liable for any default or delay of its performance or obligations under this Agreement to the extent that default or delay is caused, directly or indirectly, by any or all of the following:
                        <ul>
                            <li>A natural disaster, or what is commonly referred to as an "act of God," including but not limited to fire, flood, earthquake, or other elements of nature.</li>
                            <li>Civil disorders, including but not limited to riot, rebellion, or revolution.</li>
                            <li>Quarantines or other governmental actions.</li>
                            <li>Malicious acts of third parties or labour disputes.</li>
                            <li>Any other cause that a party could not have anticipated or planned for or that is beyond the reasonable control of a party.</li>
                        </ul>
                    </span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">17. </div>
                <div>
                    <span className="elite-bold">Exclusive Agreement. </span>
                    <span>
            This is the entire Agreement between eJElite Connect and eJAmerica.
                    </span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">18. </div>
                <div>
                    <span className="elite-bold">Amendment to the Agreement. </span>
                    <span>
            This Agreement may be modified only by writing, signed by both parties.
                    </span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">19. </div>
                <div>
                    <span className="elite-bold">Dispute Resolution. </span>
                    <span>
            If a dispute arises under this Agreement, the parties agree first to try to resolve the dispute with the help of a mutually agreed-upon mediator in Orange County, CA. The prevailing party shall bear any costs and fees, including attorney fees, associated with the mediation. If it proves impossible to arrive at a mutually satisfactory solution through mediation, the parties agree to submit the dispute to a mutually agreed-upon arbitrator in Orange County, CA. Judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction to do so.
                    </span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">20. </div>
                <div>
                    <span className="elite-bold">Confidentiality. </span>
                    <span>
            The eJElite Connect acknowledges that it will be necessary for the eJAmerica to disclose certain confidential and proprietary information to the eJElite Connect in order for the eJElite Connect to perform duties under this Agreement. The eJElite Connect acknowledges that disclosure to a third party or misuse of this proprietary or confidential information would irreparably harm the eJAmerica. Accordingly, eJElite Connect will not disclose or use, either during or after the term of this Agreement, any proprietary or confidential information of eJAmerica without eJAmerica’s prior written permission except to the extent necessary to perform services on eJAmerica’s behalf, in compliance with the terms and conditions of this Agreement.
                        <br /><br />
                        <span className="elite-bold">Proprietary or confidential information includes:</span>
                        <ul>
                            <li>The written, printed, graphic, or electronically recorded materials furnished by the eJAmerica for the eJElite Connect to use.</li>
                            <li>Any written or tangible information stamped "confidential," "proprietary," or with a similar legend, or any information that the eJAmerica makes reasonable efforts to maintain the secrecy of.</li>
                            <li>Verbal or written business or marketing plans or strategies, customer lists, operating procedures, trade secrets, design formulas, know-how and processes, computer programs and inventories, discoveries, and improvements of any kind, sales projections, and pricing information.</li>
                            <li>Information belonging to customers and suppliers of eJAmerica about whom eJElite Connect gained knowledge as a result of Contractor's services to eJAmerica.</li>
                        </ul>
            Upon termination of Contractor's services to eJAmerica, or at eJAmerica’s request, eJElite Connect shall deliver all materials to eJAmerica in Contractor's possession relating to eJAmerica’s business.
                        <br /><br />
            The eJElite Connect acknowledges that any breach or threatened breach of Clause 20 of this Agreement will result in irreparable harm to eJAmerica, for which damages would be an inadequate remedy. Therefore, the eJAmerica shall be entitled to equitable relief, including an injunction, in the event of such breach or threatened breach of Clause 20 of this Agreement. Such equitable relief shall be in addition to the eJAmerica's rights and remedies otherwise available at law.
                        <br /><br />
                        <span className="elite-bold">Non-Competition: </span>
            eJElite Connect covenants and agrees that, during the term of Contractor's work with the Company and for a period of twelve (12) months after termination thereof, regardless of the reason for termination, eJElite Connect will not directly or indirectly engage in activities that substantially compete with the services provided by eJAmerica, within jurisdictions where such restrictions are enforceable under applicable law. This clause shall not apply to jurisdictions where non-compete agreements are prohibited.
                        <br /><br />
                        <span className="elite-bold">Non-Solicitation of Customers, Customer Prospects, Customer Contacts, and Vendors: </span>
            The eJElite Connect also covenants and agrees that during the term of the Contractor's work with the Client and for thirty-six (36) months after the termination thereof, regardless of the reason for the work termination, eJElite Connect will not, directly or indirectly, solicit or attempt to solicit any business from any of the eJAmerica’s Customers, Customer Prospects, Customer Contacts, Customer Key People (if our customers, vendors, or partners contacts or employees join other companies, eJElite Connect cannot solicit business through such contacts), or Vendors with whom eJElite Connect had material contact during years of Contractor's IC relationship with the eJAmerica.
                        <br /><br />
                        <span className="elite-bold">Non-Solicitation of Employees: </span>
            eJElite Connect also covenants and agrees that during the term of Contractor's work with the Client and for thirty-six (36) months after the termination thereof, regardless of the reason for the work termination, eJElite Connect will not, directly or indirectly, on Contractor's own behalf or on behalf of or in conjunction with any person or legal entity, recruit, solicit, or induce, or attempt to recruit, solicit, or induce, any non-clerical employee of the eJAmerica with whom eJElite Connect had personal contact or supervised while performing Contractor's Job Duties, to terminate their employment relationship with the eJAmerica.
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">21. </div>
                <div>
                    <span className="elite-bold">Proprietary Information. </span>
                    <span>
                        <span className="elite-bold">A. </span>
            The product of all work performed under this Agreement ("Work Product"), including without limitation, all notes, reports, documentation, drawings, computer programs, inventions, creations, works, devices, models, work-in-progress, and deliverables will be the sole property of the eJAmerica, and eJElite Connect hereby assigns to the eJAmerica all right, title, and interest therein, including but not limited to all audiovisual, literary, moral rights and other copyrights, patent rights, trade secret rights, and other proprietary rights therein. The eJElite Connect retains no right to use the Work Product and agrees not to challenge the validity of the eJAmerica’s ownership of the Work Product.
                        <br /><br />
                        <span className="elite-bold">B. </span>
            eJElite Connect hereby assigns to the eJAmerica all right, title, and interest in any and all photographic images and videos or audio recordings made by the eJAmerica during Contractor's work for them, including, but not limited to, any royalties, proceeds, or other benefits derived from such photographs or recordings.
                        <br /><br />
                        <span className="elite-bold">C. </span>
            The eJAmerica will be entitled to use the Contractor's name and/or likeness used in advertising and other materials.
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">22. </div>
                <div>
                    <span className="elite-bold">No Partnership, No JV, and No Agency. </span>
                    <span>
            This Agreement does not create a partnership, joint venture, or agency relationship between the parties. The eJElite Connect is an independent contractor and does not have the authority to act on behalf of, represent, or bind eJAmerica in any contractual or legal matters unless explicitly authorized in writing by eJAmerica.
                    </span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">23. </div>
                <div>
                    <span className="elite-bold">Notice. </span>
                    <span>
                    Notices under this Agreement are effective on delivery when made by personal delivery, Certified Mail (return receipt requested), or by nationally recognized courier. The notice must be in writing. The notice must be sent to the persons and to the addresses stated below each party's signature to this Agreement. Either party may change its addressee or address for notice by providing the other party with that information in accordance with this Section. Communications of a purely operational or technical nature may be made by the parties by such methods as they agree from time to time.</span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">24. </div>
                <div>
                    <span className="elite-bold">Assignment and Delegation. </span>
                    <span>
                    eJElite Connect shall not assign or subcontract any rights or delegate any of its duties under this Agreement, to any of its affiliates, without the eJAmerica’s prior written approval. Subcontracting any or all the work under this Agreement to any third party shall not be allowed in any circumstances</span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">25. </div>
                <div>
                    <span className="elite-bold">Severability. </span>
                    <span>
            If any court or tribunal determines that any provision of this Agreement is void or unenforceable, it will not affect the enforceability of any other provision; all other provisions of this Agreement will remain in full force and effect.
                    </span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">26. </div>
                <div>
                    <span className="elite-bold">Applicable Law. </span>
                    <span>
            This Agreement will be governed by California law without giving effect to conflict of laws principles.
                    </span>
                </div>
            </div>
            <div className="elite-subheading">
                <div className="elite-bold">27. </div>
                <div>
                    <span className="elite-bold">Entire Agreement. </span>
                    <span>
            This Agreement, together with its incorporated Schedule(s), is the entire Agreement of the parties with respect to the subject matter of this Agreement.
                        <br /><br />
                        <span className="elite-bold">a. </span>
            This Agreement supersedes any and all other agreements between the parties, whether written or oral.
                        <br /><br />
                        <span className="elite-bold">b. </span>
            This Agreement may be modified only by a written amendment signed by the duly authorized representatives of both parties.
                    </span>
                </div>
            </div>

            <div className="elite-subheading">
                <div className="elite-bold">28. </div>
                <div>
                    <span className="elite-bold">Warrant of Authority. </span>
                    <span>
            By clicking 'Accept,' you confirm that you have the authority to bind the party you represent and agree to be bound by these terms.
                    </span>
                </div>
            </div>
            <div className="elite-schedule">
                <h2 className="elite-center-heading">SCHEDULE A: The Services</h2>
                <p>
        Services and responsibilities that eJElite Connect provides as a eJElite Connect for eJAmerica shall be:
                </p>
                <ul>
                    <li>
                        <strong>Identifying and evaluating potential partners:</strong> The eJElite Connect would conduct research to identify potential partners that align with eJAmerica's business objectives and strategy. They would evaluate potential partners based on criteria such as their industry focus, customer base, and expertise in complementary areas.
                    </li>
                    <li>
                        <strong>Building and maintaining relationships:</strong> The eJElite Connect would serve as the point of contact for potential partners, building and maintaining strong relationships with key decision-makers and influencers. They would work to establish trust and credibility with potential partners and develop a deep understanding of their business needs and goals.
                    </li>
                    <li>
                        <strong>Facilitating collaboration and communication:</strong> The eJElite Connect would serve as a liaison between eJAmerica and its partners, facilitating communication and collaboration between the two organizations. They would work to ensure that each partner has a clear understanding of the other's capabilities and identify opportunities for joint marketing, sales, and other initiatives.
                    </li>
                    <li>
                        <strong>Monitoring and reporting on partnership performance:</strong> The eJElite Connect would monitor the performance of eJAmerica's partnerships, and report on key metrics such as revenue growth, customer acquisition, and customer satisfaction. They would identify areas for improvement and work with partners to implement changes that improve performance.
                    </li>
                    <li>
                        <strong>Staying up to date on industry trends and best practices:</strong> The eJElite Connect would stay abreast of industry trends and best practices in strategic alliances and apply this knowledge to help eJAmerica identify new partnership opportunities and improve its partnership strategies.
                    </li>
                    <li>
                        <strong>Coordinating joint marketing and promotional activities:</strong> The eJElite Connect would work with eJAmerica's marketing team to develop joint marketing and promotional activities with partners. This could include joint email campaigns, co-branded content, and social media promotions. The eJElite Connect would also help partners to promote eJAmerica's services through their own marketing channels.
                    </li>
                    <li>
                        <strong>Supporting event participation:</strong> The eJElite Connect would help eJAmerica to identify relevant industry events and conferences where the company could participate, and coordinate participation with partners. This could include securing speaking opportunities, coordinating booth space and logistics, and identifying networking opportunities.
                    </li>
                    <li>
                        <strong>Negotiating and closing partnership agreements:</strong> The eJElite Connect would work with eJAmerica's legal and executive teams to negotiate and finalize partnership agreements with potential partners. They would ensure that the terms of the agreement are aligned with eJAmerica's business objectives and are mutually beneficial for both parties.
                    </li>
                    <li>
                        <strong>Providing market research and insights:</strong> The eJElite Connect would conduct research to identify trends and insights in the IT services industry and share these insights with eJAmerica and its partners. They would help eJAmerica to stay abreast of changes in the market and identify new opportunities for growth.
                    </li>
                    <li>
                        <strong>Lead Review and Termination Clause:</strong> eJElite Connect agrees to conduct monthly lead reviews with eJAmerica to evaluate the progress and quality of generated leads. In the event that eJElite Connect fails to produce any leads for a continuous period of 12 months, eJAmerica reserves the right to terminate the agreement without any further obligations.
                    </li>
                </ul>
            </div>




        </div>
    );
};
export default EliteAgreement;