import React, { useEffect, useState } from "react";
import Register from "./Register";
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as Yup from 'yup';
import { Form } from "react-bootstrap";
import util from "../utils/miniUtils";
import html2pdf from "html2pdf.js";


const TermsAndCondition = ( { eliteData } ) => {
    const [isChecked, setIsChecked] = useState( false );
    const [isAccepted, setIsAccepted] = useState( false );
    const [paymentItems, setPaymentItems] = useState( [] );
    const initialValues = {
        elite_name: eliteData?.elite_name || "",
        elite_HQ_address:eliteData?.elite_HQ_address || "",
        accepted_by:eliteData?.accepted_by || "",
        designation: eliteData?.designation || ""
    };
    
    const validationSchema = Yup.object( {
        elite_name: Yup.string().required( "Legal Name is Required" ),
        elite_HQ_address: Yup.string().required( "Address is Required" ),
        accepted_by: Yup.string().required( 'Authorized Person Name is Required' ),
        designation: Yup.string().required( 'Title is Required' )

    }
    );
    const handleAccept = ( submitForm ) => {
        if ( isChecked ) {
            submitForm();
        } else {
            alert( "You must agree to the terms and conditions to proceed." );
        }
    };
    

    const handleFormSubmit = async ( values ) => {
        try {
            const response = await fetch( "/elite_agreement.html" );
            if ( !response.ok ) throw new Error( "Failed to load agreement template" );
    
            let content = await response.text();
            // const paymentHTML = paymentItems.length
            //     ? paymentItems.map( ( item, index ) =>
            //           `<div><strong>${index + 1}. ${item.name}:</strong> <p>${item.description}</p></div>`
            //     ).join( "" )
            //     : "<p>No payment items available.</p>";
    
            // Replace placeholders with actual values
            content = content
                .replace( "{{elite_name}}", values.elite_name )
                .replace( "{{elite_HQ_address}}", values.elite_HQ_address )
                .replace( "{{accepted_by}}", values.accepted_by )
                .replace( "{{designation}}", values.designation )
                .replace( "{{date}}", new Date().toUTCString() );
            // .replace( "{{payment_items}}", paymentHTML );
    
            
            const response2 = await util.axios.post(
                '/generate-pdf',
                { html: content, values, elite_connect_id: eliteData.elite_connect_id, invitation_id: eliteData.invitation_id },
                {
                    responseType: 'blob', // make axios expect blob
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            const blob = new Blob( [response2.data], { type: 'application/pdf' } );
            const url = window.URL.createObjectURL( blob );
            const link = document.createElement( 'a' );
            link.href = url;
            link.download = `eJElite_Agreement_${values.elite_name}_${eliteData.elite_connect_id}.pdf`;
            document.body.appendChild( link );
            link.click();
            document.body.removeChild( link );
            window.URL.revokeObjectURL( url ); // Clean up
        

            
        } catch ( error ) {
            console.error( "PDF generation failed:", error );
        }
    };
    
    

    if( isAccepted ) {
        return (
            <Register email={eliteData.user_email} isAccepted={isAccepted}  />
        );
    }
    return (
       
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={( values, { setSubmitting } ) => {
                handleFormSubmit( values );
                setIsAccepted( true );
                setSubmitting( false );
            }}
        >
            {( { isSubmitting, submitForm } ) => (
                <>
                    <FormikForm id="terms-form">
                        <div className="terms-container">
                        
                            <h2 className="terms-main-title">Terms and Conditions</h2>
                            <p className="terms-intro"> 
                By using our services, you agree to the following terms and conditions.
                            </p>

                            <div className="terms-section">
                                <div className="mb-2">
            This eJElite Connect Agreement is made between <span className="elite-bold">eJangar, Inc.</span> ("eJAmerica"), a California incorporation located at <span className="elite-bold">100 Spectrum Center Drive Ste 949 Irvine, CA USA 92618</span>
                                </div>
                                <div className="elite-and"><p>AND</p></div>
                                <div>
                                    <div><strong>Elite Connect:</strong></div>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Legal Name
                                            <small className='text-muted'>(Please enter the legal name of the entity or individual)</small>
                                            <Field 
                                                name="elite_name"
                                                type="text"
                                                className="form-control"
                                                placeholder="Please enter the legal name"
                                            />
                                            <ErrorMessage name="elite_name" component="div" className="error" />
                                        </Form.Label>
                                    </Form.Group>

                                    <Form.Group className='mb-3'>
                                        <Form.Label>HQ Address
                                            <small className='text-muted'>(Please enter the headquarters address)</small>
                                            <Field
                                                name="elite_HQ_address"
                                                type="text"
                                                className="form-control"
                                                placeholder="Please enter the HQ address"
                                            />
                                            <ErrorMessage name="elite_HQ_address" component="div" className="error" />
                                        </Form.Label>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Authorized Person Accepting this agreement
                                            <small className='text-muted'>(Name of the authorized person accepting this agreement)</small>
                                            <Field
                                                name="accepted_by"
                                                placeholder="Enter the name of the authorized person"
                                                className="form-control"
                                                type="text"
                                            />
                                            <ErrorMessage name="accepted_by" component="div" className="error" />
                                        </Form.Label>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Designation
                                            <small className='text-muted'>(Enter the title/designation of the authorized person)</small>
                                            <Field
                                                type="text"
                                                placeholder="Please enter the title/designation"
                                                className="form-control"
                                                name="designation"
                                            />
                                            <ErrorMessage name="designation" component="div" className="error" />
                                        </Form.Label>
                                    </Form.Group>


                                </div>
                                <p>
    For a full breakdown of the services and responsibilities provided by eJElite Connect, please refer to  
                                    <a href="/eliteconnect/terms&conditions" target="_blank" rel="noopener noreferrer" className="elite-link">
                    eJElite Connect Service Agreement
                                    </a>.
                                </p>

                
                            </div>

                            <div className="terms-section">
                                <h2 className="terms-title">SCHEDULE B: Payment</h2>
                                <p>The payment details will be shown once you join Elite Connect</p>
                                {/* <h2 className="terms-title">SCHEDULE B: Payment</h2>
                                {paymentItems.map(
                                    ( item, index ) => (
                                        <div key={item.id}>
                                            {index+1} . <strong>{item.name} :</strong><p>{item.description}</p>
                                        </div>
                                    )
                                )}
                                 */}
                            </div>
                            <div>
                            By clicking "Accept," you acknowledge that your electronic acceptance constitutes your legal signature on this Agreement, with the same legal effect as a handwritten signature. Upon acceptance, a PDF copy of this fully executed Agreement will be generated and provided to you for your records, evidencing acceptance by both parties.
                            </div>
                            <div className="terms-agreement">
                                <label className="terms-label">
                                    <input
                                        type="checkbox"
                                        className="terms-checkbox"
                                        checked={isChecked}
                                        onChange={() => setIsChecked( !isChecked )}
                                    />
                    I agree to the terms and conditions
                                </label>
                                <button className="btn btn-secondary activeBtn sizeBtn" disabled={!isChecked}  onClick={() => handleAccept( submitForm )}>
                    Accept
                                </button>
                            </div>
                        </div>
                    </FormikForm>
                </>
            )

            }
               
        </Formik>
        
    );
};

export default TermsAndCondition;
