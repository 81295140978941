import { faAngleLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Drawer } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import util from '../../../utils/miniUtils';
import { Editor } from '@tinymce/tinymce-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const WorkHistoryDrawer = ( { isDrawerOpen, toggleDrawer, workload_id, statusList } ) => {
    const [workload, setWorkload] = useState( {} );
    const [workHistory, setWorkHistory] = useState( "" );
    const [newWorkHistory, setNewWorkHistory] = useState( "" );

    const handleWorkHistoryEditor = ( e ) => {
        const value = e.toString( "html" );
        setNewWorkHistory( value );
    };

    const getStatusName = ( value ) => {
        const statusName = value && statusList.find( el => {
            if( el.id == ( value ) ) {
                return el.name;
            } 
        } );
        return statusName && statusName.name !== undefined ? statusName.name : 'Error Loading';
    };

    const getDate = ( value ) => {
        let d = new Date( +value );
        return d;
    };

    const getContent = ( content ) => {
        let result = content.split( ', ' );
        result.shift();
        result.shift();
        result.shift();
        return result.join( ', ' ); 
    };

    const getWorkloadDetail = async ( workload_id = null ) => {
        try {
            const request = await util.axios.get( `/workload/get_workload_detail/${workload_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setWorkload( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const updateWorkHistory = async ( value ) => {
        try {
            const request = await util.axios.patch( `/customer_ticket/update_workload/${workload_id}`, { work_history: value } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getWorkloadDetail( workload_id );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleWorkHistory = ( obj, inputData ) => {
        if( inputData && inputData.length ) {
            let d = Date.now();
            if( obj.work_history ) {
                let prevWorkHistory = obj.work_history;
                return `${d}, ${workload.status}, ${util.user.id}, ${inputData}|,|${prevWorkHistory}`;
            } else {
                return `${d}, ${workload.status}, ${util.user.id}, ${inputData}`;
            }
        }
    };

    const saveWorkHistory = () => {
        if( newWorkHistory.length > 0 ) {
            const updatedWorkHistory = handleWorkHistory( workload, newWorkHistory );
            updateWorkHistory( updatedWorkHistory );
            setNewWorkHistory( "" );
        }
    };

    useEffect( () => {
        getWorkloadDetail( workload_id );
    },[] );

    useEffect( () => {
        if( workload.id ) {
            setWorkHistory( workload.work_history !== null && workload.work_history.split( "|,|" ) );
        }
    },[workload] );

    return <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer( false )}
    >
        <div className="d-flex flex-column justify-content-between" style={{ maxWidth:window.innerWidth * .30 ,minWidth:window.innerWidth * .30 }}>
            <div style={{ height: "calc(100vh - 158px)" }}>
                <div className="drawerTitle_JP" style={{ height: "4rem" }}>
                    {workload.ticket_number}
                </div>
                <ul style={{ maxHeight: "calc(100% - 72px)", overflowY: "auto" }} className="customer_workHistory_drawer_list">
                    {workHistory && workHistory.map( ( item ) => item && (
                        <li
                            className="p-2 rounded m-2"
                            style={item.split( ", " )[2] == workload.resolver_id ? { background:"#f8f9fa" } : { background: "#E2EEFE" }}>
                            <ShowUserName user_id={item.split( ", " )[2]} />
                            <div className="d-flex justify-content-between">
                                <span className="w-100 mr-1" dangerouslySetInnerHTML={{ __html: getContent( item ) }}></span>
                            </div>
                            <div className="d-flex justify-content-between w-100 xs-fontSize">
                                <span>{item && getDate( item.split( ", " )[0] ).toLocaleString( "US" )}</span>
                                <span className="workHistory_status">{getStatusName( item.split( ", " )[1] )}</span>
                            </div>
                        </li>
                    ) )
                    }
                    {workload.long_description ? <li  className="p-2 rounded m-2 bg-light">
                        <span className='badge bg-secondary text-light'>Description:</span>
                        <div dangerouslySetInnerHTML={{ __html: workload.long_description }}></div>
                    </li> : ""}
                </ul>
            </div>
            <div className="d-flex px-2 mb-2 align-items-start">
                <div className='w-100'>
                    {/* <Editor
                        apiKey='co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p'
                        value={newWorkHistory || ""}
                        init={{
                            height: 145,
                            menubar: false,
                            branding: false,
                            statusbar: false,
                            toolbar: false,
                        }}
                        onEditorChange={handleWorkHistoryEditor}
                    /> */}
                    <ReactQuill
                        value={newWorkHistory || ""}
                        onChange={handleWorkHistoryEditor}
                        modules={{
                            toolbar: [
                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                ['bold', 'italic', 'underline'],
                                [{ 'align': [] }],
                                ['clean']
                            ],
                        }}
                        formats={[
                            'header', 'font',
                            'bold', 'italic', 'underline',
                            'list', 'bullet',
                            'align'
                        ]}
                        style={{ height: '145px' }}
                    />
                </div>
                <button className="btn btn-sm btn-light ml-1" onClick={saveWorkHistory}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </div>
        </div>
    </Drawer>;
};

export default WorkHistoryDrawer;


const ShowUserName = ( { user_id } ) => {
    const [user, setUser] = useState( [] );

    const getUser = async ( id ) => {
        try {
            const request = await util.axios.get( `/customer_ticket/customer_resolver/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setUser( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    useEffect( () => {
        getUser( user_id );
    },[] );

    return <span className="badge bg-primary text-light">{user.length > 0 && user[0].name}</span>;
};