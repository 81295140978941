import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form, Col, Row, Container, Card } from 'react-bootstrap';
import util from '../../utils/miniUtils';
import MemberList from './MemberList';
import CountryFillOne from '../EmployerSettings/CountryFillOne';
import validator from 'validator';


const EmployerDetails = ( { onBackClick,selectedEmployer } ) => {
    const [industries, setIndustries] = useState( [] );
    const isReadOnly = util.user.role === 18;
    const isEmployerProgramAdmin=util?.user?.permissions?.employer_program_admin === 1;
    const [showMembersTable, setShowMembersTable] = useState( false );
    const [employerDetails, setEmployerDetails] = useState( {} );
    // const [groupsList, setGroupsList] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [attachmentFiles, setAttachmentFiles] = useState( [] );
    const [logoImage, setLogoImage] = useState( null );
    const [logoUrl, setLogoUrl] = useState( null ); // State to hold the fetched logo URL
    const [errors, setErrors] = useState( {} );
    const fetchIndustries = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/industries` );
            const { error, msg, data } = request.data;
        
            if ( error ) throw new Error( msg );
        
            setIndustries( data ); 
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };
    useEffect( ()=>{
        fetchIndustries();
    },[] );
    useEffect( () => {
        const fetchLogoUrl = async () => {
            if ( employerDetails && employerDetails.company_logo_file ) {
                const url = await util.fetchImageUrl( `src/public/employer_logos/${employerDetails.employer_id}/${employerDetails.company_logo_file}` );
                setLogoUrl( url ); // Set the fetched URL to state
            }
        };
    
        fetchLogoUrl();
    }, [employerDetails] );

    // const handleChange = ( e ) => {
    //     const { name, value } = e.target;
    //     setEmployerDetails( prev => ( { ...prev, [name]: value } ) );
    //     setErrors( prevErrors => ( { ...prevErrors, [name]: "" } ) );
    // };
    const handleChange = ( e ) => {
        const { name, value } = e.target;
    
        if ( name === "employer_email" ) {
            const originalEmail = employerDetails.employer_email || "";
            const originalDomain = originalEmail.includes( "@" ) ? originalEmail.split( "@" )[1] : "";
            let domain = "";
    
            // Extract domain from email
            if ( value.includes( "@" ) ) {
                domain = value.split( "@" )[1];
            }
    
            // If adding a new employer (no employer_id), allow full email editing
            if ( !employerDetails.employer_id ) {
                setEmployerDetails( ( prev ) => ( {
                    ...prev,
                    [name]: value,
                    email_domain: domain, // Auto-populate email domain
                } ) );
    
                setErrors( ( prevErrors ) => ( {
                    ...prevErrors,
                    employer_email: validator.isEmail( value ) ? "" : "Invalid email format",
                    email_domain: domain ? "" : "Invalid domain",
                } ) );
    
                return;
            }
    
            // If updating an existing employer, restrict domain changes
            if ( !value.includes( "@" ) ) {
                setEmployerDetails( ( prev ) => ( {
                    ...prev,
                    [name]: value,
                } ) );
                return;
            }
    
            const [localPart, newDomain] = value.split( "@" );
    
            if ( newDomain && newDomain !== originalDomain ) {
                setErrors( ( prevErrors ) => ( {
                    ...prevErrors,
                    employer_email: "Email domain cannot be changed.",
                } ) );
                return;
            } else {
                setErrors( ( prevErrors ) => {
                    const updatedErrors = { ...prevErrors };
                    delete updatedErrors.employer_email;
                    return updatedErrors;
                } );
            }
    
            setEmployerDetails( ( prev ) => ( {
                ...prev,
                [name]: value,
                email_domain: domain, // Ensure domain updates
            } ) );
        } else {
            setEmployerDetails( ( prev ) => ( {
                ...prev,
                [name]: value,
            } ) );
        }
    
        setErrors( ( prevErrors ) => ( {
            ...prevErrors,
            [name]: "",
        } ) );
    };
    
    
    

    const toggleMembersView = () => {
        setShowMembersTable( !showMembersTable );
    };

    const handleSave = ( e ) => {
        e.preventDefault();
        if ( !validateFields() ) return;
        if( employerDetails.employer_id ) {
            updateEmployer( employerDetails.employer_id, employerDetails );
        } else {
            addNewEmployer( employerDetails );
        }
    };

   
    

    const getEmployerDetails = async ( id ) => {
        try {
            const request = await util.axios.get( `/employers/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setEmployerDetails( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const validateFields = () => {
        let tempErrors = {};
        if ( !employerDetails.company_legal_name?.trim() ) tempErrors.company_legal_name = "Company legal name is required";
        if ( !employerDetails.employer_email || !validator.isEmail( employerDetails.employer_email ) ) tempErrors.employer_email = "Employer email is required";
        if ( !employerDetails.employer_website || !validator.isURL( employerDetails.employer_website ) ) tempErrors.employer_website = "Employer website is required";
        if ( !employerDetails.company_tax_id?.trim() ) tempErrors.company_tax_id = "Company tax ID is required";
        if ( !employerDetails.address?.trim() ) tempErrors.address = "Address is required";
        if ( !employerDetails.email_domain?.trim() ) tempErrors.email_domain = "Email domain is required";
        if ( !employerDetails.status?.trim() ) tempErrors.status = "Status is required";
        if ( !employerDetails.industry?.trim() ) tempErrors.industry = "Industry is required";
        
        setErrors( tempErrors );
        return Object.keys( tempErrors ).length === 0;
    };
   

    
    const addNewEmployer = async ( employer ) => {
        try {
            const request = await util.axios.post( "/employers", { ...employer } );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.toast().error( error );
        }
    };

    const updateEmployer = async ( id, employer ) => {
        try {
            const request = await util.axios.put( `/employers/${id}`, { ...employer } );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.toast().error( error );
        }
    };
    function handleImgUpload( e ) {
        const file = e.target.files[0];
        if ( file ) {
            setLogoImage( file );
            // console.log( "Selected file:", file.name );
        }
    }
    async function uploadLogo() {
        try {
            if ( !logoImage ) {
                util.toast().error( "Please select a logo to upload." );
                return;
            }
        
            const data = new FormData();
            data.append( "file", logoImage );
        
            // const employerId = util.user.employer_id; // Get the employer ID from the current user's data
            const request = await util.axios.post( `/employer/upload_comapny_logo/${employerDetails.employer_id}`, data );
            const { error, msg, employerData } = request.data;
        
            if ( error ) throw new Error( msg );
        
            util.toast().success( msg || "Logo uploaded successfully." );
        
            // Update the employer state with the new data
            setEmployerDetails( ( prevEmployer ) => ( {
                ...prevEmployer,
                company_logo_file: employerData.company_logo_file,
            } ) );
        
            setLogoImage( null ); // Clear the selected file
        } catch ( error ) {
            util.handleError( error );
        }
    }
        
        
    async function removeLogo() {
        try {
            // const employerId = util.user.employer_id;
            const request = await util.axios.delete( `/employer/delete_company_logo/${employerDetails.employer_id}` );
            const { error, msg } = request.data;
        
            if ( error ) throw new Error( msg );
        
            util.toast().success( msg || "Logo removed successfully." );
        
            setEmployerDetails( ( prevEmployer ) => ( {
                ...prevEmployer,
                company_logo_file: null,
            } ) );
        } catch ( error ) {
            util.handleError( error );
        }
    }

    useEffect( () => {
     
        if( selectedEmployer && selectedEmployer.employer_id ) {
            getEmployerDetails( selectedEmployer.employer_id );
        }
    },[] );

    if( loading ) {
        return util.LOADING_SCREEN( loading );
    }
    const badgeTypes = [
        'primary',
        'secondary',
       
    ];

    const badgeTexts = [
        'Active',
        'Inactive',
        
    ];

    const getBadgeText = ( status ) => {
        if ( !status ) return ""; // Handle empty case
        const formattedStatus = status.toLowerCase();
    
        if ( formattedStatus === "active" ) return "Active";
        if ( formattedStatus === "inactive" ) return "Inactive";
    
        return ""; // Fallback for unexpected values
    };
    

    const getBadgeMarkup = ( status ) => {
        if ( !status ) return null; // Do not render anything if status is empty
    
        const formattedStatus = getBadgeText( status );
        const index = badgeTexts.indexOf( formattedStatus );
    
        if ( index === -1 ) return null; // Safety check, avoid rendering if status is invalid
    
        const badgeType = badgeTypes[index];
    
        return (
            <div className="d-flex justify-content-end">
                <span className={`badge badge-${badgeType}`}>
                    {formattedStatus} {/* Ensures only one status is displayed */}
                </span>
            </div>
        );
    };
    return (
        <>
            <div className="d-flex align-items-center mb-3">
                <Button variant="link" onClick={onBackClick} className="d-flex align-items-center mr-2">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
                </Button>
                
                {!selectedEmployer ? "" : <Button className='btn activeBtn sizeBtn btn-secondary mr-2' onClick={toggleMembersView}>
                    {showMembersTable ? 'Info' : 'Members'}
                </Button>}
            </div>
            {showMembersTable ? <MemberList selectedEmployer={selectedEmployer} /> 
                : <div>
                    <Row style={{ marginLeft: '3%', marginTop: '20px' }}>
                        <Col xs={6} className="logo-placeholder">
                        </Col>

                    </Row>
                    <Container className="mt-3">
                        <Card className='ml-4'>
                            <Card.Header as="h5">
                                
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="d-flex align-items-center">
                                        {employerDetails.company_logo_file && (
                                            <div>
                                                <img
                                                    style={{ 
                                                        width: "50px", 
                                                        height: "50px", 
                                                        maxWidth: "150px", 
                                                        objectFit: "contain" 
                                                    }}
                                                    src={logoUrl} 
                                                    alt="logo"
                                                />
                                            </div>
                                        )}
                                        {employerDetails.company_legal_name && (
                                            <p className="mt-3 ml-2">{employerDetails.company_legal_name} Details</p>
                                        )}

                                    </span>

                                    <span>{getBadgeMarkup( employerDetails.status )}</span>
                                </div></Card.Header>
                            <Card.Body className='ml-4'>

                                <Form>
                                    {employerDetails.employer_id?
                                        <div className="mt-3">
                                            <b>Logo</b>
                                        
                                            <div>
                                                <label htmlFor="company_logo_file" className="btn btn-secondary activeBtn mt-3">
            Add Company Logo
                                                </label>
                                                <input
                                                    id="company_logo_file"
                                                    title="Logo"
                                                    accept="image/*, .svg, .webp"
                                                    onChange={handleImgUpload}
                                                    name="company_logo_file"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                />
                                                {logoImage ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary sizeBtn activeBtn ml-3"
                                                        onClick={uploadLogo}
                                                    >
                Upload
                                                    </button>
                                                ) : null}
                                            </div>

                                            {/* {employerDetails.company_logo_file ? (
                                            <div>
                                                <label>Preview</label>
                                                <img
                                                    style={{ maxWidth: "100px", marginLeft: "10px" }}
                                                    src={`${util.baseUrl()}${util.AWSURL()}/employer_logos/${employerDetails.employer_id}/${employerDetails.company_logo_file}`}
                                                    alt={employerDetails.company_legal_name}
                                                />
                                                <img
                                                    style={{ maxWidth: "100px", marginLeft: "10px" }}
                                                    src={logoUrl} 
                                                    alt={employerDetails.company_legal_name}
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary sizeBtn activeBtn ml-2"
                                                    onClick={removeLogo}
                                                >
                                                                    Remove
                                                </button>
                                            </div>
                                        ) : null} */}
                                
                                        </div>:""}
                                    <Row className="mb-3 mt-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formCompanyName">
                                                <Form.Label>Company Legal Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="company_legal_name"
                                                    value={employerDetails.company_legal_name || ""}
                                                    placeholder="Enter your organization's name (e.g. ABC Corp.)"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                            {errors.company_legal_name && <small className="text-danger">{errors.company_legal_name}</small>}
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formEmployerEmail">
                                                <Form.Label>Company Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="employer_email"
                                                    value={employerDetails.employer_email || ""}
                                                    placeholder="Enter your official company email (e.g. info@abc.com)"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                            {errors.employer_email && <small className="text-danger">{errors.employer_email}</small>}
                                        </Col>
                                    </Row>

                                   

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formCompanyWebsite">
                                                <Form.Label>Comapny Website</Form.Label>
                                                <Form.Control
                                                    type="url"
                                                    name="employer_website"
                                                    value={employerDetails.employer_website || ""}
                                                    placeholder="https://www.yourcompany.com"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                            {errors.employer_website && <small className="text-danger">{errors.employer_website}</small>}
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formHQAddress">
                                                <Form.Label>HQ Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="address"
                                                    value={employerDetails.address || ""}
                                                    placeholder="Enter the main office address (e.g., 123 Main St)"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                            {errors.address && <small className="text-danger">{errors.address}</small>}
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={3}>
                                            <Form.Group controlId="formYearFounded">
                                                <Form.Label>Year Founded</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="year_founded"
                                                    value={employerDetails.year_founded || ""}
                                                    placeholder="Enter the year your company was established"
                                                    onChange={handleChange}
                                                    style={{ width: "95%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group controlId="formStatus">
                                                <Form.Label>Status</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="status"
                                                    value={employerDetails.status || ""}
                                                    onChange={handleChange}
                                                    style={{ width: "60%" }}
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </Form.Control>
                                            </Form.Group>
                                            {errors.status && <small className="text-danger">{errors.status}</small>}
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formEmailDomain">
                                                <Form.Label>Email Domain</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email_domain"
                                                    value={employerDetails.email_domain || ""}
                                                    placeholder="Enter your company’s email domain (e.g., abc.co)"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                    disabled
                                                />
                                            </Form.Group>
                                            {errors.email_domain && <small className="text-danger">{errors.email_domain}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formSocialMediaLinks">
                                                <Form.Label>Social Media Links</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="social_media"
                                                    value={employerDetails.social_media || ""}
                                                    placeholder="Add links to your social media profiles (e.g., LinkedIn)"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formIndustry">
                                                <Form.Label>Industry</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="industry"
                                                    value={employerDetails.industry || ""}
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                >
                                                    <option value="">Select Industry</option>
                                                    {industries&&industries?.map( ( industry ) => (
                                                        <option key={industry.id} value={industry.id}>
                                                            {industry.name}
                                                        </option>
                                                    ) )}
                                                </Form.Control>
                                                {errors.industry && <small className="text-danger">{errors.industry}</small>}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formMaxJobLimit">
                                                <Form.Label>Maximum Job Limit</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="job_limit"
                                                    value={employerDetails.job_limit || 3}
                                                    placeholder="Max number of jobs this employee can post"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                    readOnly={isReadOnly || isEmployerProgramAdmin}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formStatus">
                                                <Form.Label>Maximum Number of Members</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="limit"
                                                    value={employerDetails.employee_limit || 2}
                                                    onChange={handleChange}
                                                    readOnly={isReadOnly || isEmployerProgramAdmin}
                                                    style={{ width: "80%" }} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formAvailableJobsToPost">
                                                <Form.Label>Available Jobs To Post</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="job_count"
                                                    value={employerDetails.job_count ||""}
                                                    placeholder="Available Jobs to Post"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                    readOnly={isReadOnly || isEmployerProgramAdmin}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formEmployeeCount">
                                                <Form.Label>Member Count</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="employee_count"
                                                    value={employerDetails.employee_count || ""}
                                                    placeholder="Total number of employees"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                    readOnly={isReadOnly || isEmployerProgramAdmin}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                  
                                   
                                    <Row className="mb-3">
                                        
                                        <CountryFillOne
                                            newState={employerDetails}
                                            setNewState={setEmployerDetails}
                                            onChangeHandler={handleChange}
                                            isReadOnly={isReadOnly}
                                            attachmentFiles={attachmentFiles}
                                            setAttachmentFiles={setAttachmentFiles}
                                            employerId={employerDetails.employer_id}
                                            errors={errors}
                                        />
                                    </Row>

                                    <div className="float-right" style={{ marginRight:'50px' }}>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-secondary activeBtn sizeBtn"
                                            onClick={handleSave}
                                        >
                        Save
                                        </button>
                                    </div>
                                   
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>

                </div>}
        </>
    );
};

export default EmployerDetails;