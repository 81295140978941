import React, { useEffect, useState } from "react";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "@material-ui/core";
import './styles.css';
import { JOB_COLLABORATION } from "../../../../constants";
import util from "../../../utils/miniUtils";
import CollaborationSingle from "./CollaborationSingle";

const TeamCollaboration = ( { show, closeDrawer, id } ) => {

    const [state, setState] = useState( {
        loading: true,
        data: [
            // {
            //     desc: "Creating the dummy data",
            //     createdAt: "2021-02-23 09:00:37",
            //     account: {
            //         name: "Mohit Singh Parihar",
            //         candidate_id: "6468",
            //         profile_picture: ""
            //     }
            // },
        ],
        showForm: false,
        desc:""
    } );

    const onChange = ( e ) => {
        setState( { ...state, desc: e.target.value } );
    };    
    
    const getCollaborationData = async () => {
        try {
            setState( { ...state, loading: true } );
            const request = await util.axios.get( `/job/collaboration/list/${id}/${JOB_COLLABORATION}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setState( { ...state, loading: false, data: data } );
        }catch ( error ) {
            setState( { ...state, loading: false } );
            util.handleError( error );
        }
    };   

    const saveCollaboration = async () => {
        try {
            setState( { ...state, loading: true } );
            const info = {
                user_id: util.user.id,
                desc: state.desc,
                fk_item_id: id
            };
            const request = await util.axios.post( `/job/collaboration/create/${id}/${JOB_COLLABORATION}`, info );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setState( { ...state, loading: false, data: data, desc: "", showForm: false } );
        }catch ( error ) {
            setState( { ...state, loading: false } );
            util.handleError( error );
        }
    };
    const deleteCollectionSingle = async ( id ) => { // Updated to accept id
        try {
            const request = await util.axios.delete( `/job/collaboration/delete/${id}/${JOB_COLLABORATION}` ); // Changed to DELETE request
            const { error, msg,data } = request.data; // Removed data as it's not used
            if ( error ) throw msg;
            setState( { ...state, loading: false, data: data } );
        }catch ( error ) {
            setState( { ...state, loading: false } );
            util.handleError( error );
        }
    };   
          
    useEffect( ()=>{
        if ( show )
            getCollaborationData();
    },[ show] );

    return (
        <Drawer anchor="right" open={show} onClose={closeDrawer}>
            <div className="collab-container container" style={{ width: .45 * window.innerWidth }}>
                <div className="d-flex justify-content-center collab-header align-items-center">
                    <h2 className="p-3">Collaboration</h2>
                </div>
                <div className="row collab-dataItems">
                    <div className="col">

                        {
                            state.data.map( ( d,i )=>{
                                return <div className="row mb-1" key={i}>
                                    <CollaborationSingle data={d} deleteCollectionSingle={deleteCollectionSingle} />
                                </div>;
                            } )
                        }
                    </div>
                </div>
                <div className="row collab-new">
                    <div className="col">
                        <div className="row align-items-center py-2">
                            <div className="col-md-2"></div>
                            <div className="col-md-8 d-flex align-items-center p-0">
                                <textarea
                                    className="form-control me-2"
                                    value={state.desc}
                                    onChange={onChange}
                                />
                                <button
                                    onClick={saveCollaboration}
                                    className="btn btn-primary rounded-circle ml-2"
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </div>

                
            </div>
           
        </Drawer>
    );
};
export default TeamCollaboration;