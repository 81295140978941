import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { setCandidateField } from '../../../../redux/actions/candidateProfile';
import util from "../../../utils/miniUtils";
import Evaluations from "./Evaluations";
import { Tooltip } from "@material-ui/core";
import { TalentStore } from "../TalentStore";

function EvaluationsContainer( { talent_id,isEditing } ) {

    const [evaluations, setEvaluations] = useState( [] );
    const [skills, setSkills] = useState( [] );
    const [testScore] = useState( 0 );

    useEffect( () => {
        getTalentEvaluations().then( () => null );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    useEffect( () => {
        rowSkills();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaluations] );

    async function getTalentEvaluations() {
        try {
            const request = await util.axios.get( `${util.url}/talent/evaluations/${talent_id}` );
            const { error, msg, evaluations } = request.data;
            if ( error ) throw msg;
            setEvaluations( evaluations );
            TalentStore.update( t => {
                t.talentEvaluation = evaluations ;
            } );
        } catch ( error ) {
            util.handleError( error );
        }
    }

    function rowSkills() {
        if ( evaluations.length > 0 ){
            // ****************** Get all the skills to create the rows
            let tempSkill = [];
            for ( let x= 0; x < evaluations.length; x++ ){
                for ( let y= 0; y < evaluations[x].skills.length; y++ ) {
                    // Validate if the skill is already in the array
                    let exist = false;
                    const { skillName } = evaluations[x].skills[y];
                    tempSkill.forEach( s =>{
                        if ( skillName === s.skill ){
                            exist = true;
                        }
                    } );
                    if ( !exist ) tempSkill.push( { skill:skillName } );
                }
            }
            let skills = tempSkill;
            //    ********** Get all evaluations from each skills from each evaluator
            for ( let x= 0; x < skills.length; x++ ){
                let array = [];
                for ( let y= 0; y < evaluations.length; y++ ) {
                    const evaluator = "evaluator" + y;
                    skills[x][evaluator] = evaluations[y].interviewer;

                    for ( let w= 0; w < evaluations[y].skills.length; w++ ) {
                        const { skillName, givenRate } = evaluations[y].skills[w];
                        if ( skillName === skills[x].skill ){
                            const rateNumber = "rate" + y;
                            skills[x][rateNumber] = givenRate;
                            array.push( givenRate );
                        }
                    }
                    let total = 0;
                    array.forEach( t=>{
                        total = total + t;
                    } );

                    skills[x].average = total = total / array.length;
                }
            }
            setSkills( skills );
        }
    }

    function totalAverage( skills ) {
        let total = 0;
        skills.forEach( s=>{
            total+= s.average;
        } );
        return ( total / skills.length ).toFixed( 2 );
    }
    // async function saveTestScore() {
    //     try {
    //         const request = await util.axios.post( `${util.url}/talent/save_score_test/${talent_id}`, { score: testScore } );
    //         const { error, msg, } = request.data;
    //         if ( error ) throw msg;
    //         util.toast().success( msg );
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }
    // }

    return (
        <div className="container-fluid mt-3" style={{ backgroundColor: "#F4F4F4" }}>
            {evaluations.length || isEditing ? <div> <b className="custom-title" style={{ color:evaluations.length=== 0 ? 'gray':'black' }}>Evaluations</b>
                <div className="col-md-12 card p-1">
                    {evaluations.length === 0 ? <div className="mt-2 text-center"><h6>No evaluations</h6></div>:
                        <Fragment>

                            <table className="table table-hover table-sm tableEvaluationsTalent">
                                <thead>
                                    <tr>
                                        <th>Skill</th>
                                        {evaluations && evaluations.map( ( evaluation,i ) =>{
                                            return <th key={i} className="text-center">
                                                {<Tooltip title={evaluation.interviewer}>
                                                    <span>Eval-{i+ 1}</span>
                                                </Tooltip>}
                                            </th>;
                                        } )}
                                        <th className="text-center">Average</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {skills && skills.map( ( sk,i ) =>{
                                        return <tr key={Math.random() * i}>
                                            <td className="text-center">{sk.skill}</td>
                                            {evaluations.map( ( eva,index ) =>{
                                                let dynamicIndex ="rate" + index;
                                                return <td key={dynamicIndex} className="text-center">{sk[dynamicIndex]}</td>;
                                            } )}
                                            <td className="text-center">{sk.average.toFixed( 2 )}</td>
                                        </tr>;
                                    } )}
                                    <tr>
                                        <td colSpan={1 + evaluations.length}/>
                                        <td className="text-center">{totalAverage( skills )}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Fragment>
                    }
                    <Evaluations talent_id={talent_id} evaluations={evaluations}/>
                    {/* <hr/>
                    <div className="container d-flex justify-content-start">
                        <h4>Aptitude Test Score </h4>
                        <div className="col-md-3">
                            <input
                                onChange={(e)=> setTestScore(parseInt(e.target.value))}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-2">
                            <button onClick={() => saveTestScore()} className="btn btn-btn-sm btn-primary">
                                <FontAwesomeIcon className="mr-2" icon={faSave} />
                                Save
                            </button>
                        </div>
                    </div> */}
                </div> </div>:null}
        </div>
    );
}

function mapDispatchToProps( dispatch ){
    return {
        setCandidateField: ( data ) => {dispatch( setCandidateField( { field:"notes", value: data } ) );},
    };
}

export default  connect( null, mapDispatchToProps )( EvaluationsContainer );
