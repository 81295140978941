import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { PositionsStore } from "../stores/PositionsStore";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import util from "../../utils/miniUtils";
import ImageComponent from "../../utils/ImageComponent";
import { bake_cookie } from "sfcookies";
import { toast } from "react-toastify";
import 'react-phone-input-2/lib/material.css';
import { Field, Form, Formik, useField } from "formik";
import validations from "../../utils/validations";
import jwt_decode from "jwt-decode";
import ReactRecaptcha from "react-recaptcha";
import PhoneInput from "react-phone-input-2";
import { IconButton, InputAdornment, Tooltip, withStyles } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
const CustomTooltip = withStyles( ( theme ) => ( {
    tooltip: {
        backgroundColor: '#f5f5f9', // Set your desired background color
        color: '#333', // Text color
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
    arrow: {
        color: '#f5f5f9', // Arrow color matching the tooltip background
    },
} ) )( Tooltip );
export default function Register() {

    const show = PositionsStore.useState( state => state.createAccount );
    const [showPassword, setShowPassword] = useState( false );
    const handleClickShowPassword = () => setShowPassword( !showPassword );
    const handleMouseDownPassword = () => setShowPassword( !showPassword );
    const [showConfirmPassword, setShowConfirmPassword] = useState( false );
    const handleClickShowConfirmPassword = () => setShowConfirmPassword( !showConfirmPassword );
    const handleMouseDownConfirmPassword = () => setShowConfirmPassword( !showConfirmPassword );
    const [recaptchaEnabled, setRecaptchaEnabled] = useState( false );
    const [isVerified, setIsVerified] = useState( false );

    useEffect( () => {
        setTimeout( () => {
            setRecaptchaEnabled( true );
        }, 1000 );
    }, [] );

    const handleClose = () => {
        PositionsStore.update( s => { s.createAccount = false; } );
    };

    const createAccount = async ( values ) => {
        try {
            // if (!isVerified) return util.handleError("Validate captcha")

            PositionsStore.update( s => { s.loading = true; } );
            const request = await axios.post( `${util.url}/users/new-talent-account`, { newTalent: values } );
            const { error, msg, accessToken } = request.data;
            if ( error ) {
                PositionsStore.update( s => { s.loading = false; } );
                return util.handleError( msg );
            }

            // setState({...state, isDisabled: false });
            bake_cookie( "tokenServer", accessToken );
            toast.success( msg );

            // After account is created, should display a UI  to complete the profile and then the talent can apply to the position
            PositionsStore.update( s => {
                s.isLogged = true;
                s.loginModal = false;
                s.createAccount = false;
                s.profileProcess = true;
                s.user = jwt_decode( accessToken );
            } );

            PositionsStore.update( s => { s.loading = false; } );
        } catch ( e ) {
            PositionsStore.update( s => { s.loading = false; } );
            util.handleError( e );
        }
    };
    const history = useHistory();

    const createEmployerAccount = async ( values ) => {
        try {
            // if (!isVerified) return util.handleError("Validate captcha")

            PositionsStore.update( s => { s.loading = true; } );
            const request = await axios.post( `${util.url}/users/new-employer-account`, { newTalent: values } );
            const { error, msg, userData } = request.data;
            if ( error ) {
                PositionsStore.update( s => { s.loading = false; } );
                return util.handleError( msg );
            }
            PositionsStore.update( s => {s.userData = userData; s.loading = false; } );
            toast.success( msg );
            history.replace( "/verify_email?internal=true" );
            PositionsStore.update( s => { s.loading = false; } );
        } catch ( e ) {
            PositionsStore.update( s => { s.loading = false; } );
            util.handleError( e );
        }
    };

    const goToLogin = () => {
        setRecaptchaEnabled( false );
        PositionsStore.update( s => {
            s.createAccount = false;
            s.loginModal = true;
        } );
    };

    const verifyCallBack = data => {
        if ( data ) {
            setIsVerified( true );
        }
    };

    const initialValues = {
        name: ``,
        email: ``,
        phone: '',
        employer:'0',
        companyName:''
    };

    const logo = "/images/demo_logo.svg";

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="sm"
        >
            <Modal.Body>
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="align-middle text-center">
                                <img
                                    src={logo}
                                    className="mx-auto d-block m-4 logo"
                                    alt="eJRekruit"
                                />
                                {/* <h3>eJRekruit</h3> */}
                            </div>
                            <div className="text-center"><h4>Create Account</h4></div>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validations.validateRegister}
                                onSubmit={async ( values ) => {
                                    values.employer ==="1" ? 
                                        await createEmployerAccount( values ) :    await createAccount( values );
                                }}
                            >
                                {( { values, handleChange, touched, errors } ) => {
                                    const passwordRules = [
                                        { test: values?.password?.length >= 12, label: 'At least 12 characters long' },
                                        { test: /[A-Z]/.test( values.password ), label: 'Contains an uppercase letter' },
                                        { test: /[a-z]/.test( values.password ), label: 'Contains a lowercase letter' },
                                        { test: /\d/.test( values.password ), label: 'Contains a number' },
                                        { test: /[!@#$%^&*]/.test( values.password ), label: 'Contains a special character' }
                                    ];
                                    return (
                                        <Form>
                                            <table className="registerTable w-100">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {/* <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                                <label>
                                                                    <Field
                                                                        type="radio"
                                                                        name="employer"
                                                                        value="0" // Unique value for Job Seeker
                                                                        className="mr-2"
                                                                    />
        Job Seeker
                                                                </label>
                                                                <label>
                                                                    <Field
                                                                        type="radio"
                                                                        name="employer"
                                                                        value="1" // Unique value for I am Hiring
                                                                        className="mr-2"
                                                                    />
        I am Hiring
                                                                </label>
                                                            </div> */}
                                                            {errors.employer && touched.employer && (
                                                                <div className="text-danger">{errors.employer}</div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    {
                                                        values.employer==="1" && (
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        size="small"
                                                                        id="companyName"
                                                                        name="companyName"
                                                                        label="Company Legal Name"
                                                                        value={values.companyName}
                                                                        onChange={handleChange}
                                                                        error={touched.companyName && Boolean( errors.companyName )}
                                                                        helperText={touched.companyName && errors.companyName}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                id="name"
                                                                name="name"
                                                                label="Name"
                                                                value={values.name}
                                                                onChange={phone => handleChange( phone )}
                                                                error={touched.name && Boolean( errors.name )}
                                                                helperText={touched.name && errors.name}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                id="email"
                                                                name="email"
                                                                label={values.employer ==="1" ? "Enter Your Work Email" : "Enter Your Email"}
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                error={touched.email && Boolean( errors.email )}
                                                                helperText={touched.email && errors.email}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <CustomTooltip
                                                                title={
                                                                    <ul>
                                                                        {passwordRules.map( ( rule, index ) => (
                                                                            <li key={index} style={{ color: rule.test ? 'green' : 'red' }}>
                                                                                {rule.label}
                                                                            </li>
                                                                        ) )}
                                                                    </ul>
                                                                }
                                                                placement="bottom-end"
                                                                arrow
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    id="password"
                                                                    name="password"
                                                                    label="Password"
                                                                    type={showPassword ? "text" : "password"}
                                                                    value={values.password}
                                                                    onChange={handleChange}
                                                                    error={touched.password && Boolean( errors.password )}
                                                                    helperText={touched.password && errors.password}
                                                                    InputProps={{ // <-- This is where the toggle button is added.
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    aria-label="toggle password visibility"
                                                                                    onClick={handleClickShowPassword}
                                                                                    onMouseDown={handleMouseDownPassword}
                                                                                >
                                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </CustomTooltip>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                id="confirmPassword"
                                                                name="confirmPassword"
                                                                label="Confirm Password"
                                                                type={showConfirmPassword ? "text" : "password"}
                                                                value={values.confirmPassword}
                                                                onChange={handleChange}
                                                                error={touched.confirmPassword && Boolean( errors.confirmPassword )}
                                                                helperText={touched.confirmPassword && errors.confirmPassword}
                                                                InputProps={{ // <-- This is where the toggle button is added.
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowConfirmPassword}
                                                                                onMouseDown={handleMouseDownConfirmPassword}
                                                                            >
                                                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>

                                                            <CustomPhoneInput
                                                                name="phone"
                                                                label="Phone Number"
                                                                country={'us'}
                                                                error={touched.phone && Boolean( errors.phone )}
                                                                helperText={touched.phone && errors.phone}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="text-center">
                                                {
                                                    recaptchaEnabled ?
                                                        <ReactRecaptcha
                                                            elementID={"ihd88d"}
                                                            sitekey={process.env["REACT_APP_SITE_KEY"]}
                                                            render="explicit"
                                                            onloadCallback={() => console.log( 'captchaLoaded' )}
                                                            verifyCallback={verifyCallBack}
                                                        />
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-md-12 text-center mt-2">
                                                <button type="submit" className="btn btn-sm btn-primary">Create Account</button>
                                            </div>

                                        </Form>
                                    );
                                }}
                            </Formik>
                            <div className="col-md-12 text-center mt-2">
                                <span className="likeLink" onClick={goToLogin}>
                                    Login
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}


const CustomPhoneInput = ( { ...props } ) => {
    const [field, meta, helpers] = useField( props );

    const handleOnChange = ( value ) => {
        helpers.setValue( value );
    };

    const handleOnBlur = () => {
        helpers.setTouched( true );
    };

    return (
        <div>
            <PhoneInput
                {...field}
                {...props}
                specialLabel=""

                containerStyle={{ marginLeft: '1px', border: 'none', marginBottom: '15px', marginTop: '10px' }}
                // inputStyle={{  outline: 'none' }}
                placeholder={"Enter Phone Number"}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            {meta.touched && meta.error ? (
                <div className="xs-fontSize text-danger text-left">{meta.error}</div>
            ) : null}
        </div>
    );
};
