import React from 'react';
import "./../shared/style.css";
import util from '../../utils/miniUtils';

import AssetsCategory from './AssetsCategory';


const groupSettings = {
    createPath: `${util.url}/amcategory`,
    getListPath: `${util.url}/amcategory`,
    getDetailPath: `${util.url}/amcategory`,
    deletePath: `${util.url}/amcategory`,
    updatePath: `${util.url}/amcategory`,
    getEmployeeList: `${util.url}/employee/get_employees_list`,
    getGroupListPath: `${util.url}/groups/simple-list`,
    getAssignedStatusPath: `${util.url}/amstatuscategory`,
    deleteTeamMemberPath: `${util.url}/wl_categories/delete_member`,
    getAssignedTeamMemberPath: `${util.url}/wl_categories/get_assigned_members`,
    title: "Departments",
    subTitle: "Department Members"
};

// // Get Assigned Status to Category
// router.get( "/wl_categories/get_assigned_status/:category_id" ,isAuthenticated, async ( req, res ) => {
// 	await new wlCategory( req, res ).getAssignedStatusToCategory( req.params.category_id );
// } );

// // Delete Member from a  Category
// router.post( "/wl_categories/delete_member/:relation_id" , async ( req, res ) => {
// 	await new wlCategory( req, res ).deleteMember( req.params.relation_id );
// } );

// // Get Categories from  a particular Category
// router.get( "/wl_categories/get_categories/:category_id/" , async ( req, res ) => {
// 	await new wlCategory( req, res ).getCategoriesFromGroup( req.params.category_id );
// } );



export default function WorkloadCatalog() {
    return (
        <div className="container-fluid">
            <div className="row">
                <AssetsCategory settings={groupSettings} />
            </div>
        </div>
    );
}
