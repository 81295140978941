import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const AssetCategoryItem = ( { data, onDelete, onEdit, onSelect, isActive } ) => {
    const { groupName, name, id, active } = data;
    return (
        <div
            className={`d-flex justify-content-between align-items-center p-2 border rounded mt-1 mb-1 department_item isCursor ${
                isActive === data.id_asset_category ? "activeBlock" : ""
            }`} onClick={( e ) => onSelect( e, data.id_asset_category,name )}
        >
            <div className="w-100">
                <div className="d-flex align-items-center justify-content-between">
                    <b className="text-dark">{name}</b>
                  
                </div>
                <div>
                    <div className="leadName">
                        <span>{groupName}</span>
                    </div>
                </div>
                
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <span className="text-secondary mr-1" onClick={( e ) => onEdit( e, data.id_asset_category, data )}>
                    <FontAwesomeIcon icon={faEdit} title="Edit" />
                </span>
                <span className="text-secondary" onClick={( e ) => onDelete( e, data.id_asset_category )}>
                    <FontAwesomeIcon icon={faTrash} title="Delete" />

                </span>
            </div>
        </div>
    );
};

export default AssetCategoryItem;
