import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

function PermissionModal( { show, handleClose, handleSave, permission } ) {
    // State for the permission data in the modal

    const [permissionData, setPermissionData] = useState( {
        name: '',
        description: '',
        module: '',
        action: ''
    } );
    // Effect to load permission data when the modal is opened for editing
    useEffect( () => {
        if ( permission ) {
            setPermissionData( permission );
        } else {
            setPermissionData( { name: '', description: '', module: '', action: '' } );
        }
    }, [permission] );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setPermissionData( prevData => ( { ...prevData, [name]: value } ) );
    };

    const saveChanges = () => {
        handleSave( permissionData );
        setPermissionData( { name: '', description: '', module: '', action: '' } );
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{permission ? 'Edit Permission' : 'Create Permission'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>Name</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="name"
                                value={permissionData.name}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>Description</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="description"
                                value={permissionData.description}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>Module</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="select"
                                value={permissionData.module}
                                name="module"
                                onChange={( e ) => setPermissionData( {
                                    ...permissionData,
                                    module: e.target.value
                                } )}
                            >
                                {/* Populate your dropdown options here */}
                                <option value={null}>--</option>
                                <option value="Dashboard">Dashboard</option>
                                <option value="Jobs">Jobs</option>
                                <option value="Talent">Talent</option>
                                <option value="Asset">Asset</option>
                                <option value="Workload">Workload</option>
                                <option value="Appraisal">Appraisal</option>
                                <option value="Employee">Employee</option>
                                <option value="Settings">Settings</option>
                                <option value="EliteConnect">EliteConnect</option>
                                <option value="Employer">Employer</option>

                                {/* ... other module options ... */}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={4}>Action</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="select"
                                value={permissionData.action}
                                onChange={( e ) => setPermissionData( {
                                    ...permissionData,
                                    action: e.target.value
                                } )}
                            >
                                {/* Populate your dropdown options here */}
                                <option value="View">View</option>
                                <option value="Create">Create</option>
                                <option value="Edit">Edit</option>
                                <option value="Delete">Delete</option>
                                {/* ... other action options ... */}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={saveChanges}>Save Changes</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PermissionModal;
