import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Chip, Hidden, MuiThemeProvider } from "@material-ui/core";
import { Modal, Button, Form } from "react-bootstrap";
import util from "../utils/miniUtils";
import InvitationModal from "./InvitationModal";
import SAlert from "../microComponents/AlertSA";

import { useLocation } from 'react-router-dom';

const Members = () => {
    // console.log( util.user );
    const [members, setMembers] = useState( [] );
    const [showModal, setShowModal] = useState( false );
    const [email, setEmail] = useState( "" );
    const [error, setError] = useState( "" );
    const [showDeleteModal, setShowDeleteModal] = useState( false );
    const [memberToDelete, setMemberToDelete] = useState( null );
    const location = useLocation();
    const eliteData = location.state?.eliteData;
    const [eliteDomain, setEliteDomain] = useState( '' );
    const [isSaving, setIsSaving] = useState( false );
    // Fetch members data
    const fetchMembers = async () => {
        try {
            const elite_connect_id = util.user.elite_connect_id || eliteData?.elite_connect_id;
            
            const response = await util.axios.get( `/eliteconnect/getmembers/${elite_connect_id}` );
            const { error, data } = response.data;

            if ( error ) {
                throw new Error( "Failed to fetch elite connect members data" );
            }
            if( data[0].is_entity ){
                setEliteDomain( data[0].elite_email_domain );
            }

            setMembers( data );
        } catch ( err ) {
            setError( "An error occurred while fetching members." );
            console.error( err );
            util.handleError( error );

        }
    };

   
    
    useEffect( () => {
        fetchMembers();
        // fetchSettings(); // Call fetchSettings to get account settings
    }, [] );

    const handleModalClose = () => {
        setShowModal( false );
        setEmail( "" );
        setError( "" );
    };

    const handleAddMembers = async () => {
        // const emailArray = email.split( "," ).map( ( email ) => email.trim() );
        
        if ( !/\S+@\S+\.\S+/.test( email ) ) {
            setError( `Invalid email format: ${email}` );
            return;
        }
        if( eliteDomain && email.split( "@" )[1] !==eliteDomain ){
            setError( `Email domain must be '${eliteDomain}'` );
            return;
        }
        
        if ( members.some( ( member ) => member.invitation_email.toLowerCase() === email.toLowerCase() ) ) {
            setError( `This email is already in use: ${email}` );
            return;
        }
        

        try {
            setIsSaving( true );
            const elite_connect_id = util.user.elite_connect_id || eliteData?.elite_connect_id;
            
            
            const response = await util.axios.post( '/eliteconnect/invitation', {
                elite_connect_id: elite_connect_id,
                user_email: email,
            } );

            const { error, msg } = response.data;

            if ( error ) throw  msg ;

            util.toast().success( msg );
            fetchMembers();
            handleModalClose();
        } catch ( error ) {
            setError( "An error occurred while adding members." );
            console.error( error );
            util.handleError( error );
        } finally{
            setIsSaving( false );
        }
    };

    const handleDeleteClick = ( member ) => {
        
        // if ( util.user.role !== 18 ) {
        setMemberToDelete( member );
        setShowDeleteModal( true );
        // }
    };
    

    const handleDeleteMember = async () => {
        try {
           
            
            const response = await util.axios.post( `/eliteconnect/deletemember/`, { email: memberToDelete.invitation_email } );
            const { error, msg } = response.data;

            if ( error ) throw msg;

            fetchMembers();
            setShowDeleteModal( false );
            setMemberToDelete( null );

            util.toast().success( msg );
        } catch ( error ) {
            setError( "An error occurred while deleting the member." );
            console.error( error );
            util.handleError( error );
        }
    };


    const columns = [
        {
            title: "Name",
            field: "account_name",
            
        },
        {
            title: "Email",
            field: "invitation_email",
        },
        {
            title: "Status",
            field: "status",
            render: ( rowData ) => (
                <Chip
                    size="small"
                    label={rowData.status === 0 ? "Invited" : "Accepted"}
                    className={`text-white ${rowData.status === 0 ? "bg-primary" : "bg-success"}`}
                    style={{ minWidth: "80px", textAlign: "center" }}
                />
            ),
        },
        
        // {
        //     title: "Actions",
        //     sorting: false,
            
        //     render: ( rowData ) =>
        //         <div style={{ display: "flex", gap: "10px" }}>
        //             <FontAwesomeIcon
        //                 icon={faTrash}
        //                 style={{ cursor: "pointer" }}
        //                 onClick={() => handleDeleteClick( rowData )}
        //             />
        //         </div>
        // },
    ];
    if ( util.user.role !== 20 ) {
        columns.push( {
            title: "Actions",
            sorting: false,
            render: ( rowData ) =>
                util.user.email !== rowData.invitation_email ? (
                    <div style={{ display: "flex", gap: "10px" }}>
                        <FontAwesomeIcon
                            icon={faTrash}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteClick( rowData )}
                        />
                    </div>
                ) : null,
        } );
    }
    const tableTitle = `Elite Connect's Members List `;


    return (
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={columns}
                    title={tableTitle} // Use the updated title
                    data={members}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        sorting: true,
                        search: true,
                        // exportButton: true,
                        selection: true,
                        headerStyle: { backgroundColor: "#f5f5f5", fontWeight: "bold" },
                        maxBodyHeight: "60vh",
                    }}
                    actions={
                        [
                            util.user.role !==20 &&{
                                icon: () => (
                                    <div className="btn btn-secondary btn-sm activeBtn sizeBtn">
                                        <FontAwesomeIcon icon={faPlusCircle} /> Invite Member
                                    </div>
                                ),
                                tooltip: "Invite Members",
                                isFreeAction: true,
                                onClick: () => setShowModal( true ),
                            },
                        ]
                            
                    }
                />
            </MuiThemeProvider>

            <InvitationModal
                showModal={showModal}
                handleAddMembers={handleAddMembers}
                handleModalClose={handleModalClose}
                email={email}
                setEmail={setEmail}
                error={error}
                isSaving={isSaving}
            />

            {showDeleteModal && (
                <SAlert
                    show={showDeleteModal}
                    msg={`Are you sure you want to delete this member: ${memberToDelete.invitation_email}?`}
                    hideAlert={() => setShowDeleteModal( false )}
                    onConfirm={handleDeleteMember}
                />
            )}

            
            
        </>
    );
};

export default Members;
