import { faCheck, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import util from "../../utils/miniUtils";
import AssetTypeMakes from "./AssetTypeMakes";
import { Modal } from "react-bootstrap";

export default function AssetTypeCategory( {
    typeList,
    deleteTypeCategory,
    updateCategoryStatusOrder,
    getAssignedType,
    id,
    setState,
    isActive,
    state
} ) {
    const [itemList, setItemList] = useState( [] );
    const [makeList, setMakeList] = useState( [] );
    const [selectedType, setSelectedType] = useState( null );
    const [allmakeList, setAllMakeList] = useState( [] );
    const [formData, setFormData] = useState( {} );
    useEffect( () => {
        if ( typeList?.length ) {
            setItemList( typeList );
            getAllMake();
        }
    }, [typeList] );

    const getAllMake = async () => {
        try {
            const request = await util.axios.get( "/glmake" );
            const { msg, error, data } = request.data;
            if( error ) throw msg;
            setAllMakeList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    // Fetch Makes when a Type is selected
    const getAssignedMakes = async ( id, name ) => {
        try {
            const request = await util.axios.get( `/amtypemakes/${id}` );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;

            setMakeList( data );
            setSelectedType( { id, name } );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        if( value === "notOnList" ){
            setState( { ...state, addMake: true } );
        } else {
            setFormData( {
                [name]: value
            } );
        }
    };
    const saveTypeMake = async () => {
        let newType = {
            type_id: state.typeId ,
            make_id: formData.status,
               
        };
        try {
            const request = await util.axios.post( "/amtypemakes", { newType } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getAssignedMakes( state.typeId );
             
            util.toast().success( msg );
           
        } catch ( error ) {
            util.handleError( error );
        }
    };

    

    // Handle Type Selection
    const onSelect = ( e, id, name ) => {
        e.stopPropagation();
        getAssignedMakes( id, name );
        setState( ( prev ) => ( {
            ...prev,
            typeId: id
        } ) );
    };

    // Drag & Drop Sorting for Types
    const handleDrop = async ( result ) => {
        const { source, destination } = result;
        if ( !destination || source.index === destination.index ) return;

        const updatedList = [...itemList];
        const [reorderedItem] = updatedList.splice( source.index, 1 );
        updatedList.splice( destination.index, 0, reorderedItem );
        setItemList( updatedList );

        try {
            await Promise.all(
                updatedList.map( ( element, index ) =>
                    updateCategoryStatusOrder( element.type_id, index + 1 )
                )
            );
            await getAssignedType( id );
            util.toast().success( "Type Order Updated Successfully" );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Delete Type
    const deleteItem = ( e, id ) => {
        e.stopPropagation();
        deleteTypeCategory( id );
        
    };

    return (
        <div className="row">
            {/* Type List (First Column) */}
            <div className="col-md-4 bg-white">
                <div className="w-100 border rounded p-2">
                    <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                        <b>Asset Type</b>
                        <FontAwesomeIcon
                            className="isCursor text-primary"
                            onClick={() => setState( { ...state, showAddType: true, actionType: 1 } )}
                            icon={faPlus}
                        />
                    </div>
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="typeList">
                            {( provided ) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {itemList.map( ( item, index ) => (
                                        <Draggable key={item.type_id} draggableId={`type_${item.type_id}`} index={index}>
                                            {( provided ) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`d-flex justify-content-between align-items-center p-2 border rounded mt-1 mb-1 isCursor ${
                                                        isActive === item.type_id ? "activeBlock" : ""
                                                    }`}
                                                    onClick={( e ) => onSelect( e, item.type_id, item.name )}
                                                >
                                                    <div>{item.name}</div>
                                                    <button className="btn btn-sm btn-outline-danger" onClick={( e ) => deleteItem( e, item.type_id )}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </div>
                                            )}
                                        </Draggable>
                                    ) )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>

            {/* Makes List (Second Column) */}
            {state.typeId && (  <div className="col-md-8 bg-white">
              
                <AssetTypeMakes getAssignedMakes={getAssignedMakes} makeList={makeList} selectedType={selectedType} setState={setState} state={state} />
            </div> )}
            {state.showAddMake && (
                <AddMakesModal
                    show={state.showAddMake}
                    handleClose={() => {
                        setState( { ...state, showAddMake: false, addMake: false } );
                        setFormData( {} );
                    }}
                    data={allmakeList}
                    handleChange={handleChange}
                    inputMake={state.addMake}
                    cancelInput={() => setState( { ...state, addMake: false } )}
                    getMakeList={getAllMake}
                    saveMake={saveTypeMake}
                />
            )}
        </div>
    );
}

const AddMakesModal = ( { data, show, handleChange, handleClose, saveMake, inputMake, getMakeList, cancelInput } ) => {
    const [make, setMake] = useState( "" );
    function handleStatusInput ( e ) {
        const { value } = e.target;
        setMake( value );
    }

    async function submitMakes() {
        let newMake = { make_name: make };
        try {
            const request = await util.axios.post( '/glmake', { newMake } );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            getMakeList();
            cancelInput();
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <div className="d-flex justify-content-center">
                    <b>Add Makes</b>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="form-group">
                        <label htmlFor="statusSelector">Select Make</label>
                        <select className="form-control" id="statusSelector" name="status" onChange={handleChange}>
                            <option value=""></option>
                            {data.map( item => <option key={item.mk_id} value={item.mk_id}>{item.make_name}</option> )}
                            <option className="bg-warning" value={"notOnList"}>Not on the List</option>
                        </select>
                    </div>
                    {inputMake && <div>
                        <div className="form-group">
                            <label htmlFor="statusSelector">Make Name</label>
                            <input className="form-control" name="make" value={make} onChange={handleStatusInput} />
                        </div>
                        <div className="d-flex justify-content-end">
                            <span className="btn btn-success sizeBtn" onClick={submitMakes}>Save</span>
                        </div>
                    </div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn btn-outline-danger" onClick={handleClose}>
                    <FontAwesomeIcon
                        icon={faTimes}
                    />
                </span>
                <span className="btn btn-outline-primary" onClick={saveMake}>
                    <FontAwesomeIcon
                        icon={faCheck}
                    />
                </span>
            </Modal.Footer>
        </Modal>
    );
};
