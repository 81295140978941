import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import util from "../../utils/miniUtils";


const AssetsCategoryCreationModule = ( { show, handleClose, actionType = 1, data = {}, settings, reloadData, id } ) => {
    const { createPath, getDetailPath, updatePath, getGroupListPath } = settings;
    const [groupList, setGroupList] = useState( [] );
    const [item, setItemInformation] = useState( data );

    const handleChange = ( e ) => {
        if( e.target.name === "risk_register_applicable" || e.target.name === "rental_or_lease_applicable" ) {
            setItemInformation( { ...item, [e.target.name]: e.target.checked ? 1 : 0 } );
        } else {
            setItemInformation( { ...item, [e.target.name]: e.target.value } );
        }
    };

    // const getItemDetail = async () => {
    //     try{
    //         const request = await util.axios.get( getDetailPath + "/" + id );
    //         const { msg, error, data } = request.data;
    //         if( error ) throw( msg );
    //         setItemInformation( data );
    //     } catch( error ){
    //         util.handleError( error );
    //     }
    // };

    // const getGroupList = async () => {
    //     try{
    //         const request = await util.axios.get( getGroupListPath );
    //         const { msg, error, data } = request.data;
    //         if( error ) throw( msg );
    //         setGroupList( data );
    //     } catch( error ){
    //         util.handleError( error );
    //     }
    // };

    const saveInformation = async () => {
        try{
            const request = await util.axios.post( createPath, item );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            reloadData();
            util.toast().success( msg );
        } catch( error ){
            util.handleError( error );
        }
        handleClose();
    };

    const updateInformation = async () => {
        let updateObj = {
            name: item.name,
            category_description: item.category_description,
            risk_register_applicable: item.risk_register_applicable,
            rental_or_lease_applicable: item.rental_or_lease_applicable
        };
        try{
            const request = await util.axios.put( updatePath + "/" + id, updateObj );
            const { msg, error, data } = request.data;
            if( error ) throw( msg );
            reloadData();
            util.toast().success( msg );
        } catch( error ){
            util.handleError( error );
        }
        handleClose();
    };

    const triggerSaveUpdate = () => {
        if ( actionType === 1 ) {
            return saveInformation();
        }
        return updateInformation();
    };

    // useEffect( () => {
    //     getGroupList();
    //     // if ( actionType === 2 ) {
    //     //     getItemDetail( id );
    //     // }
    // }, [] );
    
    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{actionType === 1 ? "Create new " : "Update "} Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mt-2">
                    <label className="form-label">Title</label>
                    <input className="form-control" name="name" value={item.name} onChange={handleChange} />
                </div>
                {/* <div className="mt-2">
                    <label className="form-label">Prefix</label>
                    <input className="form-control" name="prefix" value={item.prefix} onChange={handleChange} />
                </div> */}
                <div className="mt-1">
                    <label className="form-label">Description</label>
                    <input className="form-control" name="category_description" onChange={handleChange} value={item.category_description} />
                </div>
                <div className=" mt-2 form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="activeCheckbox" name="risk_register_applicable"
                        checked={item.risk_register_applicable} onChange={handleChange} />
                    <label className="form-label" htmlFor="activeCheckbox">Risk Applicable</label>
                    <input className="form-check-input" type="checkbox" id="activeCheckbox" name="rental_or_lease_applicable"
                        checked={item.rental_or_lease_applicable} onChange={handleChange} />
                    <label className="form-label" htmlFor="activeCheckbox">Rent Appicable</label>
                </div>
                
              
             
                <div className="d-flex justify-content-end m-2">
                    <button className="btn btn-sm btn-primary" onClick={triggerSaveUpdate}>
						Save
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AssetsCategoryCreationModule;
