import React, { useEffect, useState } from 'react';
// import useJobSearch from '../useJobSearch';
import { Snackbar } from '@material-ui/core';
import PositionTitleSecondary from "./PositionTitleSecondary";
import { Facebook, Twitter, LinkedIn, LinkRounded } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import {
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
} from "react-share";
import { faArrowLeft, faCopy } from '@fortawesome/free-solid-svg-icons';
import util from '../../../utils/miniUtils';
import { PositionsStore } from '../../stores/PositionsStore';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const JobDetail = () => {
    
    // const { loading, setLoading } = useJobSearch();
    const state = PositionsStore.useState( s => s );
    const job = PositionsStore.useState( s => s.job );
    const [open, setOpen] = useState( false );
    const isSmallScreen = window.innerWidth < 768;
    const location = useLocation();
    const queryParams = new URLSearchParams( location.search );
    const jobValue = queryParams.get( 'job' );
    
    const handleBackButton = () => {
        const urlParams = new URLSearchParams( location.search );
        PositionsStore.update( s => {s.job = null;} );
        urlParams.delete( 'job' );
        window.history.replaceState( null, '', `?${urlParams.toString()}` );
    };

    // Close snackbar
    const handleClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setOpen( false );
    };

    // Copy link to clipboard
    const copyToClipboard = () => {
        const url = window.document.location.origin + `/careers?swe=${util.uuidv4()}&job=${job.id}&fs=true`;
        navigator.clipboard.writeText( url ).then( null );
        setOpen( true );
    };

    const getJobDetails = async ( id ) => {
        if( id ) {
            try {
                PositionsStore.update( s => {s.loading = true;} );
                if ( !id ){
                    return;
                }
                const request = await util.axios.get( `${util.url}/job/position_information_public/${id}` );
                const { error, msg, position } = request["data"];
                if ( error ) throw msg;
                PositionsStore.update( s => {
                    s.job = position;
                } );
            } catch ( e ) {
                util.handleError( e );
            }
            PositionsStore.update( s => {s.loading = false;} );
        }
    };

    useEffect( () => {
        getJobDetails( jobValue );
    },[] );


    if ( state.loading ) { 
        return util.LOADING_SCREEN( true );
    } else if( !state.job ) {
        return <div className="backGroundIMage_JP"></div>;
    }

    return (
        <>
            {/* {window.location.pathname.split( "/" )[2] === 'job' ? <NavBar /> : null} */}
            {isSmallScreen?
                <div className="w-100">
                    <Tooltip title="Previous">
                        <span>
                            <button className="isPointer btn btn-outline-primary btn-sm rounded-circle mt-1" onClick={handleBackButton}><FontAwesomeIcon icon={faArrowLeft} /></button></span>
                    </Tooltip> 
        
                </div>: ""}
            <div className="bg-white homePage-jobDetail"  id="topSection">{ job ?
                <div className="row m-0">
                    <div className="col-md-12">
                        <div className="row m-0">
                            <div className="col-md-12">
                                <h3 className="pt-2"><p className="detailTitle">{job.job_title}</p></h3>
                            </div>

                            <PositionTitleSecondary job={job} />
                        </div> <div className="p-1 ml-3 w-100 d-flex justify-content-start align-self-center">
                            <Tooltip title="Share it in Facebook">
                                <span className="mr-3">
                                    <FacebookShareButton url={window.location.href} >
                                        <Facebook size={32} style={{ color: 'dodgerblue' }} />
                                    </FacebookShareButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Share it in Twitter">
                                <span className="mr-3">
                                    <TwitterShareButton url={window.location.href} >
                                        <Twitter size={32} style={{ color: 'dodgerblue' }} />
                                    </TwitterShareButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Share it in Linkeding">
                                <span className="mr-3">
                                    <LinkedinShareButton url={window.location.href} >
                                        <LinkedIn size={32} style={{ color: 'dodgerblue' }} />
                                    </LinkedinShareButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Copy Job Link">
                                <span className="likeLink" onClick={copyToClipboard}>
                                    <LinkRounded size={32} icon={faCopy} />
                                </span>
                            </Tooltip>
                        </div>
                        <hr></hr>
                        <div className="row m-0">
                            <div
                                className="col-md-12 jobDescription"
                                dangerouslySetInnerHTML={{ __html: job.job_description }}
                            />
                        </div>
                    </div>
                </div>
                :
                null
            } </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Job link copied"
            />
         
        </>
    );
};

export default JobDetail;