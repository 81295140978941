import { Chip, MuiThemeProvider } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import util from "../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import AddPaymentItem from "./AddPaymentItem";
import Swal from 'sweetalert2';

const PaymentItems = ( ) => {
    const [paymentItems, setPaymentItems] = React.useState( [] );
    const [showModal, setShowModal] = useState( false );
    const [loading, setLoading] = useState( false );
    const [ paymentDetail, setPaymentDetail] = useState( {
        id: "",
        name: "",
        description: ""
    } );
    const [ isEditing, setIsEditing] = useState( false );
    

    const fetchPaymentItems = async () => {
        try {
            
            const response = await util.axios.get( `/elitepaymentitem/` );
            const { error, data } = response.data;

            if ( error ) {
                throw new Error( "Failed to fetch payment items data" );
            }
            console.log( data );
            setPaymentItems( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    useEffect( () => {
        fetchPaymentItems();
    }, [] );
    const handleAddPayment = async() => {
        try{
            if( paymentDetail.name === "" || paymentDetail.description === "" ){
                throw "Please fill all fields";
            } 
            
            const request = await util.axios.post( `${util.url}/elitepaymentitem/`, paymentDetail );
            const { error, message } = request.data;
            if ( error ) throw message;
            util.toast().success( message );
            setLoading( false );
            fetchPaymentItems();
            setPaymentDetail( {
                
                name: "",
                description: ""
            } );
            setShowModal( false );
            
        }catch( error ){
            util.handleError( error );
        }
    };
    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setPaymentDetail( {
            ...paymentDetail, [name]: value
        } );
    };
    const handleModalClose = () => {
        setShowModal( false );
        setPaymentDetail( {
            name: "",
            description: ""
        } );
        setIsEditing( false );
    };

    const deletePaymentItem = async ( id ) => {
        try {
            // setLoading( true );
            const request = await util.axios.delete( `${util.url}/elitepaymentitem/${id}` );
            
            return request.data;
            
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const handleUpdatePayment = async (  ) => {
        try {
            setLoading( true );
            const request = await util.axios.put( `${util.url}/elitepaymentitem/${paymentDetail.id}`, paymentDetail );
            const { error, message } = request.data;
            if ( error ) throw message;
            util.toast().success( message );
            setShowModal( false );
            setIsEditing( false );
            setPaymentDetail( {
                id: "",
                name: "",
                description: ""
            } );
            setLoading( false );
            fetchPaymentItems();
        } catch ( error ) {
            util.handleError( error );
        }
    };
    
    const handleDelete = async ( id ) => {
        try {
            const result = await Swal.fire( {
                title: "Confirm Deletion",
                text: "Are you sure you want to delete this payment item?",
                // icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Delete",
            } );
            
            if ( result.value ) {
                const response = await deletePaymentItem( id );
                
                if ( response.error ) {
                    
                    await Swal.fire( "Error", response.message , "error" );
                }else {
                    await Swal.fire( "Deleted", "The payment item has been deleted.", "success" );
                    fetchPaymentItems();
                }
    
                
            }
        } catch ( error ) {
            await Swal.fire( "Error", "An error occurred while deleting the payment item.", "error" );
        }
    };

    const handleRowClick = ( e, rowData ) => {
        setIsEditing( true );
        setShowModal( true );
        setPaymentDetail( rowData );
    };

    const columns = [
        {
            title: "ID",
            field: "id",
            
        },
        {
            title: "Name",
            field: "name",
        },
        {
            title: "Description",
            field: "description",
            
        },
        {
            title: "",
            field: "action",
            render: ( rowData ) => (
                <div className="d-flex justify-content-space-between">
                    <div className="btn btn-link text-danger btn-sm" onClick={( e ) =>{
                        e.stopPropagation();
                        handleDelete( rowData.id );}}>
                        <FontAwesomeIcon icon={faTrash} /> 
                    </div>
                    
                    
                </div>
            )
        

        }
    ];
    const tableTitle = `Payment Items`;
    if ( loading ) return util.LOADING_SCREEN( loading );
    
    return (
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={columns}
                    title={tableTitle} // Use the updated title
                    data={paymentItems}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        sorting: true,
                        search: true,
                        // exportButton: true,
                        selection: true,
                        headerStyle: { backgroundColor: "#f5f5f5", fontWeight: "bold" },
                        maxBodyHeight: "60vh",
                    }}
                    onRowClick={handleRowClick}
                    actions={
                        [
                            util.user.role ===6 &&  {
                                icon: () => (
                                    <div className="btn btn-secondary btn-sm activeBtn ">
                                        <FontAwesomeIcon icon={faPlusCircle} /> Payment Item
                                    </div>
                                ),
                                tooltip: "Add Payment",
                                isFreeAction: true,
                                onClick: () => setShowModal( true ),
                            },
                        ]
                                                                    
                    }
                    
                />
            </MuiThemeProvider>
            <AddPaymentItem 
                showModal={showModal}
                setShowModal={setShowModal}
                paymentDetail={paymentDetail}
                handleChange={handleChange}
                handleAddPayment={handleAddPayment}
                handleModalClose={handleModalClose}
                isEditing={isEditing}
                handleUpdatePayment={handleUpdatePayment}
            />
        </>
    );
};

export default PaymentItems;