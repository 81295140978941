import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
// import util from '../../../utils/miniUtils';
import ImageComponent from "../utils/ImageComponent";
// import ParentTicketSearch from '../../../workload/shared/ParentTicketSearch';
import { Editor } from '@tinymce/tinymce-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import useManageWorkload from '../workload/manage_workload/useManageWorkLoad';
import util from '../utils/miniUtils';
import ParentTicketSearch from '../workload/shared/ParentTicketSearch';
import ExtraFields from './ExtraFields';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';


const EXCEL_FILES = '.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const WORD_FILES = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const PPT_FILES = '.ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation';

const TicketModal = ( { show, handleClose, workload_id, saveWorkload, isSaving } ) => {
    const { checkStatus } = useManageWorkload();
    const [loading, setLoading] = useState( false );
    const [workload, setWorkload] = useState( {} );
    const [statusList, setStatusList] = useState( [] );
    const [groupList, setGroupList] = useState( [] );
    const [customerList, setCustomerList] = useState( [] );
    const [categoryList, setCategoryList] = useState( [] );
    const [resolverList, setResolverList] = useState( [] );
    const [workloadList, setWorkloadList] = useState( [] );
    const [isChildTicket, setIsChildTicket] = useState( false );
    const [newWorkHistory, setNewWorkHistory] = useState( '' );
    const [showSLA, setShowSLA] = useState( false );
    const [solution, setSolution] = useState( '' );
    const [originalSolution, setOriginalSolution] = useState( '' );
    const [showSolution, setShowSolution] = useState( true ); // Control visibility of solution editor
    const [workflowList, setWorkflowList] = useState( [] );
    const { addAttachment, getAttachments, existingAttachments, deleteAttachment,attachmentFiles, removeAttachmentfile } = useManageWorkload();

    const handleChange = ( e ) => {
        e.preventDefault();
        const { name, value } = e.target;
        setWorkload( prev => ( {
            ...prev,
            [name]: value
        } ) );
    };
    const handleEngagementTypeChange = ( e ) => {
        const { checked, value } = e.target;
        console.log( checked, value );
        setWorkload( ( prev ) => {
            const currentTypes = prev.ticket_type ? prev.ticket_type.split( "," ) : [];
            const updateTypes = checked ? [...currentTypes, value] : currentTypes.filter( ( item ) => item !== value );
            const updatedTicketType = updateTypes.join( "," );

            // Return the new state
            return {
                ...prev,
                ticket_type: updatedTicketType,
            };
        } );

    };
    function handleDescription( e ) {
        const value = e.toString( "html" );
        setWorkload( ( prev ) => {
            return {
                ...prev,
                long_description: value,
            };
        } );
    }
    const [workflow, setWorkflow] = useState( {
        wl_workflow_id: null,
        approver1: null,
        approver2: null,
            
        approvals_required: null
    } );

    const handleWorkHistoryEditor = ( e ) => {
        const value = e.toString( "html" );
        setNewWorkHistory( value );
    };
    function handleWorkflowChange( e ) {
        const { name, value } = e.target;
        setWorkflow( ( prev ) => ( { ...prev, [name]: Number( value ) } ) );
    }
    useEffect( () => {
        if( workflowList.length > 0 ) {
            setWorkflow( {
                name: workflowList[0].name,
                wl_workflow_id: workflowList[0].id,
                approver1: workflowList[0].approver1,
                approver2: workflowList[0].approver2,
                // approver3: workflowList[0].approver3,
                approvals_required: workflowList[0].approvals_required
            } );
            setWorkload( prev => (
                { ...prev, isApproval : true } )
            );
        } else {
            setWorkload( prev => (
                { ...prev, isApproval : false } )
            );
        }
    },[workflowList] );
    const handleStatusChange = ( e ) => {
        const value = e.target.value;
        setWorkload( ( prev ) => {
            return {
                ...prev,
                status: Number( value.split( " " )[0] ),
                status_from_categories: Number( value.split( " " )[1] )
            };
        } );
    };

    const toggleChildTicket = () => {
        if( !isChildTicket ){
            setWorkload( ( prev ) => {
                return {
                    ...prev,
                    parent_ticket_id: null
                };
            } );
        } 
        setIsChildTicket( !isChildTicket );
    };

    // API Requests
    // Detail of an specific workload
    const getWorkLoadDetail = async ( workload_id = null ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/get_workload_detail/${workload_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setWorkload( data );
            setOriginalSolution( data.solution || '' );
            setSolution( data.solution || '' );

        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    const getInitialRequest = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/get_create_initial_request` );
            const { error, msg, grpList } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setGroupList( grpList );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    const getWorkloadList = async ( ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/WorloadList` );
            const { error, msg, workLoadList } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setWorkloadList( workLoadList );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };
    const getWorkflowsToCategory = async ( category_id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/workflow?category=${category_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setWorkflowList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    // const getDepartmentsByUser = async ( id ) => {
    //     try{
    //         setLoading( true );
    //         const request = await util.axios.get( `/department/workload_list/${id}` );
    //         const { error, msg, data } = request.data;
    //         if( error ) throw msg;
    //         setDepartmentList( data );
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }
    //     setLoading( false );
    // };

    const getCustomerList = async ( company_id ) => {
        try {
            const request = await util.axios.get( `/customer_companies_accounts/${company_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setCustomerList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getCategories = async ( group_id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/groups/get_categories/${group_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setCategoryList( data.filter( el => el.active === 1 ) );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    const getAssignedMembersToGroup = async ( group_id ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/groups/get_assigned_members/${group_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setResolverList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    const getStatusListToCategory = async ( category_id ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/get_all_status_category/by_category/${category_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setStatusList( checkStatus( data, workload.status ) );            
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    
    const getDate = ( value ) => {
        let d = new Date( +value );
        return d;
    };

    const getStatusName = ( value ) => {
        const statusName = value && statusList.find( el => {
            if( el.wl_status_id == ( value ) ) {
                return el.name;
            } 
        } );
        return statusName && statusName.name !== undefined ? statusName.name : 'Error Loading';
    };

    const handleWorkHistory = ( obj, inputData, setInputData ) => {
        if( inputData && inputData.length ) {
            let d = Date.now();
            if( obj.work_history ) {
                let prevWorkHistory = obj.work_history;
                obj.work_history = `${d}, ${workload.status}, ${util.user.id}, ${inputData}|,|${prevWorkHistory}`;
            } else {
                obj.work_history = `${d}, ${workload.status}, ${util.user.id}, ${inputData}`;
            }
        }
        setInputData( "" );
    };

    const handleUpdateWorkloadBtn = async ( data ) => {
        const temp = { ...data };

        handleWorkHistory( temp, newWorkHistory, setNewWorkHistory );
        // Remove all the read only fields from update
        delete temp.createdAt;
        delete temp.updatedAt;
        delete temp.RO_ticket_hop_count;
        delete temp.RO_assigned_to_group_time;
        delete temp.RO_assigned_to_member_time;
        delete temp.RO_actual_resolution_sla;
        delete temp.RO_actual_response_sla;
        delete temp.RO_wl_log;
        delete temp.RO_sla_caldays;
        delete temp.RO_sla_bizdays;
        delete temp.RO_sla_minutes;
        delete temp.RO_Open_time;
        delete temp.RO_Closed_Time;
        delete temp.RO_assigned_to_solver_time;
        delete temp.RO_group_sla_caldays;
        delete temp.RO_group_sla_minutes;
        delete temp.RO_group_sla_bizdays;
        delete temp.RO_resolver_sla_caldays;
        delete temp.RO_resolver_sla_minutes;
        delete temp.RO_resolver_sla_bizdays;
        delete temp.RO_AcknowledgedTime;
        
        
        await saveWorkload( temp );
        handleClose();
    };

    useEffect( () => {
        if( workload_id ) {
            getInitialRequest();
            getWorkloadList();
            getWorkLoadDetail( workload_id );
            getAttachments( workload_id );

        }
    },[] );

    useEffect( () => {
        if( workload.parent_ticket_id ) {
            setIsChildTicket( true );
        }
    },[workload] );

    useEffect( () => {
        if( workload.customer_company_id )
            getCustomerList( workload.customer_company_id );
    },[workload.customer_company_id] );

    useEffect( () => {
        if( workload.wl_category_id ) {
            getStatusListToCategory( workload.wl_category_id );
            getWorkflowsToCategory( workload.wl_category_id );
        }
    },[workload.wl_category_id] );

    useEffect( () => {
        if( workload.group_id ) {
            getAssignedMembersToGroup( workload.group_id );
            getCategories( workload.group_id );
            getStatusListToCategory( workload.wl_category_id );
            // getWorkflowsToCategory( workload.wl_category_id );
        }
    },[workload.group_id] );

    const handleSolutionChange = ( e ) => {
        const value = e.toString( "html" );
        setSolution( value );
        setWorkload( prevWorkload => ( {
            ...prevWorkload,
            solution: value
        } ) );
    };

    const saveSolution = async () => {
        // Check if there is a solution to save and if workload.solution is not null
        console.log( workload.solution,solution !== originalSolution );
        if ( workload.solution && solution !== originalSolution ) {
            try {
                // Include the entire workload object in the POST request
                const response = await util.axios.post( '/customer_company_files/train', { ...workload, solution } );
                console.log( response.data ); // Log or handle response as needed
            } catch ( error ) {
                util.handleError( error );
            }
        } else {
            console.log( "No solution to update or workload.solution is null." );
        }
    };
    

    if( !workload.id ) {
        return "";
    }

    return (
        <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>{workload.id ? 'Edit Lead' : 'New Lead'}</Modal.Title>
            </Modal.Header>
            {isSaving &&  <div className='circular-prog-overlay'><CircularProgress /></div>   }
            
            <Modal.Body>
                {util.LOADING_SCREEN( loading )}
                <div className="row m-1 d-flex justify-content-between align-items-center">
                    <div className='w-100 d-flex justify-content-between'>
                        <div className="d-flex align-items-center">
                            <span className="rounded-circle border m-1">
                                <ImageComponent src="/images/avatar.svg" width="50" />
                            </span>
                            <Form>
                                {/* Customer Department */}
                                <Form.Group controlId="formCustomerDepartment">
                                    <div className='d-flex w-100 justify-content-between align-items-center'>
                                        <Form.Label>Customer</Form.Label> 
                                        <div className='ml-2 mb-1 btn btn-sm btn-light'>{!!customerList.length && customerList[0].customer_name}</div>
                                    </div>
                                    {/* <select 
                                        className='form-control'
                                        value={workload.customer_id} 
                                        name="customer_id" onChange={handleChange}
                                        disabled>
                                        <option value=""></option>
                                        {customerList.map( el => <option key={el.name + "" + el.customer_id + "" + el.account_id} value={el.account_id}>{el.name}</option> )}
                                    </select> */}
                                    <input className="form-control" value={workload.customer_name} disabled/>
                                </Form.Group></Form>
                        </div>
                        <div><strong>Lead Hop Count:</strong> {workload.RO_ticket_hop_count}</div>
                    </div>
                </div>
                <Form className='mx-4'>
                    <Row>
                        <Col md={7}>
                            {/* Customer Department */}
                            {/* <Form.Group as={Row} controlId="formCustomerDepartment">
                                <Form.Label column sm={4}>Customer Department</Form.Label>
                                <Col sm={8}>
                                    <select 
                                        className="form-control" 
                                        value={workload.department_id} 
                                        name="department_id"  
                                        onChange={handleChange}
                                        disabled>
                                        <option value=""></option>
                                        {departmentList.map( ( item ) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ) )}
                                    </select>
                                </Col>
                            </Form.Group> */}

                            {/* Assignment Group */}
                            <Form.Group as={Row} controlId="formAssignmentGroup">
                                <Form.Label column sm={4}>Assignment Group</Form.Label>
                                <Col sm={8}>
                                    <select 
                                        className="form-control"
                                        value={workload.group_id} 
                                        name="group_id"  bl
                                        onChange={handleChange}
                                        disabled>
                                        <option value=""></option>
                                        {groupList.map( ( item ) => (
                                            <option key={item.id} value={item.id}>
                                                {item.itemName}
                                            </option>
                                        ) )}
                                    </select>
                                </Col>
                            </Form.Group>

                            {/* Category */}
                            <Form.Group as={Row} controlId="formCategory">
                                <Form.Label column sm={4}>Category</Form.Label>
                                <Col sm={8}>
                                    <select
                                        className="form-control" 
                                        value={workload.wl_category_id} 
                                        name="wl_category_id" 
                                        onChange={handleChange}
                                        disabled>
                                        <option value=""></option>
                                        {categoryList.map( ( item ) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ) )}
                                    </select>
                                </Col>
                            </Form.Group>

                            {/* Resolver */}
                            <Form.Group as={Row} controlId="formResolver">
                                <Form.Label column sm={4}>Solution Architect</Form.Label>
                                <Col sm={8}>
                                    <select
                                        className="form-control" 
                                        value={workload.resolver_id}
                                        name="resolver_id" 
                                        onChange={handleChange}
                                        // disabled={util.user.id !== workload.resolver_id && util.user.id !== workload.customer_id}
                                    >
                                        <option value=""></option>
                                        {resolverList.map( ( item ) => (
                                            <option key={item.account_id} value={item.account_id}>
                                                {item.name}
                                            </option>
                                        ) )}
                                    </select>
                                </Col>
                            </Form.Group>

                            {/* Subject */}
                            <Form.Group as={Row} controlId="formSubject">
                                <Form.Label column sm={4}>Lead Title</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" onChange={handleChange} name="short_description" value={workload.short_description}/>
                                </Col>
                            </Form.Group>

                            {/* Description */}
                            <Form.Group as={Row} controlId="formDescription">
                                <Form.Label column sm={4}>Lead Description</Form.Label>
                                <Col sm={8}>
                                    {/* <Editor
                                        className="editor"
                                        apiKey='co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p'
                                        value={workload.long_description || ""}
                                        init={{
                                            height: 200,
                                            menubar: false,
                                            branding: false,
                                            statusbar: false,
                                            toolbar:
												"formatselect | bold italic| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
                                        }}
                                        onEditorChange={handleDescription}
                                    /> */}
                                    <ReactQuill
                                        value={workload.long_description || ""}
                                        className='editWorkload-editor'
                                        onChange={handleDescription}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                ['bold', 'italic', 'underline'],
                                                [{ 'align': [] }],
                                                ['clean']
                                            ],
                                        }}
                                        formats={[
                                            'header', 'font',
                                            'bold', 'italic', 'underline',
                                            'list', 'bullet',
                                            'align'
                                        ]}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="workHistory">
                                <Form.Label column sm={4}>Work History</Form.Label>
                                <Col sm={8}>
                                    {( util.user.id === workload.customer_id || util.user.employee_id === workload.resolver_id ) && 
                                    // <Editor
                                    //     className="editor"
                                    //     apiKey='co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p'
                                    //     value={newWorkHistory || ""}
                                    //     init={{
                                    //         height: 150,
                                    //         menubar: false,
                                    //         branding: false,
                                    //         statusbar: false,
                                    //         toolbar: false,
                                    //     }}
                                    //     onEditorChange={handleWorkHistoryEditor}
                                    // /> 
                                    <ReactQuill
                                        value={newWorkHistory || ""}
                                        onChange={handleWorkHistoryEditor}
                                        className='editWorkload-workHistoryEditor'
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                ['bold', 'italic', 'underline'],
                                                [{ 'align': [] }],
                                                ['clean']
                                            ],
                                        }}
                                        formats={[
                                            'header', 'font',
                                            'bold', 'italic', 'underline',
                                            'list', 'bullet',
                                            'align'
                                        ]}
                                    />
                                    }
                                    <div className="wl_historyDiv">
                                        <ul className="p-0">
                                            {workload.work_history && workload.work_history.split( "|,|" ).map(
                                                ( item, index ) => (
                                                    <li key={index} className="m-1">
                                                        <ListItem
                                                            timestamp={item && getDate( item.split( ", " )[0] ).toLocaleString( "US" )}
                                                            status={getStatusName( item.split( ", " )[1] )}
                                                            user_id={item && item.split( ", " )[2] }
                                                            content={item}
                                                        />
                                                    </li> )
                                            )}
                                        </ul>
                                    </div>
                                </Col>
                            </Form.Group>
                            {/* Solution */}
                            {showSolution && (
                                <Form.Group as={Row} controlId="formSolution">
                                    <Form.Label column sm={4}>Solution</Form.Label>
                                    <Col sm={8}>
                                        {/* <Editor
                                            apiKey='co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p'
                                            value={solution}
                                            onEditorChange={handleSolutionChange}
                                            init={{
                                                height: 200,
                                                menubar: false,
                                                branding: false,
                                                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                                            }}
                                        /> */}
                                        <ReactQuill
                                            value={solution}
                                            onChange={handleSolutionChange}
                                            className='editWorkload-editor'
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['bold', 'italic', 'underline'],
                                                    [{ 'align': [] }],
                                                    ['clean']
                                                ],
                                            }}
                                            formats={[
                                                'header', 'font',
                                                'bold', 'italic', 'underline',
                                                'list', 'bullet',
                                                'align'
                                            ]}
                                        />
                                        <div>
                                            <Tooltip title="Click to select attachments">
                                                <span>
                                                    <label htmlFor="wl_attachment" className="btn btn-sm btn-primary mt-1">
                                                        <FontAwesomeIcon icon={faPaperclip} />
                                                        <span className="p-1">Attachments</span>
                                                    </label>
                                                </span>
                                            </Tooltip>
                                            <input
                                                type="file"
                                                multiple name="files"
                                                id="wl_attachment"
                                                onChange={( e ) => addAttachment( e )}
                                                accept={`image/*,.pdf,.txt,text/csv,${WORD_FILES},${EXCEL_FILES},${PPT_FILES}`}
                                                className="d-none" />
                                            <div className="d-flex flex-wrap mt-2">
                                                {
                                                                    existingAttachments?.map( ( a, i ) => {
                                                                        const { title, id, workload_id } = a;
                                                                        return (
                                                                            <span key={i} className="mr-1 mb-1 d-flex align-items-center attachmentBtn p-1">
                                                                                <a className="text-secondary" target="new" href={`${util.baseUrl()}${util.AWSURL()}/workload_attachments/${workload_id}/${title}`}>{title.substring( 0, 20 )}</a>
                                                                                <span className="p-1 isPointer text-danger" onClick={() => deleteAttachment( id )}><FontAwesomeIcon icon={faTimes} /></span>
                                                                            </span>
                                                                        );
                                                                    } )
                                                }
                                                {
                                                    attachmentFiles.map( ( a, i ) => {
                                                        return <span key={i} className="mr-1 d-flex align-items-center attachmentBtn">
                                                            <span>{a.name}</span>
                                                            <span className="p-1 isPointer text-danger" onClick={() => removeAttachmentfile( a.name )}><FontAwesomeIcon icon={faTimes} /></span>
                                                        </span>;
                                                    } )
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Group>
                            )}

                            {/* Save Solution Button */}
                            {solution !== originalSolution && (
                                <Button variant="info" onClick={saveSolution}>Save Solution</Button>
                            )}
                        </Col>

                        <Col md={5} style={{ backgroundColor: '#f0f0f0' }}>
                            {workflowList.length > 0 && (
                                <div >
                                    <Form.Group as={Row} controlId="formWorkflow">
                                        <Form.Label column md={4}>Workflow</Form.Label>
                                        <Col md={8}>
                                            <Form.Control plaintext readOnly defaultValue={workflow.name} />
                                        </Col>
                                    </Form.Group>
                        
                                    <Form.Group as={Row} controlId="formApprover1">
                                        <Form.Label column md={4}>Approver 1</Form.Label>
                                        <Col md={8}>
                                            <Form.Control as="select" value={workflow.approver1} name="approver1" disabled onChange={handleWorkflowChange}>
                                                <option value={null}> -- </option>
                                                {groupList.map( item => (
                                                    <option key={item.id} value={item.id}>{item.itemName}</option>
                                                ) )}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                        
                                    <Form.Group as={Row} controlId="formApprover2">
                                        <Form.Label column md={4}>Approver 2</Form.Label>
                                        <Col md={8}>
                                            <Form.Control as="select" value={workflow.approver2} name="approver2" disabled onChange={handleWorkflowChange}>
                                                <option value={null}> -- </option>
                                                {groupList.map( item => (
                                                    <option key={item.id} value={item.id}>{item.itemName}</option>
                                                ) )}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                </div>
                            )}
                            {/* Priority */}
                            <Form.Group as={Row} controlId="formPriority" className='mt-2'>
                                <Form.Label column sm={4}>Priority</Form.Label>
                                <Col sm={8}>
                                    <Form.Control as="select" onChange={handleChange} value={workload.priority} name="priority">
                                        <option>Critical</option>
                                        <option>High</option>
                                        <option>Medium</option>
                                        <option>Low</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>

                            {/* Status */}
                            <Form.Group as={Row} controlId="formStatus">
                                <Form.Label column sm={4}>Status</Form.Label>
                                <Col sm={8}>
                                    <select className="form-control" value={workload.status + " " + workload.status_from_categories} onChange={handleStatusChange}>
                                        {statusList && statusList.map( ( item ) => (
                                            <option key={item.wl_status_category} disabled={item.disabled} className={item.disabled && "bg-lightGrey"} value={item.wl_status_id + " " + item.wl_status_category}>
                                                {item.name}
                                            </option>
                                        ) )}
                                    </select>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm={4}>SLA</Form.Label>
                                <Col sm={8}>
                                    <div className="slaDiv">
                                        {showSLA ? <>
                                            <table>
                                                <tr>
                                                    <th colSpan={2}>Actual Response Time SLA:</th>
                                                    <td>{util.time_SLA_render( workload.RO_actual_response_sla )}</td>
                                                </tr>

                                                <tr className="bg-light">
                                                    <th colSpan="3">Overall Resolution SLA (Client)</th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "5%" }}></td>
                                                    <th>Business Days Resolution SLA:</th>
                                                    <td>{util.time_SLA_days_render( workload.RO_sla_bizdays )}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <th>Calender Days Resolution SLA:</th>
                                                    <td>{util.time_SLA_days_render( workload.RO_sla_caldays )}</td>
                                                </tr>

                                                <tr className="bg-light">
                                                    <th colSpan="4">Group Resolution SLA</th>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <th>Business Days Resolution SLA:</th>
                                                    <td>
                                                        {util.time_SLA_days_render( workload.RO_group_sla_bizdays )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <th>Calender Days Resolution SLA:</th>
                                                    <td>
                                                        {util.time_SLA_days_render( workload.RO_group_sla_caldays )}
                                                    </td>
                                                </tr>

                                                <tr className="bg-light">
                                                    <th colSpan="3">Resolver Resolution SLA</th>
                                                </tr>
                                                <tr>
                                                    <td> </td>
                                                    <th>Business Days Resolution SLA:</th>
                                                    <td>
                                                        {util.time_SLA_days_render( workload.RO_resolver_sla_bizdays )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> </td>
                                                    <th>Calender Days Resolution SLA:</th>
                                                    <td>
                                                        {util.time_SLA_days_render( workload.RO_resolver_sla_caldays )}
                                                    </td>
                                                </tr>
                                                <span className="text-primary" onClick={() => setShowSLA( !showSLA )}>Hide</span>
                                            </table>
                                        </> :  <span className="text-primary" onClick={() => setShowSLA( !showSLA )}>Show</span>}
                                    </div>
                                </Col>
                            </Form.Group>

                            {/* Region */}
                            <Form.Group as={Row} controlId="formRegion">
                                <Form.Label column sm={4}>Region</Form.Label>
                                <Col sm={8}>
                                    <select className="form-control" value={workload.region} name="region"  onChange={handleChange}>
                                        <option value={null}> -- </option>
                                        {util.regionList.map( ( item ) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ) )}
                                    </select>
                                </Col>
                            </Form.Group>

                            {/* Country */}
                            <Form.Group as={Row} controlId="formCountry">
                                <Form.Label column sm={4}>Country</Form.Label>
                                <Col sm={8}>
                                    <select className="form-control" value={workload.country} name="country"  onChange={handleChange}>
                                        <option value={null}> -- </option>
                                        {util.fixCountryList().map( ( item ) => (
                                            <option key={item.id} value={item.value}>
                                                {item.value}
                                            </option>
                                        ) )}
                                    </select>
                                </Col>
                            </Form.Group>

                            {/* Child Ticket Checkbox */}
                            {/* <Form.Group as={Row} controlId="formChildTicket">
                                <span className="col-4 font-weight-bold">Child Ticket:</span>
                                <span className="col-8 d-flex align-items-center">
                                    <div className="form-check d-flex align-items-center w-100 wl_childTicketDiv">
                                        <input                                            
                                            aria-describedby="text-input-popover" 
                                            type="checkbox" 
                                            className="form-check-input" 
                                            value={isChildTicket} 
                                            checked={isChildTicket} 
                                            onChange={toggleChildTicket} />
                                        <ParentTicketSearch
                                            workloadSimple={workloadList}
                                            setData = {setWorkload}
                                            isChildTicket={isChildTicket}
                                            toggleChildTicket={toggleChildTicket}
                                            value={workload.parent_ticket_id}/>
                                    </div>
                                </span>
                            </Form.Group> */}

                            {/* Ticket Category */}
                            {/* <Form.Group as={Row} controlId="formTicketCategory">
                                <Form.Label column sm={4}>Ticket Category</Form.Label>
                                <Col sm={8}>
                                    <Form.Control as="select" onChange={handleChange} value={workload.ticket_category} name="ticket_category">
                                        <option>Technical</option>
                                        <option>Service Request</option>
                                        <option>Incident</option>
                                        <option>Application Bug</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group> */}
                            {/* Type */}
                            {/* <Form.Group as={Row} controlId="formType">
                                <Form.Label column md={4}>Lead Engagement Type</Form.Label>
                                <Col md={8}>
                                    <Form.Control as="select" name="ticket_type" value={workload.ticket_type} onChange={handleChange}>
                                        <option>Staff Augmentation</option>
                                        <option>Managed Services</option>
                                        <option>Project-Based</option>
                                        <option>Build-Operate-Transfer (BOT)</option>
                                        <option>Hybrid Model</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} controlId="formType">
                                <Form.Label column md={4}>Lead Engagement Type</Form.Label>
                                <Col md={8}>
                                    {[
                                        "Staff Augmentation",
                                        "Managed Services",
                                        "Project-Based",
                                        "Build-Operate-Transfer (BOT)",
                                        // "Hybrid Model",
                                    ].map( ( type, index ) => (
                                        <Form.Check
                                            key={index}
                                            type="checkbox"
                                            label={type}
                                            name="ticket_type"
                                            value={type}
                                            checked={workload.ticket_type.split( "," ).includes( type )}
                                            onChange={handleEngagementTypeChange}
                                        />
                                    ) )}
                                </Col>
                            </Form.Group>

                            {/* Item */}
                            <Form.Group as={Row} controlId="formItem">
                                <Form.Label column sm={4}>Item</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" value={workload.item} name="item" onChange={handleChange}/>
                                </Col>
                            </Form.Group>
                            {/* Ref */}
                            <Form.Group as={Row} controlId="formRef">
                                <Form.Label column md={4}>Ref No.</Form.Label>
                                <Col md={8}>
                                    <Form.Control type="text" value={workload.reference_number} name="reference_number" onChange={handleChange}/>
                                </Col>
                                {workload.wl_category_id && <div className="mx-3 mt-3 ">
                                    <ExtraFields
                                        category={workload. wl_category_id} 
                                        handleChange={handleChange} 
                                        workload={workload}
                                    />
                                </div>}
                            </Form.Group>
                            {workload.RO_wl_log && <div className="row">
                                <div className="col mb-1">
                                    <div className="row">
                                        <span className="col-xl-4 col-lg-12 font-weight-bold">Lead Log:</span>
                                        <span className="col-xl-8 col-lg-12">
                                            <ul className="wl_logDiv">
                                                {workload.RO_wl_log && workload.RO_wl_log.split( '|' ).map( ( item, index ) => item && <li key={index}>{item}</li> )}
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                            </div>}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="btn btn-secondary sizeBtn" onClick={handleClose}>
          Close
                </Button>
                <Button className='btn ActiveBtn sizeBtn' onClick={() => handleUpdateWorkloadBtn( { ...workload, workflow: workflow } )}>
          Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TicketModal;

const ListItem = ( { timestamp, status, user_id, content } ) => {
    const [expand, setExpand] = useState( false );
    const [user, setUser] = useState( {} );

    const getUser = async ( id ) => {
        try {
            const request = await util.axios.get( `/customer_ticket/customer_resolver/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setUser( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getContent = ( content ) => {
        let result = content.split( ', ' );
        result.shift();
        result.shift();
        result.shift();
        return result.join( ', ' ); 
    };

    useEffect( () => {
        getUser( user_id );
    },[] );

    return (
        <div className="workHistory_item">
            <div className="workHistory_subtext">
                <span>{timestamp}{": "}</span>
                <span className="workHistory_status">{status}{": "}</span>
                <span className="workHistory_subtext">Updated by {user[0]?.name + ": "}</span>
            </div>
            <div
                className={!expand ? "workHistory_contentHidden" : ""}
                dangerouslySetInnerHTML={{ __html: getContent( content ) }}></div>
            {content && content.length > 60 && 
            <span
                className="text-primary xs-fontSize"
                onClick={() => setExpand( !expand )}>
                {expand ? " Read Less" : " Read More"}
            </span>
            }
        </div>
    );
};