import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, Fragment, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import util from "../../utils/miniUtils";


export default function AssetDragAndDrop( {
    statusList,
    deleteStatusCategory,
    updateCategoryStatusOrder,
    getAssignedStatus,
    id,
} ) {
    const [itemList, setItemList] = useState( [] );
  
    useEffect( () => {
        if ( statusList?.length ) {
            setItemList( statusList );
        }
    }, [statusList] );
  
    const handleDrop = async ( result ) => {
        const { source, destination } = result;
  
        if ( !destination || source.index === destination.index ) return;
  
        const updatedList = Array.from( itemList );
        const [reorderedItem] = updatedList.splice( source.index, 1 );
        updatedList.splice( destination.index, 0, reorderedItem );
  
        setItemList( updatedList );
  
        try {
            await Promise.all(
                updatedList.map( ( element, index ) =>
                    updateCategoryStatusOrder( element.status_id, index + 1 )
                )
            );
            await getAssignedStatus( id ); // Ensure data consistency
            util.toast().success( "Status Order Updated Successfully" );
        } catch ( error ) {
            util.handleError( error );
        }
    };
  
    const deleteItem = ( e, id ) => {
        e.stopPropagation();
        deleteStatusCategory( id );
    };
  
    return (
        <div className="w-100">
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="statusList">
                    {( provided ) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {itemList.map( ( item, index ) => (
                                <Draggable
                                    key={item.status_id}
                                    draggableId={`status_${item.status_id}`}
                                    index={index}
                                >
                                    {( provided ) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            className="d-flex justify-content-between border p-1 pl-2 m-2 align-items-center"
                                        >
                                            <div>{item.status_name}</div>
                                            <button
                                                className="btn btn-sm btn-outline-danger"
                                                onClick={( e ) => deleteItem( e, item.status_id )}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    )}
                                </Draggable>
                            ) )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}
  