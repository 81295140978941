import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import TicketModal from "./TicketModal";
// import NewTicketModal from './NewTicketModal';
import { Button, createTheme, FormControl, InputLabel, MenuItem, MuiThemeProvider, Select, styled } from '@material-ui/core';
// import util from "../../../utils/miniUtils";
// import WorkHistoryDrawer from './WorkHistoryDrawer';
import { TextField, Tooltip } from "@material-ui/core";

// import DeleteWorkloadModal from '../../../workload/shared/DeleteWorkloadModal';
// import NewTicketModal from '../customers/ticketSolution/TicketTable/NewTicketModal';
// import TicketModal from '../customers/ticketSolution/TicketTable/TicketModal';
import WorkHistoryDrawer from '../customers/ticketSolution/TicketTable/WorkHistoryDrawer';
import util from '../utils/miniUtils';
import DeleteWorkloadModal from '../workload/shared/DeleteWorkloadModal';
import NewTicketModal from './NewTicketModal';
import WorkloadSelector from '../workload/shared/WorkloadSelector';
import { useLocation } from 'react-router-dom';
import useManageWorkload from '../workload/manage_workload/useManageWorkLoad';


const TicketTable = ( ) => {
    const [modalShow, setModalShow] = useState( false ); // Ticket and Modal States
    const [loading, setLoading] = useState( false );
    const [tickets, setTickets] = useState( [] );
    const [viewModalShow, setViewModalShow] = useState( false );
    const [selectedTicket, setSelectedTicket] = useState( null );
    const [pageNo, setPageNo] = useState( 0 );
    const [search, setSearch] = useState( '' );
    const [total, setTotal] = useState( 0 );
    const [priority, setPriority] = useState( '' );
    const [tableStatus, setTableStatus] = useState( '' );
    const [pageSize, setPageSize] = useState( 20 );
    const [isDrawerOpen, setIsDrawerOpen] = useState( false );
    const [statusList, setStatusList] = useState( [] );
    const [workloads, setWorkloads] = useState( [] );
    const [selectedCompany, setSelectedCompany] = useState( util.user.customer_company_id ? util.user.customer_company_id : "" );
    const userAccount = util.user.id;
    const [companyList, setCompanyList] = useState( [] );
    const [deleteMultipleWorkloadModal, setDeleteMultipleWorkloadModal] = useState( {
        show: false,
        workloads: []
    } );
    const [extraColumn, setExtraColumn] = useState( [] );
    const location = useLocation();
    const eliteData = location.state?.eliteData;
    const [isSaving, setIsSaving] = useState( false );
    const { addAttachment, removeAttachmentfile, deleteAttachment, existingAttachments, attachmentFiles, saveAttachments } = useManageWorkload();
    

    // Api Request to get my tickets for customer
    async function getCustomerTickets( pageSize, pageNo, company_id, tableStatus, priority, account, searchTerm ){
        try {
            setLoading( true );
            const elite_connect_id = util.user.elite_connect_id || eliteData?.elite_connect_id;
            let url = `/eliteconnectworkload/get_all_my_workload_customer?limit=${pageSize}&page=${pageNo}&company_id=${company_id}&tableStatus=${tableStatus}&priority=${priority}&account_id=${account}&elite_connect_id=${elite_connect_id}`;
            if( searchTerm.length ) {
                url += `&search=${searchTerm}`;
            }
            const request = await util.axios.get( url );
            const { error, msg, data, total } = request.data;
            if( error ) throw msg;
            setTickets( data );
            setTotal( total );
            setLoading( false );
        } catch ( error ) {
            util.handleError( error );
            setLoading( false );
        }
    }

    const getStatusListToCategory = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/get_all_status` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setStatusList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    const getCustomerCompanyList = async () => {
        try {
            const request = await util.axios.get( `/customer_companies?all=true&status=Active` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setCompanyList( data );
        } catch ( error ) {
            util.handleError( error );
        }       
    };

    const deleteWorkload = async ( id ) => {
        try {
            setLoading( true );
            const request = await util.axios.delete( `/delete_workload/${id}` );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg + " with id:" + id );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    const handleMultipleWorkloadDeleteBtn = async ( workloads ) => {
        await workloads.forEach( async item => {
            await deleteWorkload( item.id );
        } );  
        closeMultipleDeleteWorkload();
        await getCustomerTickets( pageSize, pageNo, selectedCompany, tableStatus, priority, userAccount, '' );
    };

    const closeMultipleDeleteWorkload = () => {
        setDeleteMultipleWorkloadModal( prev => {
            return {
                ...prev,
                show: false
            };
        } );        
    };

    // const handleCompanySelect = ( value ) => {
    //     setSelectedCompany( value );
    //     if( value == util.user.customer_company_id ) {
    //         console.log( { selected: value, user_company: util.user.customer_company_id } );
    //         setUserAccount( util.user.id );
    //     } else {
    //         setUserAccount( "" );
    //     }
    // };

    // useEffect( () => {
    //     setPageNo( 0 );
    //     getCustomerTickets( pageSize, pageNo, selectedCompany, tableStatus, priority, userAccount, '' );
    // },[tableStatus, priority,pageSize, selectedCompany ] );

    // useEffect( () => {
    //     if ( pageNo !== 0 ) { // This condition prevents fetching tickets again after resetting pageNo to 0 in the above useEffect.
    //         getCustomerTickets( pageSize, pageNo, selectedCompany, tableStatus, priority, userAccount, '' );
    //     }
    // }, [pageNo] );

    // useEffect( () => {
    //     const timerId = setTimeout( () => {
    //         if( search ) {
    //             getCustomerTickets( pageSize, 0, selectedCompany, tableStatus, priority, userAccount, search );
    //         }
    //     }, 500 );
    //     return () => clearTimeout( timerId );
    // },[search] );

    useEffect( () => {
        // Avoid fetching when the page number is reset to 0 by other filters
        // and when search is not used or is reset.
        if ( ( pageNo !== 0 || search ) && search !== '' ) {
            const timerId = search ? setTimeout( () => {
                getCustomerTickets( pageSize, 0, selectedCompany, tableStatus, priority, userAccount, search );
            }, 500 ) : getCustomerTickets( pageSize, pageNo, selectedCompany, tableStatus, priority, userAccount, '' );
    
            return () => clearTimeout( timerId );
        }
        // This branch handles the scenario when search is cleared, 
        // ensuring tickets are fetched without a search term.
        else if ( search === '' ) {
            getCustomerTickets( pageSize, 0, selectedCompany, tableStatus, priority, userAccount, '' );
        }
    }, [search, pageNo, pageSize, selectedCompany, tableStatus, priority, userAccount] );
    
    const getFieldsByCategory = async (  ) => {
        try{
            const request = await util.axios.get( `/wl_fieldCategory/by_category/${38}` );
            const { error,msg, data } = request.data;
            if( error ) throw msg;
            setExtraColumn( data );
        } catch( e ) {
            util.handleError( e );
        }
    };
    const renderUpdateField = ( rowData ) => {
        // const isExpanded = expandedRows[rowData.id];
        const displayText = rowData.work_history?.split( "|,|" )[0];
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <div style={{ overflow: "hidden", maxHeight: "5ch", textOverflow: "ellipsis" }} dangerouslySetInnerHTML={{ __html: displayText?.split( ', ' )[3] }}></div>
                <span
                    onClick={toggleDrawer( rowData,true )}
                    // onClick={() => handleExpandClick( rowData.id )}
                    style={{ color: 'blue', textDecoration: 'none', border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                >
      
                    {rowData.work_history ? "Read More..." : "Update..."}
                </span>
            </div>
        );
    };
    const toggleDrawer = ( rowData, open ) => ( event ) => {
        if ( event.type === 'keydown' && ( event.key === 'Tab' || event.key === 'Shift' ) ) {
            return;
        }
        setSelectedTicket( rowData ); // set the selected ticket
        setIsDrawerOpen( open );
    };

    // Function to open modal with ticket data
    const handleOpenModal = ( ticket ) => {
        setSelectedTicket( ticket );
        setViewModalShow( true );
    };

    const handleNewTicketCloseModal = () => {
        setModalShow( false );
        getCustomerTickets( pageSize, pageNo, selectedCompany ? selectedCompany : util.user.customer_company_id, tableStatus, priority, userAccount, '' );
        setSelectedTicket( null );
    };

    const saveWorkload = async ( formData ) => {
        try {
            if( util.user.role === 20 ){
                formData = { ...formData, customer_id: util.user.id };
            }
            const requiredFields = [ 
                { field:"customer_id", name: "Customer Name" },
                { field:"group_id", name: "Assignment Group" },
                { field:"wl_category_id", name: "Category" },
                { field:"resolver_id", name: "Solution Architect" },
                { field:"short_description", name: "Lead Title" },
                { field:"long_description", name: "Lead Description" },
            ];
            
            for( let i=0;i<requiredFields.length; i++ ){

                if( !formData[requiredFields[i].field] ){
                    
                    throw new Error( `${requiredFields[i].name} is required` );
                }
            }
            
            formData.elite_connect_id = ( eliteData?.elite_connect_id || util.user.elite_connect_id );
            setIsSaving( true );
            console.log( attachmentFiles );
            const request = await util.axios.post( `/eliteconnectworkload/new_workload`, { ...formData } );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            attachmentFiles.length > 0 && saveAttachments( data.id );
            util.toast().success( "New Ticket Created" );
            handleNewTicketCloseModal();
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setIsSaving( false );
        }
    };

    const updateWorkLoad = async ( temp_workload ) => {
        try {
            setLoading( true );
            setIsSaving( true );
            const { id } = temp_workload;
            const request = await util.axios.put( `/workload/update_workload/${id}`, { ...temp_workload } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            attachmentFiles.length > 0 && saveAttachments();
            util.toast().success( msg );
            setLoading( false );
            // setWorkLoadCreated( true );
            handleCloseViewModal();
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }finally{
            setIsSaving( false );
        }
    };

    // Function to close modal
    const handleCloseViewModal = () => {
        setViewModalShow( false );
        setSelectedTicket( null );
        getCustomerTickets( 
            pageSize, 
            pageNo, 
            selectedCompany ? selectedCompany : util.user.customer_company_id, 
            tableStatus, 
            priority, 
            userAccount,'' );
    };

    // Example status colors (You can expand or modify)
    const getStatusColor = ( status ) => {
        switch ( status ) {
            case "Acknowledged": return 'bg-primary text-white'; // In Progress
            case "Closed": return 'bg-success text-white'; // Closed
            case "New": return 'bg-secondary text-white'; // Open
            case "Open": return 'bg-secondary text-white'; // Open
            case "Working": return 'bg-warning text-white'; // Pending Action
                // ... other cases
            default: return 'bg-warning text-white';
        }
    };

    const getPriorityColor = ( priority ) => {
        switch ( priority ) {
            case "Critical": return 'bg-danger text-white'; // In Progress
            case "High": return 'bg-warning text-white'; // Closed
            case "Medium": return 'bg-primary text-white'; // Open
            case "Low": return 'bg-secondary text-white'; // Pending Action
                // ... other cases
            default: return 'bg-secondary text-white';
        }
    };

    const getPriorityTag = ( priority ) => {
        switch ( priority ) {
            case "Critical": return 'Cr'; // In Progress
            case "High": return 'Hi'; // Closed
            case "Medium": return 'Md'; // Open
            case "Low": return 'Lo'; // Pending Action
                // ... other cases
            default: return 'priority';
        }
    };

    useEffect( () => {
        getCustomerTickets( pageSize, pageNo, selectedCompany, tableStatus, priority, userAccount,'' );
        getStatusListToCategory();
        if( ( util.arrangePermissionData().Announcement?.length > 0 ? 
            util.arrangePermissionData().Announcement.includes( "Create" ) : 
            false ) ) {
            getCustomerCompanyList();
        }
    },[] );

    return (
        <MuiThemeProvider theme={tableTheme}>
            <MaterialTable
                title="Ticket Table"
                columns={[
                    {
                        title: 'Status',
                        field: 'status',
                        headerStyle: {
                            textAlsign: "center"
                        },
                        render: rowData => (
                            <div className='d-flex justify-content-center align-items-center'>
                                <span className={`p-2 mr-1 rounded-circle text-center ${getPriorityColor( rowData.priority )}`} style={{ width: "40px" }}>
                                    {getPriorityTag( rowData.priority )}
                                </span>
                                <div className={`d-flex w-100 justify-content-center align-items-center rounded ${getStatusColor( rowData.statusName )}`}
                                    style={{ margin: "5px 0" }}>
                                    {rowData.statusName}
                                </div>
                            </div>
                        )
                    },
                    
                    { 
                        title: 'ID',
                        field: 'ticket_number',
                        headerStyle: {
                            textAlign: "center"
                        },
                        cellStyle: {
                            textAlign: "center"
                        },
                        render: rowData => (
                            <div className="customer-ticketId" onClick={() => handleOpenModal( rowData )}>{rowData.ticket_number}</div>
                        )
                    },
                    { title: 'Customer Name', field: 'customer_name' },
                    { title: 'Lead Title', field: 'short_description',
                        render: rowData => {
                            const maxLen = 50; // Max length of text to show
                            const description = rowData.short_description;
                            // Truncate text if it's longer than maxLen
                            const displayText = description && description.length > maxLen ? description.substring( 0, maxLen ) + '...' : description;
                            return (
                                <Tooltip title={description || ''} placement="top" arrow>
                                    <span>{displayText}</span>
                                </Tooltip>
                            );
                        }
                        
                    },
                    
                    { 
                        title: 'Monthly Contract Value($)', 
                        field: 'mcv',
                        headerStyle: {
                            textAlign: "center"
                        },
                        cellStyle: {
                            textAlign: "center"
                        },
                    },
                    { 
                        title: 'Annual Contract Value ($)', 
                        field: 'acv',
                        headerStyle: {
                            textAlign: "center"
                        },
                        cellStyle: {
                            textAlign: "center"
                        },
                    },
                    { 
                        title: 'Total Contract Value ($)', 
                        field: 'tcv',
                        headerStyle: {
                            textAlign: "center"
                        },
                        cellStyle: {
                            textAlign: "center"
                        },
                    },
                    { 
                        title: 'Probability %', 
                        field: 'probability',
                        headerStyle: {
                            textAlign: "center"
                        },
                        cellStyle: {
                            textAlign: "center"
                        },
                    },
                    { 
                        title: 'End Client Legal Name', 
                        field: 'end_client_name',
                        headerStyle: {
                            // textAlign: "center"
                        },
                        cellStyle: {
                            // textAlign: "center"
                        },
                    },
                    { 
                        title: 'Created On', 
                        field: 'createdAt',
                        headerStyle: {
                            textAlign: "center"
                        },
                        cellStyle: {
                            textAlign: "center"
                        },
                    },
                    { title: 'Assign To', field: 'resolver_name' },
                    {
                        title: 'Update',
                        field: 'work_history',
                        render: rowData => (
                            <div>
                                {renderUpdateField( rowData )}
                                {/* <FontAwesomeIcon icon={faPlus} className="float-right" onClick={toggleDrawer( rowData,true )}  style={{ color: 'blue', textDecoration: 'none', border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}/> */}
                            </div>
                        )
                        // render: rowData => renderUpdateField(rowData)
                    },
                ]}
                data={tickets}
                isLoading={loading}
                page={pageNo}
                totalCount={total}
                onSelectionChange={( rows ) => setWorkloads( [...rows] )}
                actions={[
                    { 
                        icon: () =>  <MuiThemeProvider theme={selectTheme}>
                            <FormControl variant="outlined" size="small">
                                <InputLabel id="simple-select-priority">Priority</InputLabel>
                                <Select
                                    labelId="simple-select-priority"
                                    id="demo-simple-select"
                                    // value=""
                                    label="Priority"
                                    autoWidth
                                    onChange={( e ) => setPriority( e.target.value )}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="critical">Critical</MenuItem>
                                    <MenuItem value="high">High</MenuItem>
                                    <MenuItem value="medium">Medium</MenuItem>
                                    <MenuItem value="low">Low</MenuItem>
                                </Select>
                            </FormControl>
                        </MuiThemeProvider>,
                        isFreeAction: true 
                    },
                    { 
                        icon: () =>  <MuiThemeProvider theme={selectTheme}>
                            <FormControl variant="outlined" size="small">
                                <InputLabel id="simple-select-status">Status</InputLabel>
                                <Select
                                    labelId="simple-select-status"
                                    id="demo-simple-select"
                                    // value=""
                                    label="Status"
                                    autoWidth
                                    onChange={e => setTableStatus( e.target.value )}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {/* <MenuItem value="open">Open</MenuItem> */}
                                    <MenuItem value="open">In Progress</MenuItem>
                                    <MenuItem value="closed">Closed</MenuItem>
                                </Select>
                            </FormControl>
                        </MuiThemeProvider>,
                        isFreeAction: true 
                    },
                    {
                        icon: () => <span className={"btn btn-danger ml-1"}>Delete</span>,
                        isFreeAction: false,
                        onClick: () => setDeleteMultipleWorkloadModal( prev => {
                            return {
                                ...prev,
                                show: true,
                                workloads: [...workloads]
                            };
                        } ),
                    },
                    // { 
                    //     icon: () => ( util.arrangePermissionData().Announcement?.length > 0 ? 
                    //         util.arrangePermissionData().Announcement.includes( "Create" ) : 
                    //         false ) ? <MuiThemeProvider theme={selectTheme}>
                    //             <FormControl variant="outlined" size="small">
                    //                 <InputLabel id="simple-select-status">Customer</InputLabel>
                    //                 <Select
                    //                     labelId="simple-select-status"
                    //                     id="demo-simple-select"
                    //                     label="Customer"
                    //                     autoWidth
                    //                     value={selectedCompany}
                    //                     onChange={( e ) => handleCompanySelect( e.target.value )}
                    //                 >
                    //                     <MenuItem value="">All</MenuItem>
                    //                     {companyList.map( el => <MenuItem key={el.customer_company_id} value={el.customer_company_id}>{el.customer_name}</MenuItem> )}
                    //                 </Select>
                    //             </FormControl>
                    //         </MuiThemeProvider> : "",
                    //     isFreeAction: true 
                    // },
                    { 
                        icon: () => <NewTicketButton variant="contained">+ Leads</NewTicketButton>,
                        isFreeAction: true,
                        onClick: () => setModalShow( true ), 
                    },
                ]}
                options={{
                    toolbarButtonAlignment: "left",
                    showTitle: false,
                    selection: true,
                    pageSizeOptions: [20, 50, 100], 
                    pageSize: pageSize,
                    emptyRowsWhenPaging: true,
                    // actionsColumnIndex: -1,
                    headerStyle: { backgroundColor: "#f5f5f5", fontWeight: "bold" },
                    minBodyHeight:"300px",
                    maxBodyHeight:"calc(100vh - 360px)"
                }}
                onSearchChange={query => {
                    setSearch( query );
                    setPageNo( 0 );
                    
                }}
                onChangePage={newPage => {
                    setPageNo( newPage );
                }}
                onChangeRowsPerPage={( pageSize ) => {
                    setPageSize( pageSize );
                    setPageNo( 0 );               
                }}
            />
            {( viewModalShow && selectedTicket ) && (
                <TicketModal
                    show={viewModalShow}
                    handleClose={() => setViewModalShow( false )}
                    workload_id={selectedTicket.id}
                    saveWorkload={updateWorkLoad}
                    isSaving={isSaving}
                />
            )}
            {modalShow && <NewTicketModal 
                show={modalShow} 
                handleClose={() => setModalShow( false )} 
                saveWorkload={saveWorkload}
                selectedCompany={selectedCompany}
                isSaving={isSaving}
                attachmentFiles={attachmentFiles}
                addAttachment={addAttachment} removeAttachmentfile={removeAttachmentfile} deleteAttachment={deleteAttachment}
                existingAttachments={existingAttachments}
                

            />}

            { isDrawerOpen && <WorkHistoryDrawer
                isDrawerOpen={isDrawerOpen}
                toggleDrawer={toggleDrawer}
                workload_id={selectedTicket.id}
                statusList={statusList}
            />}

            {deleteMultipleWorkloadModal.show && 
            <DeleteWorkloadModal
                show={deleteMultipleWorkloadModal.show}
                workloads={deleteMultipleWorkloadModal.workloads}
                handleClose={closeMultipleDeleteWorkload}
                deleteWorkload={handleMultipleWorkloadDeleteBtn}
            />}
        </MuiThemeProvider>
    );
};

export default TicketTable;

const tableTheme = createTheme( {
    palette: {
        primary: {
            main: '#2196F3',
        },
        secondary: {
            main: '#2196F3',
        }
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiTableCell: {
            root: {
                padding: 2,
                fontSize: 12,
                backgroundColor: "white",
            },
            head: {
                textDecoration: "none",
                lineHeight: 1,
                backgroundColor: "#DFE1E0!important",
                color: "#5b5b5b!important",
            },
            body: {
                fontSize: "12px!important",
            }
        },
        MuiIconButton: {
            root: {
                padding: 0,
                paddingRight: 8,
            },
        },
        MuiOutlinedInput: {
            root: {
                outline: "none"
            }
        },
        MuiTablePagination: {
            toolbar: {
                paddingRight: "44px !important"
            }
        }
    } } );

const selectTheme = createTheme( {
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiFormControl : {
            root: {
                minWidth: "120px",
            }
        },
        MuiOutlinedInput: {
            inputMarginDense: { 
                paddingTop: '10px',
                paddingBottom: '7.5px',
            }
        }
    } } );

const NewTicketButton = styled( Button )( {
    boxShadow: 'none',
    textTransform: 'none',
    lineHeight: 1.5,
    // padding: 8,
    backgroundColor: '#007bff',
    color: "white",
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
} );