import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import util from '../utils/miniUtils';
import ExtraFields from './ExtraFields';
import { useLocation } from 'react-router-dom';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { values } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import useManageWorkload from '../workload/manage_workload/useManageWorkLoad';

const EXCEL_FILES = '.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const WORD_FILES = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const PPT_FILES = '.ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation';


function NewTicketModal( { show, handleClose, saveWorkload,selectedCompany, escalate_description="", isSaving,addAttachment, removeAttachmentfile, deleteAttachment, existingAttachments, attachmentFiles } ) {
    const[loading, setLoading] = useState( false );
    // const [employeeList, setEmployeeList] = useState( [] );
    const [statusList, setStatusList] = useState( [] );
    const [groupList, setGroupList] = useState( [] );
    // const [customerList, setCustomerList] = useState( [] );
    // const [categoryList, setCategoryList] = useState( [] );
    const categoryList = {
        "id": 38,
        "name": "Elite Connect Lead",
        "description": " Leads generated from eJElite Connect.  These leads represent potential clients or partners who have shown interest in services or solutions through direct interactions, referrals, or marketing campaigns. Each lead includes key contact details, engagement history, and qualification insights to support effective follow-ups and conversions.",
        "active": 1,
        "group_id": 31,
        "prefix": "ECL",
        "createdAt": "2025-01-28 17:31:13",
        "updatedAt": "2025-01-28 17:31:13"
    };
    const [resolverList, setResolverList] = useState( [] );
    const [workloadList, setWorkloadList] = useState( [] );
    
    const [workflowList, setWorkflowList] = useState( [] );
    const [memberName, setMemberName] = useState( [] );
    const location = useLocation();
    const eliteData = location.state?.eliteData;
    const [workload, setWorkload] = useState( {
        department_id: null,
        wl_category_id: null,
        parent_ticket_id: null,
        customer_id: null,
        resolver_id: null,
        group_id: null,
        created_by: util.user.id ? util.user.id: null,
        status: null,
        status_from_categories: null,
        ticket_number: null,
        short_description: null,
        long_description:  escalate_description !== ""? escalate_description :null,
        ticket_category: null,
        ticket_type: "",
        item: null,
        reference_number: null,
        priority: "Medium",
        region: "Americas",
        country: util.user.country ? util.user.country:"United States",
        isApproval: false,
        customer_company_id: selectedCompany ? selectedCompany : util.user.customer_company_id
    } );
    const eliteGroup = [{
        "id": 31,
        "itemName": "eJElite Program Admin",
        "description": "eJElite Program Admin Group that is created to approve leads",
        "status": 1,
        "ag_lead_id": 173,
        "region": "Global",
        "country": "Global",
        "customer_company_id": 1,
        "createdAt": "2025-01-24 20:19:13",
        "updatedAt": "2025-01-24 20:19:13"
    }];
    const [workflow, setWorkflow] = useState( {
        wl_workflow_id: null,
        approver1: null,
        approver2: null,
        
        approvals_required: null
    } );
    function handleWorkflowChange( e ) {
        const { name, value } = e.target;
        console.log( name, value );
        setWorkflow( ( prev ) => ( { ...prev, [name]: Number( value ) } ) );
        console.log( workflow );
    }
    const handleChange = ( e ) => {
        e.preventDefault();
        const { name, value } = e.target;
        setWorkload( prev => ( {
            ...prev,
            [name]: value
        } ) );

        // Setting the status to the first value of the status list
        if( workload.wl_category_id !== null && statusList ){
            setWorkload( ( prev ) => {
                return {
                    ...prev,
                    status: statusList[0]?.wl_status_id,
                    status_from_categories: statusList[0]?.wl_status_category
                };
            } );}
    };
    const handleEngagementTypeChange = ( e ) => {
        const { checked, value } = e.target;
        console.log( checked, value );
        setWorkload( ( prev ) => {
            const currentTypes = prev.ticket_type ? prev.ticket_type.split( "," ) : [];
            const updateTypes = checked ? [...currentTypes, value] : currentTypes.filter( ( item ) => item !== value );
            const updatedTicketType = updateTypes.join( "," );

            // Return the new state
            return {
                ...prev,
                ticket_type: updatedTicketType,
            };
        } );

    };
    const getWorkflowsToCategory = async ( category_id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/workflow?category=${category_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setWorkflowList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    console.log( workflowList, "workflowlist" );
    function handleDescription( e ) {
        const value = e.toString( "html" );
        setWorkload( ( prev ) => {
            return {
                ...prev,
                long_description: value,
            };
        } );
    }

    

    // API Requests
    const getGroupList = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/get_create_initial_request` );
            const { error, msg, grpList } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setGroupList( grpList );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };
    console.log( groupList );

    const getWorkloadList = async ( ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/WorloadList` );
            const { error, msg, workLoadList } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setWorkloadList( workLoadList );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    

    // const getCategories = async ( ) => {
    //     const group_id = 31;
    //     try{
    //         setLoading( true );
    //         const request = await util.axios.get( `/groups/get_categories/${group_id}` );
    //         const { error, msg, data } = request.data;
    //         if( error ) throw msg;
    //         setCategoryList( data.filter( el => el.active === 1 ) );
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }
    //     setLoading( false );
    // };

    const getAssignedMembersToGroup = async ( group_id ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/groups/get_assigned_members/${group_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setResolverList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    const getStatusListToCategory = async (  ) => {
        try {
            const category_id = 38;
            setLoading( true );
            const request = await util.axios.get( `/get_all_status_category/by_category/${category_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setStatusList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };


    const getDefault = async () => {
        // Early return if the condition is not met
        if ( !util.user.customer_company_id ) {
            return;
        }
        try {
            setLoading( true );
            const request = await util.axios.get( `/customer_companies/${util.user.customer_company_id}` );
            const { error, data } = request.data;
            
            if ( error ) {
                throw new Error( "Not Working" ); // Assuming `data` contains the error message
            }
            
            // Destructure your data
            const { customer_group_id, customer_default_category, customer_default_resolver } = data;
            setWorkload( prev => ( {
                ...prev,
                group_id: customer_group_id !== null ? customer_group_id : workload.group_id,
                wl_category_id: customer_default_category !== null ? customer_default_category : workload.wl_category_id,
                resolver_id: customer_default_resolver !== null ? customer_default_resolver : workload.resolver_id,
            } ) );
            // if ( customer_group_id !== null && groupList.length > 0 && groupList.some( obj => obj.id === customer_group_id ) ) {
            //     // If the array is not empty and there is at least one object with the same id, update the state
            //     setWorkload( prev => ( {
            //         ...prev,
            //         group_id: customer_group_id,
            //     } ) );
            // }
            // if ( customer_default_category !== null && categoryList.length > 0 && categoryList.some( obj => obj.id === customer_default_category ) ) {
            //     // If the array is not empty and there is at least one object with the same id, update the state
            //     setWorkload( prev => ( {
            //         ...prev,
            //         wl_category_id: customer_default_category,
            //     } ) );
            // }
            // if ( customer_default_resolver !== null && resolverList.length > 0 && resolverList.some( obj => obj.id === customer_default_resolver ) ) {
            //     // If the array is not empty and there is at least one object with the same id, update the state
            //     setWorkload( prev => ( {
            //         ...prev,
            //         resolver_id: customer_default_resolver,
            //     } ) );
            // }
            // if( groupList ) {
            //     updateWorkloadIfValid( groupList, customer_group_id, 'group_id' );
            // }
            // if( categoryList ) {
            //     updateWorkloadIfValid( categoryList, customer_default_category, 'wl_category_id' );
            // }
            // if( resolverList ) {
            //     updateWorkloadIfValid( resolverList, customer_default_resolver, 'resolver_id' );
            // }
            // Usage
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    };

    function updateWorkloadIfValid( list, id, key ) {
        console.log( key );
        // Check if the ID is not null, the list is not empty, and the list contains the ID
        if ( id !== null && list.length > 0 && list.some( obj => obj.id === id ) ) {
            setWorkload( prev => ( {
                ...prev,
                [key]: id,
            } ) );
        }
    }
    
    const getMembersName = async() => {
        try {
            setLoading( true );
            const elite_connect_id = util.user.elite_connect_id || eliteData?.elite_connect_id;
            const request = await util.axios.get( `eliteconnect/getmemberaccount/${elite_connect_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setMemberName( data );
            
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    useEffect( () => {
        getWorkloadList();
        
        getDefault();
     
    },[] );

    useEffect( () => {
        // if( customerCompany ) {
        // getCustomerList( customerCompany );
        
        getGroupList();
        getMembersName();

        // }
    },[] );

    useEffect( () => {
        if( workflowList.length > 0 ) {
            setWorkflow( {
                name: workflowList[0].name,
                wl_workflow_id: workflowList[0].id,
                approver1: workflowList[0].approver1,
                approver2: workflowList[0].approver2,
                // approver3: workflowList[0].approver3,
                approvals_required: workflowList[0].approvals_required
            } );
            setWorkload( prev => (
                { ...prev, isApproval : true } )
            );
        } else {
            setWorkload( prev => (
                { ...prev, isApproval : false } )
            );
        }
    },[workflowList] );
  

    useEffect( () => {
        if( workload.wl_category_id !== null ) {
            getStatusListToCategory( workload.wl_category_id );
            getWorkflowsToCategory( workload.wl_category_id );

        }
    },[workload.wl_category_id] );

    useEffect( () => {
        // getCategories(  );

        if( workload.group_id !== null ) {
            getAssignedMembersToGroup( workload.group_id );
            // getCategories(  );
            getStatusListToCategory( workload.wl_category_id );
        }
    },[workload.group_id, groupList] );

    return (
        <>
            {util.LOADING_SCREEN( loading )}
            <Modal size='xl' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Lead
                    </Modal.Title>
                </Modal.Header>
                {isSaving &&  <div className='circular-prog-overlay'><CircularProgress /></div>   }
                
                <Modal.Body>
                    {/* <div className="row d-flex justify-content-between align-items-center"> */}
                    
                    <Form className='mx-4'>
                        {/* <Row>
                            <Col md={7} sm={12}>
                                <Form.Group as={Row} controlId="formCustomer">
                                    <Col md={4}>
                                        <Form.Label>Customer Name</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        { util.user.role !==20 ? (

                                            <select className='form-control' name="customer_id" onChange={handleChange} >
                                                <option value=''>Select Customer</option>
                                                {
                                                    memberName.map( ( item ) => (
                                                        <option key={item.id} value={item.id}>{item.name} </option>
                                                    ) )
                                                }
                                            </select>
                                        )
                                                
                                                
                                            : (
                                                <select className='form-control' value={util.user.id} name="id"  >
                                                    <option value="">{util.user.name}</option>
                                                </select>
                                            )}
                                        
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={5} sm={12}>
                                
                            </Col>
                        </Row> */}

                    </Form>
                    {/* </div> */}
                    {/* </div> */}
                    <Form className='mx-4'>
                        <Row>
                            <Col md={7} sm={12}>
                                <Form.Group as={Row} controlId="formCustomer">
                                    <Col md={4}>
                                        <Form.Label>Customer Name</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        { util.user.role !==20 ? (

                                            <select className='form-control' name="customer_id" onChange={handleChange} >
                                                <option value=''>Select Customer</option>
                                                {
                                                    memberName.map( ( item ) => (
                                                        <option key={item.id} value={item.id}>{item.name} </option>
                                                    ) )
                                                }
                                            </select>
                                        )
                                                
                                                
                                            : (
                                                <select className='form-control' value={util.user.id} name="id"  >
                                                    <option value=''>Select Customer</option>
                                                    <option value={util.user.id}>{util.user.name}</option>
                                                </select>
                                            )}
                                        
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formAssignmentGroup">
                                    <Form.Label column md={4}>Assignment Group</Form.Label>
                                    <Col md={8}>
                                        <select className="form-control" value={workload.group_id} name="group_id"  onChange={handleChange}>
                                            <option value=""></option>
                                            {eliteGroup.map( ( item ) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.itemName}
                                                </option>
                                            ) )}
                                        </select>
                                    </Col>
                                </Form.Group>

                                {/* Category */}
                                <Form.Group as={Row} controlId="formCategory">
                                    <Form.Label column md={4}>Category</Form.Label>
                                    <Col md={8}>
                                        <select className="form-control" value={workload.wl_category_id} name="wl_category_id"  onChange={handleChange}>
                                            <option value=""></option>
                                            <option value={categoryList.id}>{categoryList.name}</option>
                                            {/* {categoryList.map( ( item ) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ) )} */}
                                        </select>
                                    </Col>
                                </Form.Group>

                                {/* Resolver */}
                                <Form.Group as={Row} controlId="formResolver">
                                    <Form.Label column md={4}>Solution Architect</Form.Label>
                                    <Col md={8}>
                                        <select className="form-control" value={workload.resolver_id} name="resolver_id"  onChange={handleChange}>
                                            <option value=""></option>
                                            {resolverList.map( ( item ) => (
                                                <option key={item.account_id} value={item.account_id}>
                                                    {item.name}
                                                </option>
                                            ) )}
                                        </select>
                                    </Col>
                                </Form.Group>

                                {/* Subject */}
                                <Form.Group as={Row} controlId="formSubject">
                                    <Form.Label column md={4}>Lead Title</Form.Label>
                                    <Col md={8}>
                                        <Form.Control type="text" onChange={handleChange} name="short_description" value={workload.short_description}/>
                                    </Col>
                                </Form.Group>

                                {/* Description */}
                                <Form.Group as={Row} controlId="formDescription">
                                    <Form.Label column md={4}>Lead Description</Form.Label>
                                    <Col md={8}>
                                        
                                        <ReactQuill
                                            value={workload.long_description || ""}
                                            className='newWorkload-editor'
                                            onChange={handleDescription}
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['bold', 'italic', 'underline'],
                                                    [{ 'align': [] }],
                                                    ['clean']
                                                ],
                                            }}
                                            formats={[
                                                'header', 'font',
                                                'bold', 'italic', 'underline',
                                                'list', 'bullet',
                                                'align'
                                            ]}
                                        />
                                        <div>
                                            <Tooltip title="Click to select attachments">
                                                <span >
                                                    <label htmlFor="wl_attachment"  className="btn btn-sm btn-primary mt-2">
                                                        <FontAwesomeIcon icon={faPaperclip} />
                                                        <span className="p-1">Attachments</span>
                                                    </label>
                                                </span>
                                            </Tooltip>
                                            <input
                                                type="file"
                                                multiple name="files"
                                                id="wl_attachment"
                                                onChange={( e ) => addAttachment( e )}
                                                accept={`image/*,.pdf,.txt,text/csv,${WORD_FILES},${EXCEL_FILES},${PPT_FILES}`}
                                                className="invisible" />
                                            <div className="mt-1 d-flex flex-wrap">
                                                {
                                                                                existingAttachments?.map( ( a,i )=> {
                                                                                    const { title, id, workload_id } = a;
                                                                                    return (
                                                                                        <span key={i} className="mr-1 mb-1 d-flex align-items-center attachmentBtn p-1">
                                                                                            <a className="text-secondary" target="new" href={`${util.baseUrl()}${util.AWSURL()}/${workload_id}/${title}`}>{title.substring( 0, 20 )}</a>
                                                                                            <span className="p-1 isPointer text-danger" onClick={()=>deleteAttachment( id )}><FontAwesomeIcon icon={faTimes} /></span>
                                                                                        </span>
                                                                                    );
                                                                                } )
                                                }
                                                {
                                                    attachmentFiles.map( ( a, i ) => {
                                                        return <span key={i} className="mr-1 mb-1 d-flex align-items-center attachmentBtn p-1">
                                                            <span>{a.name}</span>
                                                            <span className="p-1 isPointer text-danger" onClick={() => removeAttachmentfile( a.name )}><FontAwesomeIcon icon={faTimes} /></span>
                                                        </span>;
                                                    } )
                                                }
                                            </div>
                                        </div>
                                    </Col>

                                </Form.Group>
                               
                            
                            </Col>
                            
                            {/* <Col md={5}> */}
                            <Col md={5} sm={12} style={{ backgroundColor: '#f0f0f0' }}>
                                {/* Priority */}
                                {workflowList.length > 0 && (
                                    <div >
                                        <Form.Group as={Row} controlId="formWorkflow">
                                            <Form.Label column md={4}>Workflow</Form.Label>
                                            <Col md={8}>
                                                <Form.Control plaintext readOnly defaultValue={workflow.name} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formApprover1">
                                            <Form.Label column md={4}>Approver 1</Form.Label>
                                            <Col md={8}>
                                                <Form.Control as="select" value={workflow.approver1} name="approver1" onChange={handleWorkflowChange} disabled>
                                                    <option value={null}> -- </option>
                                                    {groupList.map( item => (
                                                        <option key={item.id} value={item.id}>{item.itemName}</option>
                                                    ) )}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formApprover2">
                                            <Form.Label column md={4}>Approver 2</Form.Label>
                                            <Col md={8}>
                                                <Form.Control as="select" value={workflow.approver2} disabled name="approver2" onChange={handleWorkflowChange}>
                                                    <option value={null}> -- </option>
                                                    {groupList.map( item => (
                                                        <option key={item.id} value={item.id}>{item.itemName}</option>
                                                    ) )}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </div>
                                )}
                                <Form.Group as={Row} controlId="formPriority" className='mt-2'>
                                    <Form.Label column md={4}>Priority</Form.Label>
                                    <Col md={8}>
                                        <Form.Control as="select" onChange={handleChange} value={workload.priority} name="priority">
                                            <option>Critical</option>
                                            <option>High</option>
                                            <option>Medium</option>
                                            <option>Low</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                {/* Status */}
                                <Form.Group as={Row} controlId="formStatus">
                                    <Form.Label column md={4}>Status</Form.Label>
                                    <Col md={8}>
                                        <select className="form-control" value={workload.status + " " + workload.status_from_categories}>
                                            {statusList && statusList.map( ( item ) => (
                                                <option key={item.wl_status_category} value={item.wl_status_id + " " + item.wl_status_category}>
                                                    {item.name}
                                                </option>
                                            ) )}
                                        </select>
                                    </Col>
                                </Form.Group>

                                {/* Region */}
                                <Form.Group as={Row} controlId="formRegion">
                                    <Form.Label column md={4}>Region</Form.Label>
                                    <Col md={8}>
                                        <select className="form-control" value={workload.region} name="region"  onChange={handleChange}>
                                            <option value={null}> -- </option>
                                            {util.regionList.map( ( item ) => (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            ) )}
                                        </select>
                                    </Col>
                                </Form.Group>

                                {/* Country */}
                                <Form.Group as={Row} controlId="formCountry">
                                    <Form.Label column md={4}>Country</Form.Label>
                                    <Col md={8}>
                                        <select className="form-control" value={workload.country} name="country"  onChange={handleChange}>
                                            <option value={null}> -- </option>
                                            {util.fixCountryList().map( ( item ) => (
                                                <option key={item.id} value={item.value}>
                                                    {item.value}
                                                </option>
                                            ) )}
                                        </select>
                                    </Col>
                                </Form.Group>

                                

                                {/* Ticket Category */}
                                {/* <Form.Group as={Row} controlId="formTicketCategory">
                                    <Form.Label column md={4}>Ticket Category</Form.Label>
                                    <Col md={8}>
                                        <Form.Control as="select" onChange={handleChange} value={workload.ticket_category} name="ticket_category">
                                            <option>Technical</option>
                                            <option>Service Request</option>
                                            <option>Incident</option>
                                            <option>Application Bug</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group> */}
                                {/* Type */}
                                <Form.Group as={Row} controlId="formType">
                                    <Form.Label column md={4}>Lead Engagement Type</Form.Label>
                                    <Col md={8}>
                                        {[
                                            "Staff Augmentation",
                                            "Managed Services",
                                            "Project-Based",
                                            "Build-Operate-Transfer (BOT)",
                                            // "Hybrid Model",
                                        ].map( ( type, index ) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                label={type}
                                                name="ticket_type"
                                                value={type}
                                                checked={workload.ticket_type.split( "," ).includes( type )}
                                                onChange={handleEngagementTypeChange}
                                            />
                                        ) )}
                                    </Col>
                                </Form.Group>

                                {/* Ref */}
                                <Form.Group as={Row} controlId="formRef">
                                    <Form.Label column md={4}>Ref No.</Form.Label>
                                    <Col md={8}>
                                        <Form.Control type="text" value={workload.reference_number} name="reference_number" onChange={handleChange}/>
                                    </Col>
                                    {workload.wl_category_id && <div className="mx-3 mt-3 ">
                                        <ExtraFields
                                            category={workload. wl_category_id} 
                                            handleChange={handleChange} 
                                            workload={workload}
                                        />
                                    </div>}
                                </Form.Group>
                                
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="activeBtn sizeBtn btn-secondary" onClick={handleClose}>
          Close
                    </Button>
                    <Button variant=" activeBtn sizeBtn btn-secondary" onClick={() => saveWorkload( { ...workload,workflow: workflow } )}>
          Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NewTicketModal;