import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";


import { Modal, Nav, Tab } from "react-bootstrap";
import util from "../../utils/miniUtils";
import CategoryItem from "../categories/components/CategoryItem";
import DragAndDrop from "../categories/components/DragAndDrop";
import FieldCategoryList from "../wlField/FieldCategoryList";
import WorkflowList from "../workflow/WorkflowList";
import CreationModal from "../shared/CreationModal";
import AssetCategoryItem from "./AssetsCateogryItem";
import AssetDragAndDrop from "./AssetDragAndDrop";
import AssetsCategoryCreationModule from "./AssetsCategoryCreationModule";
import AssetTypeCategory from "./AmTypeCategory";
import AmFieldCategoryList from "./AmFieldCategory";


const AssetsCategory = ( { settings } ) => {
    const { getListPath, getAssignedStatusPath } = settings;
    const [formData, setFormData] = useState( {} );
    const [statusList, setStatusList] = useState( [] );
    const [typeList, setTypeList] = useState( [] );
    const [fieldList, setFieldList] = useState( [] );
    const [catName ,setCatName] =useState( );

    const [state, setState] = useState( {
        showExtraColumn: false,
        childData: [],
        typeData:[],
        fieldData:[],
        parentData: [],
        showModal: false,
        id: null,
        actionType: 1,
        showAddStatus: false,
        showAddType:false,
        showAddMake:false,
        showAddModel:false,
        addStatus:false,
        addSType: false,
        addMake:false,
        typeId:null,
        makeId:null

    } );

    const reloadData = () => {
        getCategoryList();
        setState( { ...state, showModal: false } );
    };
    // Get Assigned Status list according to category
    const getAssignedStatus = async ( id ) => {
        try {
            const request = await util.axios.get( `${getAssignedStatusPath}/${id}` );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;
           
            getStatusList();
            setState( { ...state, childData: data, showExtraColumn: true, id: id } );
          
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getAssignedType = async ( id ) => {
        try {
            const request = await util.axios.get( `amtypecategory/${id}` );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;
            getTypeList();
            setState( { ...state, typeData: data, showExtraColumn: true, id: id } );
            console.log( state.typeData );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const getAssignedField = async ( id ) => {
        try {
            const request = await util.axios.get( `/amfieldcategory/${id}` );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;
            // getFieldList(id);
            setState( { ...state, fieldData: data, showExtraColumn: true, id: id } );
            console.log( state.typeData );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const getTypeList = async () => {
        try {
            const request = await util.axios.get( "/amalltype" );
            const { msg, error, data } = request.data;
            if( error ) throw msg;
            setTypeList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Get all status list
    const getStatusList = async () => {
        try {
            const request = await util.axios.get( "/amstatus" );
            const { msg, error, data } = request.data;
            if( error ) throw msg;
            setStatusList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Get field list according to category
    const getFieldList = async ( categoryId ) => {
        try {
            const request = await util.axios.get( `/amfieldcategoryAvilable/${categoryId}` );
            const { msg, error, data } = request.data;
            if( error ) throw msg;
            setFieldList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    // Save status in wl_status_categories table
    const saveStatusCategory = async () => {
        let newStatus = {
            am_category_id: state.id,
            status_id: formData.status,
            status_order: state.childData.length + 1
        };
        try {
            const request = await util.axios.post( '/amstatuscategory', { newStatus } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getAssignedStatus( state.id );
            util.toast().success( msg );
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const saveTypeCategory = async () => {
        let newType = {
            am_category_id: state.id,
            type_id: formData.status,
           
        };
        try {
            const request = await util.axios.post( '/amtypecategory', { newType } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getAssignedType( state.id );
         
            util.toast().success( msg );
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const onSelect = ( e, id,name ) => {
    
        e.stopPropagation();
        
        getAssignedStatus( id );
        getAssignedType( id );

        getAssignedField( id );
        setState( ( prev ) => {
            return {
                ...prev,
                id: id
            };
        } );
    };

    const deleteCategory = async ( id ) => {
        try{
            const request = await util.axios.delete( `/amcategory/${id}` );
            const { error, msg } = request;
            if( error ) throw msg;
            getCategoryList();
        } catch ( error ) {
            util.handleError( error );
        }
    }; 

    const onDelete = ( e, id ) => {
        e.stopPropagation();
        deleteCategory( id );
    };

    const onEdit = ( e, id, data ) => {
        e.stopPropagation();
        setFormData( data );
        setState( ( prev ) => {
            return {
                ...prev,
                id: id,
                actionType:2,
                showModal: true,
            };
        } );
    };

    // Get request for getting the parent data i.e. the category list here
    const getCategoryList = async () => {
        try {
            const request = await util.axios.get( getListPath );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;
            // Setting up the data from the request into state.parentData
            setState( ( prev ) => {
                return {
                    ...prev,
                    parentData: data
                };
            } );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Delete request for deleting the status from categories
    const deleteStatusCategory = async ( id ) => {
        try {
            const request = await util.axios.delete( `/amstatuscategory/${id}` );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getAssignedStatus( state.id );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const deleteTypeCategory = async ( id ) => {
        try {
            const request = await util.axios.delete( `/amtypecategory/${id}` );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getAssignedStatus( state.id );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const updateCategoryStatusOrder = async ( id, order ) => {
        try {
            const request = await util.axios.put( `/update_status_category/${id}`, { status_order: order } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            // util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    //Create Field
    const createField = async ( data ) => {
        try {
            const request = await util.axios.post( 'amfieldcategory', { newField: { ...data } } );
            const { error, msg } = request.data;
            if( error ) throw msg;
         await   getAssignedField( state.id );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Delete field
    const deleteField = async ( fieldId, categoryId ) => {
        console.log(fieldId,categoryId)
        try {
            const request = await util.axios.delete( `amfieldcategory/${fieldId}` );
            const { msg, error } = request.data;
            if( error ) throw msg;
           await getAssignedField( state.id );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );  
        }
    };

    const handleClose = () => {
        setFormData( {} );
        setState( {
            ...state,
            showModal: false 
        } );
    };

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        if( value === "notOnListStatus" ){
            setState( { ...state, addStatus: true } );
        }
        else if ( value === "notOnListType" ){
            setState( { ...state, addType: true } );
        }
    
        else {
            setFormData( {
                [name]: value
            } );
        }
    };

    useEffect( () => {
        getCategoryList();
    }, [] );
    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-sm-6 bg-white">
                        <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                            <b>Asset Category</b>
                            <FontAwesomeIcon
                                className="isCursor text-primary"
                                onClick={() => setState( { ...state, showModal: true, actionType: 1 } )}
                                icon={faPlus}
                            />
                        </div>
                        <div className="listContainer">
                            {state.parentData.map( ( d, i ) => {
                                return <AssetCategoryItem isActive={state.id} key={i} data={d} onSelect={onSelect} onDelete={onDelete} onEdit={onEdit} />;
                            } )}
                        </div>
                    </div>
                    {state.id && (
                        <div className="col-md-9 bg-white">
                            <Tab.Container defaultActiveKey="status">
                                <Nav variant="tabs">
                                    <Nav.Item className="mr-4">
                                        <Nav.Link eventKey="status">Asset Status</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="mr-4">
                                        <Nav.Link eventKey="type">Asset Type</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fields"> Custom Fields</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="status">
                                        <div className="col-md-4 col-sm-12 bg-white">
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                                                    <b>Asset Status</b>
                                                    <FontAwesomeIcon
                                                        className="isCursor text-primary"
                                                        onClick={() => setState( { ...state, showAddStatus: true, actionType: 1 } )}
                                                        icon={faPlus}
                                                    />
                                                </div>
                                                <AssetDragAndDrop
                                                    statusList={state.childData}
                                                    deleteStatusCategory={deleteStatusCategory}
                                                    id={state.id}
                                                    updateCategoryStatusOrder={updateCategoryStatusOrder}
                                                    getAssignedStatus={getAssignedStatus}
                                                />
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="type">
                                        <div className="col-md-12 col-sm-12 bg-white">
                                            <div className="col-md-12">
                                               
                                                <AssetTypeCategory
                                                    typeList={state.typeData}
                                                    deleteTypeCategory={deleteTypeCategory}
                                                    id={state.id}
                                                    getAssignedType={getAssignedType}
                                                    setState={setState}
                                                    isActive={state.typeId}
                                                    state={state}
                                                />
                                            </div>
                                        </div>

                                        
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fields">
                                        
                                        <AmFieldCategoryList
                                            list={state.fieldData}
                                            category={state.id}
                                            getFieldList={getAssignedField}
                                            createField={createField}
                                            deleteItem={deleteField}
                                        /> 
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    )}
                   
                 
                </div>
            </div>
            {state.showModal && (
                <AssetsCategoryCreationModule
                    show={state.showModal}
                    handleClose={handleClose}
                    settings={settings}
                    reloadData={reloadData}
                    actionType={state.actionType}
                    id={state.id}
                    data={formData}
                />
            )}
            {state.showAddStatus && (
                <AddStatusModal
                    show={state.showAddStatus}
                    handleClose={() => {
                        setState( { ...state, showAddStatus: false, addStatus: false } );
                        setFormData( {} );
                    }}
                    data={statusList}
                    handleChange={handleChange}
                    inputStatus={state.addStatus}
                    cancelInput={() => setState( { ...state, addStatus: false } )}
                    getStatusList={getStatusList}
                    saveStatus={saveStatusCategory}
                />
            )}
            {state.showAddType && (
                <AddTypeModal
                state={state}
                    show={state.showAddType}
                    handleClose={() => {
                        setState( { ...state, showAddType: false, addType: false } );
                        setFormData( {} );
                    }}
                    data={typeList}
                    handleChange={handleChange}
                    inputType={state.addType}
                    cancelInput={() => setState( { ...state, addType: false } )}
                    getTypeList={getTypeList}
                    saveType={saveTypeCategory}
                />
            )}
        </Fragment>
    );
};

export default AssetsCategory;

const AddStatusModal = ( { data, show, handleChange, handleClose, saveStatus, inputStatus, getStatusList, cancelInput } ) => {
    const [status, setStatus] = useState( "" );
    
    function handleStatusInput ( e ) {
        const { value } = e.target;
        setStatus( value );
    }

    async function submitStatus() {
        let newStatus = { name: status };
        try {
            const request = await util.axios.post( '/amstatus', { newStatus } );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            getStatusList();
            cancelInput();
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <div className="d-flex justify-content-center">
                    <b>Add Status</b>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="form-group">
                        <label htmlFor="statusSelector">Select Status</label>
                        <select className="form-control" id="statusSelector" name="status" onChange={handleChange}>
                            <option value=""></option>
                            {data.map( item => <option key={item.id} value={item.id}>{item.status_name}</option> )}
                            <option className="bg-warning" value={"notOnListStatus"}>Not on the List</option>
                        </select>
                    </div>
                    {inputStatus && <div>
                        <div className="form-group">
                            <label htmlFor="statusSelector">Status Name</label>
                            <input className="form-control" name="status" value={status} onChange={handleStatusInput} />
                        </div>
                        <div className="d-flex justify-content-end">
                            <span className="btn btn-success sizeBtn" onClick={submitStatus}>Save</span>
                        </div>
                    </div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn btn-outline-danger" onClick={handleClose}>
                    <FontAwesomeIcon
                        icon={faTimes}
                    />
                </span>
                <span className="btn btn-outline-primary" onClick={saveStatus}>
                    <FontAwesomeIcon
                        icon={faCheck}
                    />
                </span>
            </Modal.Footer>
        </Modal>
    );
};

const AddTypeModal = ( { state,data, show, handleChange, handleClose, saveType, inputType, getTypeList, cancelInput } ) => {
    const [type, setType] = useState( "" );
    
    function handleTypeInput ( e ) {
        const { value } = e.target;
        setType( value );
    }

    async function submitType() {
        let newType = { name: type,am_category_id:state.id };
        try {
            const request = await util.axios.post( '/amtype', { newType } );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            getTypeList();
            cancelInput();
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <div className="d-flex justify-content-center">
                    <b>Add Type</b>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="form-group">
                        <label htmlFor="statusSelector">Select Type</label>
                        <select className="form-control" id="statusSelector" name="status" onChange={handleChange}>
                            <option value=""></option>
                            {data.map( item => <option key={item.id} value={item.am_type_id}>{item.name}</option> )}
                            <option className="bg-warning" value={"notOnListType"}>Not on the List</option>
                        </select>
                    </div>
                    {inputType && <div>
                        <div className="form-group">
                            <label htmlFor="statusSelector">Type Name</label>
                            <input className="form-control" name="status" value={type} onChange={handleTypeInput} />
                        </div>
                        <div className="d-flex justify-content-end">
                            <span className="btn btn-success sizeBtn" onClick={submitType}>Save</span>
                        </div>
                    </div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn btn-outline-danger" onClick={handleClose}>
                    <FontAwesomeIcon
                        icon={faTimes}
                    />
                </span>
                <span className="btn btn-outline-primary" onClick={saveType}>
                    <FontAwesomeIcon
                        icon={faCheck}
                    />
                </span>
            </Modal.Footer>
        </Modal>
    );
};