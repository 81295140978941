import React, { useEffect, useMemo, useState } from 'react';
import { Popover } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, } from '@fortawesome/free-solid-svg-icons';
import util from '../../utils/miniUtils';
import useManageWorkload from '../manage_workload/useManageWorkLoad';

const WorkloadSelector = ( { name, value, title, id, data, reload, children } ) => {
    const { checkStatus } = useManageWorkload();
    const [edit, setEdit] = useState( false );
    const [optionsList, setOptionsList] = useState( [] );
    const [itemList, setItemList] = useState( {} );
    const [state, setState] = useState( {} );
    let reopened = ( ( data?.RO_wl_log )?.split( "Reopen" ).length - 1 ) != 0;

    useEffect( () => {
        if( name === "status" ) {
            setState( {
                [name]: value,
                status_from_categories: data.status_from_categories
            } );
        }else {
            setState( {
                [name]: value,
            } );
        }
    },[] );

    const getDepartments = async () => {
        try{
            const request = await util.axios.get( '/department/simple-list' );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setOptionsList( data );
            data.forEach( item => setItemList( ( prev )=>{
                return {
                    ...prev,
                    [item.id]: item.itemName
                };
            } ) );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getResolverList = async (  group_id ) => {
        try{
            const request = await util.axios.get( `/groups/get_assigned_members/${group_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setOptionsList( data );
            data.forEach( item => setItemList( ( prev )=>{
                return {
                    ...prev,
                    [item.employee_id]: item.name
                };
            } ) );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getStatusList = async ( id ) => {
        try {
            const request = await util.axios.get( `/get_all_status_category/by_category/${id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setOptionsList( checkStatus( data, value, reopened ) );
            data.forEach( item => setItemList( ( prev )=>{
                return {
                    ...prev,
                    [item.wl_status_id]: item.name
                };
            } ) );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getOptionList = async () => {
        switch( name ) {
            case "resolver_id":
                getResolverList( data.group_id );
                break;
            case "priority":
                setOptionsList( ["Low", "Medium", "High", "Critical"] );
                break;
            case "status":
                getStatusList( data.wl_category_id );
                break;
            default: 
                getDepartments();
        }
    };

    useEffect( () => {
        if( edit ) {
            getOptionList();
        }
    },[edit] );

    // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );

    // Triggering the popover on the double click
    const handleDoubleClick = ( event ) => {
        setEdit( true );
        setAnchorEl( event.currentTarget );
    };

    function handleChangeValue ( e ) {
        const { name, value } = e.target;
        switch( name ) {
            case "priority":
                setState( {
                    [name]: value,
                } );
                break;
            case "status":
                setState( {
                    ["status"]: Number( value.split( " " )[0] ),
                    ["status_from_categories"]: Number( value.split( " " )[1] )
                } );
                break;
            default:
                setState( {
                    [name]: Number( value ),
                } );
        }
    }

    const handleClose = async ( workload_id ) => {
        if( edit ) {
            try {
                const request = await util.axios.put( `/workload/update_workload/${workload_id}`, state );
                const { error, msg } = request.data;
                if( error ) throw msg;
                util.toast().success( msg );
                reload();
            } catch ( error ) {
                util.handleError( error );
            }
            setEdit( false );
        }
        // resetting the anchor for the popover
        setAnchorEl( null );
    };

    const handleCancel = () => {
        setEdit( false );
        setAnchorEl( null );
    };

    const multipleOptions = () => {
        switch( name ) {
            case "resolver_id":
                return <div className="d-flex">
                    <div className='form-group m-1'>
                        <label htmlFor={name}>{title}</label>
                        <select className='w-100 form-control'
                            name={name} 
                            value={state[name]}
                            onChange={handleChangeValue}>
                            <option></option>
                            {optionsList.map( item => <option key={item.employee_id} value={item.employee_id}>{item.name}</option> )}
                        </select>
                    </div>
                </div>;
            case "priority":
                return <div className="d-flex">
                    <div className='form-group m-1'>
                        <label htmlFor={name}>{title}</label>
                        <select className='w-100 form-control'
                            name={name} 
                            value={state[name]}
                            onChange={handleChangeValue}>
                            <option></option>
                            {optionsList.map( ( item, index ) => <option key={index} value={item}>{item}</option> )}
                        </select>
                    </div>
                </div>;
            case "status":
                return <div className="d-flex">
                    <div className='form-group m-1'>
                        <label htmlFor={name}>{title}</label>
                        <select className='w-100 form-control'
                            name={name} 
                            value={`${state[name]} ${state["status_from_categories"]}`}
                            onChange={handleChangeValue}>
                            <option></option>
                            {optionsList.map( ( item, index ) => <option key={index} disabled={item.disabled} className={item.disabled && "bg-lightGrey"} value={`${item.wl_status_id} ${item.wl_status_category}`}>{item.name}</option> )}
                        </select>
                    </div>
                </div>;
            default:
                return <div className="d-flex">
                    <div className='form-group m-1'>
                        <label htmlFor={name}>{title}</label>
                        <select className='w-100 form-control'
                            name={name} 
                            value={state[name]}
                            onChange={handleChangeValue}>
                            <option></option>
                            {optionsList.map( item => <option key={item.id} value={item.id}>{item.itemName}</option> )}
                        </select>
                    </div>
                </div>; 
        }
    };

    function editValue() {
        return <Popover
            id={open ? 'text-input-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCancel}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <div className='d-flex'>
                {multipleOptions()}
                <div className="d-flex align-items-end m-1">
                    <span className='btn btn-outline-danger' onClick={handleCancel}><FontAwesomeIcon icon={faTimes} /></span>
                    <span className='btn btn-outline-primary' onClick={() => handleClose( id ) }><FontAwesomeIcon icon={faCheck} /></span>
                </div>
            </div>
        </Popover>;
    }

    const renderDisplayValue  = useMemo( () => {
        switch ( name ) {
            case "resolver_id":
                return itemList[value] || data.resolver_name || "_ _ _ _";
            case "status":
                return itemList[value] || data.statusName || "_ _ _ _";
            case "priority":
                return data.priority || "_ _ _ _";
            default:
                return itemList[value] || data.department_name || "_ _ _ _";
        }
    }, [name, value, data, itemList] );

    if( name === "status" || name === "priority" ) {
        return (
            <>
                {editValue()}
                <div className='d-flex justify-content-between editableWorkload' onDoubleClick={handleDoubleClick}>
                    {children}
                </div>
            </>
        );
    }

    return <>
        {editValue()}
        <div className='d-flex justify-content-between editableWorkload' onDoubleClick={handleDoubleClick}>
            {edit ? editValue() : children || renderDisplayValue}
        </div>
    </>;
};

export default WorkloadSelector;