import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CircularProgressbar } from 'react-circular-progressbar';
import { CircularProgress } from '@material-ui/core';

const InviteManager = ( { showModal, handleModalClose, handleAddEliteConnect, setEmail, isSending } ) => {
    const restrictedDomains = ["gmail.com",
        "outlook.com",
        "yahoo.com",
        "hotmail.com",
        "aol.com",
        "zoho.com",
        "icloud.com",
        "mail.com",
        "protonmail.com",
        "yandex.com"];
    const validationSchema = Yup.object().shape( {
        elite_email: Yup.string()
            .email( 'Enter a valid email' )
            .required( 'Email is required' )
            .test( "test-domain", "Entity email cannot be from third-party domains", function( value ){

                const { is_entity } = this.parent;
                if( !is_entity || !value ) return true;
                const domain = value.split( "@" )[1]?.toLowerCase();
                return domain && !restrictedDomains.includes( domain );
            } ),
        is_entity: Yup.boolean().required( "Please select an option" ),
    } );

    return (
        <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Invite Elite Connect </Modal.Title>
            </Modal.Header>
            {isSending &&  <div className='circular-prog-overlay'><CircularProgress /></div>   }
            <Formik
                initialValues={{ 
                    elite_email: '',
                    elite_name: "",
                    elite_HQ_address:"",
                    accepted_by:"",
                    designation:"",
                    is_entity:false
                }}
                validationSchema={validationSchema}
                onSubmit={( values, { setSubmitting } ) => {
                    handleAddEliteConnect( values );
                    setSubmitting( false );
                }}
            >
                {( { values, isSubmitting } ) => (
                    <FormikForm>
                        <Modal.Body>
                            {/* <Form.Group className="mb-3">
                                <Form.Check 
                                    type="checkbox"
                                    name="is_entity"
                                    label="Entity"
                                    as={Field}
                                />
                                <ErrorMessage name="is_entity" component="p" className="text-danger" />
                            </Form.Group> */}
                            <Form.Group className="mb-3">
                                <Form.Label>Select Type</Form.Label>
                                <div role="group" aria-labelledby="is_entity" className="select-entity">
                                    <div className="form-check">
                                        <Field 
                                            type="radio"
                                            name="is_entity"
                                            value="true"
                                            className="form-check-input"
                                            id="entity"
                                        />
                                        <label className="form-check-label" htmlFor="entity">Entity</label>
                                    </div>
                                    <div className="form-check">
                                        <Field 
                                            type="radio"
                                            name="is_entity"
                                            value="false"
                                            className="form-check-input"
                                            id="individual"
                                        />
                                        <label className="form-check-label" htmlFor="individual">Individual</label>
                                    </div>
                                </div>
                                <ErrorMessage name="is_entity" component="p" className="text-danger" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Email
                                    <small className="text-muted"> (Please enter email address)</small>
                                </Form.Label>
                                <Field 
                                    name="elite_email" 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="e.g. john@example.com" 
                                />
                                <ErrorMessage name="elite_email" component="p" className="text-danger" />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Legal Name
                                    <small className='text-muted'>(Please enter the legal name of the entity or individual)</small>
                                    <Field 
                                        name="elite_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Please enter the legal name"
                                    />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Address
                                    {/* <small >Please enter the address of the entity or individual</small> */}
                                    <Field
                                        name="elite_HQ_address"
                                        type="text"
                                        className="form-control"
                                        placeholder="Please enter the address"
                                    
                                    />
                                </Form.Label>
                            </Form.Group>
                            {values.is_entity === "true" && ( 
                                <> 
                            
                                    <Form.Group>
                                        <Form.Label>
                                    Authorized Person Accepting this agreement
                                            <Field
                                                name="accepted_by"
                                                placeholder="Enter the name of the person accepting this agreement."
                                                className="form-control"
                                                type="text"
                                    
                                            />
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                    Title/ Designation
                                            <Field
                                                type="text"
                                                placeholder="Please enter the Title/Designation of the person"
                                                className="form-control"
                                                name="designation"
                                            />
                                        </Form.Label>
                                    </Form.Group>
                                </> )}
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-sm btn-secondary activeBtn" onClick={handleModalClose}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-sm btn-secondary activeBtn" disabled={isSubmitting}>
                                Add Elite Connect
                            </button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
        </Modal>
    );
};

export default InviteManager;
