import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Tooltip, InputGroup, Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { CountryInfo } from "../../utils/countryInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash, faPaperclip, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import util from "../../utils/miniUtils";

const CountryFillOne = ( { newState, onChangeHandler ,isReadOnly,attachmentFiles,setAttachmentFiles ,setNewState ,employerId=util.user.employer_id,errors } ) => {
    const [countryState, setCountryState] = useState( {
        countries: CountryInfo,
    } );
    const [showTaxId, setShowTaxId] = useState( false );

    const [validationMessage, setValidationMessage] = useState( "" ); // Feedback message
    
    const { countries } = countryState;
    const taxIdRef = useRef( null );

    useEffect( () => {
        if ( window.location.hash === "#tax-verification" && taxIdRef.current ) {
            taxIdRef.current.scrollIntoView( { behavior: "smooth", block: "start" } );
        }
    }, [] );


    // Set default country to India if newState.country is not set
    useEffect( () => {
        if ( !newState.country ) {
            onChangeHandler( { target: { name: "country", value: "India" } } );
        }
    }, [newState.country, onChangeHandler] );

    // Find selected country data
    const searchSelectedCountry = countries.find(
        ( obj ) => obj.name === newState.country
    );

    const handleChangePhone = ( e ) => {
        const inputValue = e.target.value.replace( /[^0-9]/g, "" ); 
        
        if ( inputValue.length <= 10 ) {
            onChangeHandler( {
                target: { name: e.target.name, value: inputValue },
            } );
        }
    };
   
    

    
    useEffect( () => {
        if ( newState.tax_id_file && !attachmentFiles.length ) {
            setAttachmentFiles( [{ name: newState.tax_id_file }] );
        } else if ( !newState.tax_id_file && attachmentFiles.length ) {
            setAttachmentFiles( [] ); // Clear the UI state when no file exists
        }
    }, [newState.tax_id_file, attachmentFiles, setAttachmentFiles] );
    
    // useEffect( () => {
    //     if ( !newState.tax_id_file ) {
    //         setNewState( ( prevState ) => ( {
    //             ...prevState,
    //             tax_id_feedback: null, // Ensure it is null when no file exists
    //             tax_id_verified: 0, // Reset verification status
    //         } ) );
    //     }
    // }, [newState.tax_id_file, setNewState] );
    
    
    const handleFileUpload = async ( e ) => {
        const file = e.target.files[0];
        if ( !file ) return;

        const formData = new FormData();
        formData.append( "file", file );

        try {
            const response = await util.axios.post(
                `/employer/upload_attachment/${employerId}`,
                formData
            );
            console.log( response );
            const { error,validationResult
                
            } = response.data;
            if ( error ) {
                util.toast().error( validationResult
                    
                );
            } else {
                util.toast().success( `Uploaded ${file.name} successfully` );
                setAttachmentFiles( [{ name: file.name }] );
                console.log( attachmentFiles );
                // Update the employer details state to include the new file name
                onChangeHandler( { target: { name: "tax_id_file", value: file.name } } );
                setNewState( prevState => ( {
                    ...prevState,
                    tax_id_file: file.name,
                    tax_id_feedback: newState.tax_id_feedback || "Document Uploaded", // Ensure feedback is set
                    tax_id_verified: 0, // Reset verification state
                } ) );
                
            }
        } catch ( error ) {
            util.handleError( error );
        }
    };
   
    
    
    const handleRemoveAttachment = async () => {
        if ( !attachmentFiles.length ) return;
    
        const fileName = attachmentFiles[0].name;
    
        try {
            // Call the API to delete the file
            const response = await util.axios.delete(
                `/employer/delete_attachment/${employerId}`,
                {
                    data: { fileName }, // Pass the file name to the backend
                }
            );
    
            const { error, msg } = response.data;
    
            if ( error ) {
                util.toast().error( msg );
            } else {
                util.toast().success( "File removed successfully" );
                setAttachmentFiles( [] ); // Clear the attachment files state
                onChangeHandler( { target: { name: "tax_id_file", value: "" } } ); // Clear the form field
                // Reset status in dropdown to "Choose an option"
                setNewState( {
                    ...newState,
                    tax_id_file: "",
                    tax_id_feedback: null, // Reset status to default
                    tax_id_verified: 0, // Reset verification state
                } );
            }
        } catch ( error ) {
            util.handleError( error );
        }
    };
    // Validate Tax ID using the backend API
    const validateTaxId = async ( taxId, country ) => {
        try {
            const response = await util.axios.get( `/gpt/validate_tax_id/${taxId}/${country}` );
            const { error, validationResult } = response.data;
    
            if ( error ) {
                setValidationMessage( validationResult );   // Set the error message
            } else {
                setValidationMessage( validationResult );     // Set the success message
            }
        } catch ( err ) {
            setValidationMessage( "Failed to validate Tax ID. Please try again." ); // Generic error message
        }
    };
    
    

    const handleTaxIdChange = ( e ) => {
        const { name, value } = e.target;
        onChangeHandler( { target: { name, value } } );

        // Clear validation states when changing the input
        setValidationMessage( "" );
    };
    
   
    // useEffect( () => {
    //     setStatusIcon( statusIcons[newState.tax_id_feedback] || null );
    // }, [newState.tax_id_feedback] );
    
    
    
    const fileLabel =
        newState.country === "India" ? "Attach PAN Card File" : "Attach Tax ID File";
    return (
        <>
            {/* Country Field */}
            <Col md={6}>
                <Form.Group controlId="formCountrySelect">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                        as="select"
                        name="country"
                        value={newState.country || "India"}
                        onChange={onChangeHandler}
                        readOnly={isReadOnly}
                        style={{ width: "80%" }}
                        disabled={isReadOnly}
                    >
                        <optgroup label="Most Active">
                            <option value="">--</option>
                            <option value="United States">United States</option>
                            <option value="Canada">Canada</option>
                            <option value="India">India</option>
                            <option value="Australia">Australia</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Japan">Japan</option>
                            <option value="Germany">Germany</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                        </optgroup>
                        <optgroup label="All Countries">
                            <option value="" />
                            {countries.map( ( item ) => (
                                <option key={uuidv4()} value={item.name}>
                                    {item.name}
                                </option>
                            ) )}
                        </optgroup>
                    </Form.Control>
                </Form.Group>
            </Col>

            {/* Selected Country Info and Phone Field */}
            {searchSelectedCountry && (
                <>
                    {/* Employer Phone and Company Tax ID Fields */}
                    <Col md={5}>
                        <Form.Group controlId="formEmployerPhone">
                            <Form.Label style={{ textAlign: "left", display: "block" }}>Employer Phone</Form.Label>
                            <div className="d-flex align-items-center">
                                {/* Flag */}
                                <img
                                    style={{ width: "25px", height: "20px", marginRight: "8px" }}
                                    src={searchSelectedCountry.flagPng}
                                    alt={searchSelectedCountry.name}
                                />
            
                                {/* Country Code */}
                                <span style={{ marginRight: "8px", whiteSpace: "nowrap" }}>
                                    {searchSelectedCountry.root}
                                    {searchSelectedCountry.name !== "United States" && searchSelectedCountry.suffix}
                                </span>

                                {/* Phone Input */}
                                <Form.Control
                                    type="text"
                                    name="employer_phone"
                                    value={newState.employer_phone || ""}
                                    placeholder="+123 456 7890"
                                    onChange={handleChangePhone}
                                    readOnly={isReadOnly}
                                />
                            </div>
                        </Form.Group>
                    </Col>

                    {/* Company Tax ID */}
                    {isReadOnly?"":
                        <Col md={5}>
                            <Form.Group controlId="formCompanyTaxId">
                                <Form.Label>
                                    {newState.country === "India" ? "PAN Number" : "Company Tax ID"}
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={showTaxId ? "text" : "password"}
                                        name="company_tax_id"
                                        value={newState.company_tax_id || ""}
                                        onChange={handleTaxIdChange}
                                        // onBlur={() => validateTaxId( newState.company_tax_id, newState.country )}
                                        placeholder={
                                            newState.country === "India"
                                                ? "Enter PAN Number"
                                                : "Enter Company Tax ID"
                                        }
                                        readOnly={isReadOnly}
                                        autoComplete="new-password"
                                        
                                        
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            style={{ outline: 'none', boxShadow: 'none' }}
                                            className=" activeBtn "
                                            variant="outline-secondary"
                                            onClick={() => {
                                                setShowTaxId( !showTaxId );
                                               
                                            }}

                                        >
                                            <FontAwesomeIcon icon={showTaxId ? faEye : faEyeSlash} />
                                        </Button>
                                        <Button
                                            className="btn btn-sm btn-secondary activeBtn "
                                            onClick={() => validateTaxId( newState.company_tax_id, newState.country )}
                                            disabled={isReadOnly}
                                        >
                    Validate
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                                {errors.company_tax_id && <small className="text-danger">{errors.company_tax_id}</small>}

                                {/* <Col md={4}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={()=>validateTaxId( newState.company_tax_id,newState.country )}
                                            disabled={!newState.company_tax_id}
                                        >
                                    Verify
                                        </button>
                                    </Col> */}
                                {validationMessage && (
                                    <div
                                        className={`mt-2 d-flex align-items-center ${
                validationMessage.includes( "Invalid" ) ? "text-danger" : "text-success"
            }`}
                                    >
                                        <FontAwesomeIcon
                                            icon={validationMessage.includes( "Invalid" ) ? faTimesCircle : faCheck}
                                            className="mr-2"
                                        />
                                        <span>{validationMessage}</span>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>}
                </>
            )}
            {employerId &&
            <div ref={taxIdRef}>

                {isReadOnly ? "" :
                    <Row className="mt-4 ml-5">
                        <Col md={12} className="ml-5">
                            <label htmlFor="emp_attachment" className="btn btn-sm btn-secondary activeBtn mt-2">
                                <FontAwesomeIcon icon={faPaperclip} />
                                <span className="p-1">{fileLabel}</span>
                            </label>
                            <input
                                type="file"
                                name="tax_id_file"
                                id="emp_attachment"
                                onChange={handleFileUpload}
                                accept="image/*,.pdf,.txt,.xls,.xlsx,.doc,.docx,.ppt,.pptx"
                                className="d-none"
                            />
                            <div className="mt-1">
                                {attachmentFiles.length > 0 && (
                                    <span className="d-flex align-items-center p-1">
                                        <a
                                            href={util.getFileUrl(
                                                util.user.employer_id, 
                                                attachmentFiles[0].name
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-primary mr-2"
                                        >
                                            <span>{attachmentFiles[0].name}</span></a>
                                        <span
                                            className="p-1 text-danger cursor-pointer"
                                            onClick={handleRemoveAttachment}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                    </span>
                                )}
                            </div>
                        </Col>
                    </Row>}</div>}
            <div style={{ position: 'relative', minHeight: '100px' }}>
            
                <Row>
                    {employerId && util.arrangePermissionData()?.Employer?.includes( "Edit" ) ? (
                        <Col md={12} className="ml-4 mt-2">
                            <select
                                id="statusDropdown"
                                className="form-control mt-4"
                                style={{ width: "90%" }}
                                value={newState.tax_id_file ? newState.tax_id_feedback ?? "" : ""} // Ensure reset when file is missing
                                onChange={( e ) => {
                                    const selectedStatus = e.target.value;

                                    if ( newState.tax_id_file ) {
                                        setNewState( ( prevState ) => ( {
                                            ...prevState,
                                            tax_id_feedback: selectedStatus || null, // Set to null if empty
                                            tax_id_verified: selectedStatus === "Verified" ? 1 : 0,
                                        } ) );
                                    }
                                }}
                                // disabled={!newState.tax_id_file} // Disable dropdown when no file exists
                            >
                                <option value="">Choose an option</option>
                                <option value="Document Uploaded">📄 Document Uploaded</option>
                                <option value="Verification in Progress">🔄 Verification in Progress</option>
                                <option value="Verified">✅ Verified</option>
                                <option value="Verification Failed">❌ Verification Failed</option>
                            </select>
                        </Col>
                    ) : (
                        <>
                            {!isReadOnly && newState.tax_id_feedback && (
                                <div className="ml-5 mb-2 d-flex align-items-center" style={{ marginTop:'30px' }}>
                                    <span>
                                        {newState.tax_id_feedback === "Document Uploaded" && "📄"}
                                        {newState.tax_id_feedback === "Verification in Progress" && "🔄"}
                                        {newState.tax_id_feedback === "Verified" && "✅"}
                                        {newState.tax_id_feedback === "Verification Failed" && "❌"}
                                    </span>
                                    <span className="ms-2">{newState.tax_id_feedback}</span>
                                </div>
                            )}
                        </>
                    )}
                </Row>


            </div>

            



           
        </>
    );
};

export default CountryFillOne;
