import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import util from '../../utils/miniUtils';

const AddFeedbackModal = ( { id, pc_id,show, handleClose, initialFeedback, initialScore, onFeedbackSaved } ) => {
    const [data, setData] = useState( {
        score: initialScore || 0,
        feedback: initialFeedback || '',
        pc_id: pc_id
    } );

    console.log( id );

    useEffect( () => {
        if ( show ) {
            setData( {
                score: initialScore || 0,
                feedback: initialFeedback || '',
                pc_id: pc_id
            } );
        }
    }, [show, initialFeedback, initialScore] );

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        if ( id ) {
            await updateFeedback(); // Call update function if editing
        } else {
            await addFeedback(); // Call add function if adding
        }
        onFeedbackSaved();
        handleClose(); // Close the modal after submission
    };

    const addFeedback = async () => {
        try {

            const request = await util.axios.post( `/pc_feedbacks`, { ...data } );
            const { error, msg } = request.data;
            if ( error ) throw new Error( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const updateFeedback = async () => {
        try {
            const request = await util.axios.put( `/pc_feedbacks/${id}`, { ...data } );
            const { error, msg } = request.data;
            if ( error ) throw new Error( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    return (
        <Modal show={show} onHide={handleClose} enforceFocus={false} backdrop="static" keyboard={false} style={{ zIndex: 1350 }}>
            <Modal.Header closeButton>
                <Modal.Title>{id ? 'Edit Feedback' : 'Add Feedback'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formRating">
                        <Form.Label>Rating (0-10)</Form.Label>
                        <Form.Control
                            type="number"
                            min="0"
                            max="10"
                            value={data.score}
                            onChange={( e ) => setData( { ...data, score: Number( e.target.value ) } ) }
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formFeedback">
                        <Form.Label>Feedback</Form.Label>
                        <Form.Control
                            as="textarea"
                            type="text"
                            rows={3}
                            value={data.feedback}
                            onChange={( e ) => setData( { ...data, feedback: e.target.value } ) }
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddFeedbackModal;