import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Card, CardContent, Typography, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faEdit, faTrash, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import util from "../../utils/miniUtils";
import AddFeedbackModal from './AddFeedbackModal';
import Swal from 'sweetalert2';

const FeedbackViewer = ( { open, onClose, id } ) => {
    const [loading, setLoading] = useState( false );
    const [components, setComponents] = useState( [] );
    const [modalShow, setModalShow] = useState( false );
    const [selectedFeedback, setSelectedFeedback] = useState( {} );
    const getFeedbacks = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/pc_feedbacks/${id}` );
            const { error, data, msg } = request.data;
            if ( error ) throw msg;
            setComponents( data );
            setLoading( false );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };
    useEffect( () => {
        if ( id ) {
            getFeedbacks();
        }
    }, [id] );

    const handleEditClick = ( feedback ) => {
        setSelectedFeedback( feedback );
        console.log( feedback );
        setModalShow( true );
    };

    const handleAddFeedback = () => {
        setSelectedFeedback( null );
        setModalShow( true );
    };

    const handleClose = () => {
        setModalShow( false );
        onClose();
    };

    const handleDeleteClick = async ( feedbackId ) => {
        
        const result = await Swal.fire( {
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                popup: 'swal-popup',
                container: 'swal-container' 

            }
        } );

        if ( result.value ) {
            try {
                await util.axios.delete( `/pc_feedbacks/${feedbackId}` );
                setComponents( components.filter( component => component.id !== feedbackId ) );
                // Swal.fire( 'Deleted!', 'Your feedback has been deleted.', 'success', {
                //     customClass: {
                //         popup: 'swal-popup',
                //         container: 'swal-container' 

                //     }
                // } );
                getFeedbacks();
            } catch ( error ) {
                util.handleError( error );
            }
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={handleClose} disableEnforceFocus={true} disableBackdropClick={true} style={{ zIndex: 1300 }}>
            <div style={{ width: 400, overflowY: 'auto', maxHeight: '80vh' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                    <Button
                        className='btn btn-sm btn-success'
                        style={{ marginRight: '10px', backgroundColor: '#28a745', borderColor: '#28a745' }} 
                        onClick={handleAddFeedback} >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Button
                        className='btn btn-sm btn-danger'
                        style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }} 
                        onClick={handleClose}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    components.map( ( component ) => (
                        <Card key={component.id} style={{ margin: '10px', padding: '10px', position: 'relative' }}>
                            <div className="d-flex justify-content-end">
                                <Button
                                    onClick={() => handleEditClick( component )}
                                    className='btn btn-sm btn-success'
                                    style={{ marginRight: '10px', backgroundColor: '#28a745', borderColor: '#28a745' }} 
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <Button
                                    onClick={() => handleDeleteClick( component.id )}
                                    className='btn btn-sm btn-danger'
                                    style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }} 
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </div>
                            <CardContent>
                                <textarea
                                    readOnly
                                    value={component.feedback}
                                    style={{
                                        width: '100%',
                                        height: '100px', // Set a fixed height
                                        overflowY: 'auto', // Enable vertical scrolling
                                        border: 'none',
                                        resize: 'none', // Disable resizing
                                        backgroundColor: 'transparent', // Optional: match the card background
                                        fontSize: '16px', // Optional: adjust font size
                                    }}
                                /><Typography variant="body2">Created At: {component.createdAt}</Typography>
                                <Typography variant="body2">
                                    Created By: {component["creator.name"] ? component["creator.name"] : 'Unknown'}
                                </Typography>                                <Typography variant="body2">PC ID: {component.pc_id}</Typography>
                                <Typography variant="body2">Status: {component.status}</Typography>
                                <div>
                                    <Typography variant="body2">Score: {component.score}</Typography>
                                    {[...Array( component.score )].map( ( _, index ) => (
                                        <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'gold' }} />
                                    ) )}
                                </div>
                            </CardContent>
                        </Card>
                    ) )
                )}
            </div>
            <AddFeedbackModal
                id={selectedFeedback ? selectedFeedback.id : null}
                pc_id={id}
                show={modalShow}
                handleClose={() => setModalShow( false )}
                initialFeedback={selectedFeedback ? selectedFeedback.feedback : ''}
                initialScore={selectedFeedback ? selectedFeedback.score : 0}
                onFeedbackSaved={getFeedbacks}

            />
        </Drawer>
    );
};

export default FeedbackViewer;