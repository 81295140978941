import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Chip, MuiThemeProvider, Tooltip, Typography } from '@material-ui/core';
import SAlert from '../../microComponents/AlertSA';
import InviteModal from '../EmployerSettings/Subordinate/InviteModal';
import { Button, Form, Modal } from 'react-bootstrap';
import { InfoOutlined } from '@material-ui/icons';

const MemberList = ( { selectedEmployer: selectedEmployer } ) => {
    console.log( selectedEmployer );
   
    const [members, setMembers] = useState( [] );
    const [showDeleteModal, setShowDeleteModal] = useState( false );
    const [memberToDelete, setMemberToDelete] = useState( null );
    const [showModal, setShowModal] = useState( false );
    const [emails, setEmails] = useState( '' );
    const [error, setError] = useState( '' );
    const [accountSettings, setAccountSettings] = useState( {} );
    const [memberAlert,setMemberAlert]=useState( false );
           
    const hideAlertLimitOne =()=>{
        setMemberAlert( false );
    };
     
    
    const [showRoleModal, setShowRoleModal] = useState( false ); 
    const [roleToUpdate, setRoleToUpdate] = useState( null ); 
    const [selectedRole, setSelectedRole] = useState( "" ); 
    const roleOptions = [
        { id: 17, name: "Employer Manager" },
        { id: 18, name: "Employer " }
    ];
    useEffect( () => {
        if ( selectedEmployer ) {
            getEmployerMembers( selectedEmployer.employer_id );
        }
    }, [selectedEmployer] );
    useEffect( () => {
        if ( selectedEmployer ) {
            fetchSettings( selectedEmployer.employer_id );
        }
    }, [selectedEmployer] );

    const getEmployerMembers = async ( id ) => {
        try {
            const request = await util.axios.get( `/employers_subordinates/get_members/${id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setMembers( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const fetchSettings = async ( id ) => {
        try {
            const response = await util.axios.get( `/employers/get_account_settings/${id}` );
            const { error, data } = response.data;
            if ( error ) {
                throw new Error( "Failed to fetch account settings" );
            }
            setAccountSettings( data ); // Set account settings
            console.log( accountSettings,"hello" );
        } catch ( err ) {
            console.error( err );
        }
    };

    const handleDeleteClick = ( member ) => {
        setMemberToDelete( member );
        setShowDeleteModal( true );
    };

    const handleDeleteMember = async () => {
        try {
            const response = await util.axios.delete( `/employer_invitation/${memberToDelete.id}`, {
                params: {
                    email: encodeURIComponent( memberToDelete.email ),
                    employer_id: selectedEmployer.employer_id, // Pass employer_id explicitly
                },
            } );
            const { error, msg } = response.data;
            if ( error ) throw msg;
            getEmployerMembers( selectedEmployer.employer_id );
            setShowDeleteModal( false );
            setMemberToDelete( null );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleModalClose = () => {
        setShowModal( false );
        setEmails( '' );
        setError( '' );
    };

    const handleAddMembers = async () => {
        const emailArray = emails.split( ',' ).map( email => email.trim() );
        const employerDomain = selectedEmployer.employer_email.split( '@' )[1];
        if ( emailArray.length + ( accountSettings.employee_count || 0 ) > ( accountSettings.employee_limit || 0 ) ) {
            setError( `You've already invited ${accountSettings.employee_count || 0} members. Your limit is ${accountSettings.employee_limit}. You can invite ${( accountSettings.employee_limit || 0 ) - ( accountSettings.employee_count || 0 )} more.` );
            return;
        }
        if ( emailArray.length > 5 ) {
            setError( 'You can only add up to 5 emails at a time.' );
            return;
        }

        for ( const email of emailArray ) {
            if ( !/\S+@\S+\.\S+/.test( email ) ) {
                setError( `Invalid email format: ${email}` );
                return;
            }

            const emailDomain = email.split( '@' )[1];
            if ( emailDomain !== employerDomain ) {
                setError( `Please use your official company email (${employerDomain}).` );
                return;
            }

            if ( members.some( member => member.email.toLowerCase() === email.toLowerCase() ) ) {
                setError( `This email is already in use: ${email}` );
                return;
            }
        }

        const userEmails = emailArray.map( email => ( { email } ) );

        try {
            const response = await util.axios.post( '/employer_invitation/sendInvite', {
                employer_id: selectedEmployer.employer_id,
                user_email: userEmails,
            } );
            const { error, msg } = response.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            getEmployerMembers( selectedEmployer.employer_id );
            handleModalClose();
        } catch ( error ) {
            setError( 'An error occurred while adding members.' );
            util.handleError( error );
        }
    };
    const handleRoleUpdate = async () => {
        try {
            const response = await util.axios.put( `/employer_invitation/${roleToUpdate.id}`, {
                role_id: selectedRole,
            } );

            const { error, msg } = response.data;
            if ( error ) throw msg;

            getEmployerMembers( selectedEmployer.employer_id );
            setShowRoleModal( false );
            setRoleToUpdate( null );
            util.toast().success( msg );
        } catch ( error ) {
            setError( "An error occurred while updating the role." );
            console.error( error );
            util.handleError( error );
        }
    };

    const handleRoleUpdateClick = ( member ) => {
        setRoleToUpdate( member );
        const matchingRole = roleOptions.find( role => role.name.trim() === member.roles.trim() );
        setSelectedRole( matchingRole ? matchingRole.id : '' );
        setShowRoleModal( true );
    };  

    if( !selectedEmployer ) {
        return "";
    }
    const columns = [
        {
            title: "Name",
            field: "name",
            render: ( rowData ) => (
                <span
                    style={{ cursor: "pointer", color:  "#007bff" }}
                    onClick={() =>  handleRoleUpdateClick( rowData )}
                >
                    {rowData.name}
                </span>
            ),
        },
        {
            title: "Email",
            field: "email",
            render: ( rowData ) => (
                <a href={`mailto:${rowData.email}`} style={{ color: "blue" }}>
                    {rowData.email}
                </a>
            ),
        },
        {
            title: "Status",
            field: "status",
            render: ( rowData ) => (
                <Chip
                    size="small"
                    label={rowData.status === 0 ? "Invited" : "Accepted"}
                    className={`text-white ${rowData.status === 0 ? "bg-primary" : "bg-success"}`}
                    style={{ minWidth: "80px", textAlign: "center" }}
                />
            ),
        },
        {
            title: "Role",
            field: "role",
            render: ( rowData ) => <div>{rowData.roles}</div>,
        },
        {
            title: "Actions",
            sorting: false,
            render: ( rowData ) => (
                <div style={{ display: "flex", gap: "10px" }}>
                  
                    <FontAwesomeIcon
                        icon={faTrash}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteClick( rowData )}
                    />
                </div>
            ),
        },
    ];
    const employerCount = accountSettings?.employee_count || 0;
    const employerLimit = accountSettings?.employee_limit || 0;
    const employersRemaining = employerLimit > employerCount ? employerLimit - employerCount : 0;
    const isLimitReached = employersRemaining === 0;
    
    const tableTitle = (
        <Box display="flex" alignItems="center" gap={2} sx={{ p: 1, backgroundColor: "#ffff", borderRadius: "8px" }}>
            
    
            {/* Remaining Employers Display */}
            <Chip
                label={
                    isLimitReached 
                        ? "Member Limit Reached" 
                        : `You can invite ${employersRemaining} more member${employersRemaining === 1 ? "" : "s"}`
                }
                sx={{
                    backgroundColor: isLimitReached ? "#d32f2f" : "#388e3c", 
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "14px",
                    padding: "5px 10px",
                    borderRadius: "6px",
                }}
            />
    
            {/* Info Tooltip */}
            <Tooltip
                title={
                    <Typography variant="body2" textAlign="center">
                        You have invited <strong>{employerCount}</strong> members out of your limit of <strong>{employerLimit}</strong>.
                        {isLimitReached && (
                            <Box mt={1}>
                           Member invitation limit reached
                            </Box>
                        )}
                    </Typography>
                }
                placement="top"
            >
                <InfoOutlined sx={{ color: "#1976d2", cursor: "pointer" }} />
            </Tooltip>
        </Box>
    );

    return (
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={columns}
                    title={tableTitle}
                    data={members}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        sorting: true,
                        search: true,
                        // exportButton: true,
                        selection: true,
                        headerStyle: { backgroundColor: "#f5f5f5", fontWeight: "bold" },
                        maxBodyHeight: "60vh",
                    }}
                    actions={[
                                           
                        {
                            icon: () => (
                                <div className="btn btn-secondary btn-sm activeBtn sizeBtn">
                                    <FontAwesomeIcon icon={faPlusCircle} /> Invite Member
                                </div>
                            ),
                            tooltip: "Invite Members",
                            isFreeAction: true,
                            onClick: () => {
                                       

                                if ( accountSettings.employee_count + 1 > accountSettings.employee_limit ) {
                                    setMemberAlert( true ); 
                                } else {
                                    setShowModal( true );
                                }
                            }
                        },
                                                
                                       
                    ] }
                   
                />
            </MuiThemeProvider>
            {showModal &&  <InviteModal
                showModal={showModal}
                handleAddMembers={handleAddMembers}
                handleModalClose={handleModalClose}
                emails={emails}
                setEmails={setEmails}
                error={error}
            />}
            {memberAlert && (
                <SAlert
                    show={memberAlert}
                    title="Limit Exceeded"
                    msg="Your member invitation limit has been reached."
                    confirmText="OK"
                    typeButton="danger"
                    onConfirm={() => setMemberAlert( false )}
                    hideAlert={hideAlertLimitOne}
                />
            )}
            
           


            {showDeleteModal && (
                <SAlert
                    show={showDeleteModal}
                    msg={`Are you sure you want to delete this member: ${memberToDelete.email}?`}
                    hideAlert={() => setShowDeleteModal( false )}
                    onConfirm={handleDeleteMember}
                />
            )}
            {/* Role Update Modal */}
            <Modal show={showRoleModal} onHide={() => setShowRoleModal( false )}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="memberEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={roleToUpdate?.email || ""}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="roleSelect">
                            <Form.Label>Select Role</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedRole}
                                onChange={( e ) => setSelectedRole( parseInt( e.target.value ) )}
                            >
                                <option value="">Select Role</option>
                                {roleOptions.map( ( role ) => (
                                    <option key={role.id} value={role.id}>
                                        {role.name}
                                    </option>
                                ) )}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRoleModal( false )}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleRoleUpdate}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MemberList;

