import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import util from '../utils/miniUtils';

const AddPaymentSchedule = ( { showModal, handleModalClose, handleAddPayment, error, paymentDetail, handleChange, isEditing,handleUpdatePayment, memberName, setPaymentDetail,paymentItems, setPaymentItems } ) => {
    // console.log( util.user.email );
    // const [paymentItems, setPaymentItems] = useState( [] );
    const isReadOnly = util.user.role !== 6 ;
    const [paymentDescription, setPaymentDescription] = useState( "" );
    const fetchPaymentItems = async () => {
        try {
                
            const response = await util.axios.get( `/elitepaymentitem/` );
            const { error, data } = response.data;
    
            if ( error ) {
                throw new Error( "Failed to fetch payment items data" );
            }
            
            setPaymentItems( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    useEffect( () => {
        fetchPaymentItems();
    },[] );
    const handlePaymentChange = ( e ) => {
        const selectedItem = paymentItems.find( item => item.id == e.target.value );
        console.log( selectedItem );
        setPaymentDetail( {
            ...paymentDetail,
            payment_item_id: e.target.value,
            description: selectedItem ? selectedItem.description : ""
        } );
    };
    
    return (
        <div>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? "Update Payment Schedule" : "Add Payment Schedule"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Member Name</Form.Label>
                            <Form.Control
                                as='select'
                                value={paymentDetail?.account_id || ""}
                                onChange={handleChange}
                                name='account_id'
                                readOnly={isReadOnly}
                            >
                                <option>Select Member</option>
                                {memberName.map( ( item ) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ) )}
                            </Form.Control>

                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <Form.Label>Payment Item Name</Form.Label>
                            <Form.Control
                                as="select"
                                value={paymentDetail?.payment_item_id || ""}
                                onChange={handlePaymentChange}
                                name='payment_item_id'
                                readOnly={isReadOnly}

                            >
                                <option>Select Payment Item</option>
                                {paymentItems.map( ( item ) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ) )}
                            </Form.Control>

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Payment Item Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                type="text"
                                placeholder="Enter Payment Item Description"
                                value={paymentDetail?.description || paymentDescription}
                                // onChange={handleChange}
                                name='description'
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Value</Form.Label>
                            <Form.Control
                                as="input" 
                                type="text"
                                placeholder="Value"
                                value={paymentDetail.value}
                                onChange={handleChange}
                                name='value'
                                readOnly={isReadOnly}

                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                type="text"
                                placeholder="Comment"
                                value={paymentDetail.comment}
                                onChange={handleChange}
                                name='comment'
                                readOnly={isReadOnly}

                            />
                        </Form.Group>
                        {error && <p className="text-danger">{error}</p>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-secondary activeBtn sizeBtn" onClick={handleModalClose}>
                        Cancel
                    </button>
                    {isEditing ? 
                    
                    
                        ( util.user.role ===6 && <button className="btn btn-sm btn-secondary activeBtn " onClick={handleUpdatePayment}>
                        Update Payment Schedule
                        </button> ):
                        <button className="btn btn-sm btn-secondary activeBtn " onClick={handleAddPayment}>
                        Add Payment Schedule
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    );
};



export default AddPaymentSchedule;
