import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col,  Card } from "react-bootstrap";
import util from "../utils/miniUtils";
import { useLocation } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import { CircularProgress } from "@material-ui/core";
import { faGlasses } from "@fortawesome/free-solid-svg-icons";

const EliteInfo = (  ) => {
    
    const [eliteConnect, setEliteConnect] = useState( null );
    const [saving, setSaving] = useState( false );
    const [eliteParentList, setEliteParentList] = useState( [] );
    const [eliteAdminList, setEliteAdminList] = useState( [] );
    const [loading, setLoading] = useState( true );
    const [error, setError] = useState( null );
    const [country, setCountry] = useState( [] );
    const location = useLocation();
    const eliteData = location.state?.eliteData;
    const isReadOnly = util.user.role === 20;
    const isReadByManager = util.user.role ===20 || util.user.role === 19;
    const [showModal, setShowModal] = useState( false );
    const badgeTypes = [
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'danger',
        'warning',
        'danger',
        'success',
        'danger'
    ];
    const badgeTexts = [
        'Active',
        'Inactive',
        'Prospective',
        'Onboarding',
        'Suspended',
        'Closed',
        'At Risk',
        'Churned',
        'Renewed',
        'Blacklisted'
    ];

    
    // const fetchEliteParent = async () => {
    //     try {
    //         const response = await util.axios.get( `/eliteconnect/` );
    //         const { error, data } = response.data;
    
    //         if ( error ) {
    //             throw new Error( "Failed to fetch elite connect data" );
    //         }
                
    //         setEliteParentList( data );
    //     } catch ( err ) {
    //         console.error( err );
    //     }
    // };
    // const fetchEliteConnectAdmin = async () => {
    //     try {
    //         const response = await util.axios.get( `/eliteconnectadminlist/` );
    //         const { error, data } = response.data;
    
    //         if ( error ) {
    //             throw new Error( "Failed to fetch elite admin" );
    //         }
                
    //         setEliteAdminList( data );
    //     } catch ( error ) {
    //         util.handleError( error );

    //     }
    // };
    // const fetchCountry = async () => {
    //     try {
            
    //         const request = await util.axios.get( `/generic_data/list_of_countries/` );
    //         const { error, msg, data } = request.data;
            
    //         setCountry( data );
    //         if ( error ) throw new Error( msg );

    //     } catch ( error ) {
    //         util.handleError( error );
    //         setError( "Failed to fetch countries details." );
    //     } finally {
    //         setLoading( false );
    //     }
    // };
    // const fetchEliteConnectDetail = async () => {
    //     try {
            
    //         const elite_connect_id = util.user.elite_connect_id || eliteData?.elite_connect_id;
            
    //         setLoading( true );
    //         const request = await util.axios.get( `/eliteconnect/${elite_connect_id}` );
    //         const { error, msg, data } = request.data;
    //         if ( error ) throw new Error( msg );

    //         setEliteConnect( data );
    //         // util.toast().success( msg || "Employer details fetched successfully" );
    //     } catch ( error ) {
    //         util.handleError( error );
    //         setError( "Failed to fetch elite connect details." );
    //     } finally {
    //         setLoading( false );
    //     }
    // };
    const fetchAllData = async() => {
        try{
            setLoading( true );
            const elite_connect_id = util.user.elite_connect_id || eliteData?.elite_connect_id;
            const [ eliteConnectRes, countryRes, eliteAdminRes, eliteParentRes,] = 
            await Promise.all( [
                util.axios.get( `/eliteconnect/${elite_connect_id}` ),
                util.axios.get( `/generic_data/list_of_countries/` ),
                util.axios.get( `/eliteconnectadminlist/` ),
                util.axios.get( `/eliteconnect/` ),
            ] );

            const [countryData, eliteAdminData,  parentData, detailData ] = [ countryRes.data, eliteAdminRes.data, eliteParentRes.data, eliteConnectRes.data ];
            
            if( countryData.error ) throw new Error( countryData.msg );
            if( eliteAdminData.error ) throw new Error( eliteAdminData.msg );
            if( detailData.error ) throw new Error( detailData.msg );
            if( parentData.error ) throw new Error( parentData.msg );
            setEliteConnect( detailData.data );
            setCountry( countryData.data );
            setEliteAdminList( eliteAdminData.data );
            setEliteParentList( parentData.data );
        }catch ( error ) {
            util.handleError( error );
            setError( "Failed to fetch elite connect details." );
        } finally {
            setLoading( false );
        }
    };
    useEffect( () => {
        // fetchCountry();
        // fetchEliteConnectAdmin();
        // fetchEliteConnectDetail();
        // fetchEliteParent();
        fetchAllData();
    },[] );
    
    const getBadgeText = ( number ) => {
        // Check if the input is "Active"
        const isActive = number?.toLowerCase() === 'active';
    
        // Filter the badgeTexts array based on the condition
        return badgeTexts.filter( text => {
            const lowercaseText = text.toLowerCase();
            const lowercaseNumber = number?.toLowerCase();
    
            // If the input is "Active", exclude "Inactive" from the result
            if ( isActive && lowercaseText.includes( 'inactive' ) ) {
                return false;
            }
    
            // Otherwise, include text that matches the input number
            return lowercaseText.includes( lowercaseNumber );
        } );
    };
    const getBadgeMarkup = ( badgeNumber ) => {
        // Determine the badge type based on the integer using modulus to cycle through array
        const index = badgeTexts.findIndex( text => text.toLowerCase().includes( badgeNumber?.toLowerCase() ) );

        const badgeType = badgeTypes[index];
      
        return (
            <div className="d-flex justify-content-end ">
                <span className={`badge badge-${badgeType}`}>
                    {getBadgeText( badgeNumber )}
                </span>
            </div>
        );
    };
    const handleChange = ( e ) => {
        const { name, value } = e.target;
        
        setEliteConnect( ( prevState ) => {
            const updateEliteConnect = { ...prevState, [name]: value };
            
            return updateEliteConnect;
        } );
    };
    const handleSaveClick = async () => {
        try {
            setSaving( true );
            const elite_connect_id = util.user.elite_connect_id || eliteData?.elite_connect_id;
            const request = await util.axios.put( `eliteconnect/${elite_connect_id}`, eliteConnect );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setSaving( false );  
        }catch ( error ) {
            util.handleError( error );
        }
    };
 
    const handleShowModal = () => setShowModal( true );
    
    const handlePhoneChange = ( value ) => {
        setEliteConnect( ( prev ) => ( { ...prev, elite_phone: value } ) );
    };
    
    if ( loading ) return util.LOADING_SCREEN( loading );
        
    // if ( saving ) return <CircularProgress />;

    if ( error ) {
        return <div>{error}</div>;
    }

    if ( !eliteConnect ) {
        return <div>No Elite Connect Found</div>;
    }

    return (
        <Container className="mt-3">
            <Card>
                <Card.Header as="h5">
                    <div className="d-flex justify-content-between align-items-center">
                        <span>{eliteConnect?.elite_name}'s Details</span>
                        <span>{getBadgeMarkup( eliteConnect.elite_status )}</span>
                    </div>
                </Card.Header>
                <Card.Body>
                    {/* <Card.Header className="text-center mb-4">Elite Connect Details</Card.Header> */}
                    {saving ? <CircularProgress /> :
                        <Form>
                            <Row className="mb-3">
                            
                                <Col md={6}>
                                    <Form.Group controlId="formCompanyEmail">
                                        <Form.Label>Elite Connect Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="elite_email"
                                            value={eliteConnect.elite_email || ""}
                                            placeholder="Enter Your Email"
                                            onChange={handleChange}
                                            readOnly={isReadOnly} 
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formEliteName">
                                        <Form.Label>Elite Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="elite_name"
                                            value={eliteConnect.elite_name || ""}
                                            placeholder="Enter elite name"
                                            onChange={handleChange}
                                            readOnly={isReadOnly} 
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="formCompanyWebsite">
                                        <Form.Label>Elite Connect Website</Form.Label>
                                        <Form.Control
                                            type="url"
                                            name="eliteConnect_website"
                                            value={eliteConnect.eliteConnect_website || ""}
                                            placeholder="https://www.yourcompany.com"
                                            onChange={handleChange}
                                            readOnly={isReadOnly} 
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formHQAddress">
                                        <Form.Label>Elite HQ Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="address"
                                            value={eliteConnect.address || ""}
                                            placeholder="Enter the main office address (e.g. 123 Main St)"
                                            onChange={handleChange}
                                            readOnly={isReadOnly} 
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="formYearFounded">
                                        <Form.Label>Year Founded</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="elite_founded_year"
                                            value={eliteConnect.elite_founded_year || ""}
                                            placeholder="Enter the year your company was established"
                                            onChange={handleChange}
                                            readOnly={isReadOnly} 
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formEmailDomain">
                                        <Form.Label>Email Domain</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="email_domain"
                                            value={eliteConnect.elite_email_domain || ""}
                                            placeholder="Enter your email domain (e.g. abc.co)"
                                            onChange={handleChange}
                                            readOnly={isReadOnly} 
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="formStatus">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="elite_status"
                                            value={eliteConnect.elite_status || ""}
                                            onChange={handleChange}
                                            readOnly={isReadByManager}
                                            disabled={isReadByManager}

                                        >
                                            <option value="">Select Status</option>
                                            <option value="active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formCountry">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="country_code"
                                            value={eliteConnect.country_code || ""}
                                            onChange={handleChange}
                                            readOnly={isReadOnly}
                                        >
                                        
                                            <optgroup label="All Countries">
                                            
                                                {country?.map( ( item ) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                } )}
                                            </optgroup>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* <Row className="mb-3"> */}
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="formYearFounded">
                                        <Form.Label>eJ Partner In Charge</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="elite_ej_partner_in_charge"
                                            value={eliteConnect.elite_ej_partner_in_charge || ""}
                                            placeholder="eJ Partner In Charge"
                                            onChange={handleChange}
                                            readOnly={isReadByManager} 
                                            disabled={isReadByManager}
                                        >
                                            <option value="">Select eJ Partner In Charge</option>
                                            {eliteAdminList.map( ( item ) => (
                                                <option key={item.elite_ej_partner_in_charge} value={item.elite_ej_partner_in_charge}>{item.name}</option>
                                            ) )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            
                            
                                <Col md={6}>
                                    <Form.Group controlId="formCompanyPhone">
                                        <Form.Label>Elite Connect Phone</Form.Label>
                              
                                        <PhoneInput
                                            country={"us"}
                                            name="elite_phone"
                                            value={eliteConnect.elite_phone ||""}
                                            // placeholder="+123 456 7890"
                                            onChange={handlePhoneChange}
                                            disabled={isReadOnly}
                                            specialLabel=""
                                            placeholder="Enter Phone Number"
                                            inputProps={{ name: "phone", required: true }}
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>   
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="formEliteParent">
                                        <Form.Label>Elite Parent</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            name="elite_parent"
                                            placeholder="Select Elite Parent"
                                            value={eliteConnect.elite_parent || ""}
                                            onChange={handleChange}
                                            readOnly={isReadByManager}
                                            disabled={isReadByManager}

                                        >
                                            <option value="">Select Elite Parent</option>
                                            {eliteParentList.map( ( item ) => (
                                                <option key={item.elite_connect_id} value={item.elite_connect_id}>{item.elite_name}</option>
                                            ) )}
                                        </Form.Control>
                                    
                                    </Form.Group>
                                </Col>

                            </Row>
                            
                            {/* </Row> */}

                            {!isReadOnly && (
                                <div className="float-right"> 
                                    <button 
                                        type="button" 
                                        className="btn btn-sm btn-secondary activeBtn sizeBtn" 
                                        onClick={handleSaveClick}
                                        disabled={loading} 
                                    > 
                                        {loading ? "Saving..." : "Save"} 
                                    </button> 
                                </div> 
                            )} 
                        </Form>}
                </Card.Body>
                
            </Card>

            
        </Container>
    );
};

export default EliteInfo;
