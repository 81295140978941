import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Box, Chip, MuiThemeProvider, Tooltip, Typography } from "@material-ui/core";
import { Modal, Button, Form } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import InviteModal from "./InviteModal";
import SAlert from "../../../microComponents/AlertSA";
import { InfoOutlined } from "@material-ui/icons";

const Subordinate = () => {
    // console.log( util.user );
    const [members, setMembers] = useState( [] );
    const [showModal, setShowModal] = useState( false );
    const [emails, setEmails] = useState( "" );
    const [error, setError] = useState( "" );
    const [showDeleteModal, setShowDeleteModal] = useState( false );
    const [memberToDelete, setMemberToDelete] = useState( null );
    const [showRoleModal, setShowRoleModal] = useState( false ); 
    const [roleToUpdate, setRoleToUpdate] = useState( null ); 
    const [selectedRole, setSelectedRole] = useState( "" ); 
    const [accountSettings, setAccountSettings] = useState( {} ); 
    const [memberAlert,setMemberAlert]=useState( false );
    const isEmployer=util.user.role===18;
    const hideAlertLimitOne =()=>{
        setMemberAlert( false );
    };

    // Fetch members data
    const fetchMembers = async () => {
        try {
            const employerId = util.user.employer_id;
            const response = await util.axios.get( `/employers_subordinates/get_members/${employerId}` );
            const { error, data } = response.data;

            if ( error ) {
                throw new Error( "Failed to fetch subordinate data" );
            }

            setMembers( data );
        } catch ( err ) {
            setError( "An error occurred while fetching members." );
            console.error( err );
        }
    };

    const fetchSettings = async () => {
        try {
            const employerId = util.user.employer_id;
            const response = await util.axios.get( `/employers/get_account_settings/${employerId}` );
            const { error, data } = response.data;
            if ( error ) {
                throw new Error( "Failed to fetch account settings" );
            }
            setAccountSettings( data ); // Set account settings
        } catch ( err ) {
            console.error( err );
        }
    };

    useEffect( () => {
        fetchMembers();
        fetchSettings(); // Call fetchSettings to get account settings
    }, [] );

    const handleModalClose = () => {
        setShowModal( false );
        setEmails( "" );
        setError( "" );
    };

    const handleAddMembers = async () => {
        const emailArray = emails.split( "," ).map( ( email ) => email.trim() );
        const employerDomain = util.user.email.split( "@" )[1]; 
        
        if ( emailArray.length + ( accountSettings.employee_count || 0 ) > ( accountSettings.employee_limit || 0 ) ) {
            setError( `You've already invited ${accountSettings.employee_count || 0} members. Your limit is ${accountSettings.employee_limit}. You can invite ${( accountSettings.employee_limit || 0 ) - ( accountSettings.employee_count || 0 )} more.` );
            return;
        }
        if ( emailArray.length > 5 ) {
            setError( "You can only add up to 5 emails at a time." );
            return;
        }

        for ( const email of emailArray ) {
            if ( !/\S+@\S+\.\S+/.test( email ) ) {
                setError( `Invalid email format: ${email}` );
                return;
            }

            const emailDomain = email.split( "@" )[1];
            if ( emailDomain !== employerDomain ) {
                setError( `Please use your official company email (${employerDomain}).` );
                return;
            }

            if ( members.some( ( member ) => member.email.toLowerCase() === email.toLowerCase() ) ) {
                setError( `This email is already in use: ${email}` );
                return;
            }
        }

        const userEmails = emailArray.map( ( email ) => ( { email } ) );

        try {
            const response = await util.axios.post( '/employer_invitation/sendInvite', {
                employer_id: util.user.employer_id,
                user_email: userEmails,
            } );

            const { error, msg } = response.data;

            if ( error ) throw new Error( msg );

            util.toast().success( msg );
            fetchMembers();
            handleModalClose();
        } catch ( error ) {
            setError( "An error occurred while adding members." );
            console.error( error );
            util.handleError( error );
        }
    };

    const handleDeleteClick = ( member ) => {

        if ( util.user.role !== 18 ) {
            setMemberToDelete( member );
            setShowDeleteModal( true );
        }
    };
    

    const handleDeleteMember = async () => {
        try {
            const response = await util.axios.delete( `/employer_invitation/${memberToDelete.id}?email=${encodeURIComponent( memberToDelete.email )}` );
            const { error, msg } = response.data;

            if ( error ) throw msg;

            fetchMembers();
            setShowDeleteModal( false );
            setMemberToDelete( null );

            util.toast().success( msg );
        } catch ( error ) {
            setError( "An error occurred while deleting the member." );
            console.error( error );
            util.handleError( error );
        }
    };
    const roleOptions = [
        { id: 17, name: "Employer Manager" },
        { id: 18, name: "Employer " }
    ];

    // New function to open the role update modal
    const handleRoleUpdateClick = ( member ) => {
        if ( util.user.role !== 18 ) {
            setRoleToUpdate( member );
    
            // Map role name to ID and populate dropdown
            const matchingRole = roleOptions.find( role => role.name.trim() === member.roles.trim() );
            setSelectedRole( matchingRole ? matchingRole.id : "" );
    
            setShowRoleModal( true );
        }
    };
    
    

    // Function to handle role update
    const handleRoleUpdate = async () => {
        try {
            const response = await util.axios.put( `/employer_invitation/${roleToUpdate.id}`, {
                role_id: selectedRole, 
            } );
    
            const { error, msg } = response.data;
    
            if ( error ) throw msg;
    
            
            fetchMembers(); 
    
            setShowRoleModal( false ); 
            setRoleToUpdate( null ); 
            util.toast().success( msg ); 
        } catch ( error ) {
            setError( "An error occurred while updating the role." ); 
            console.error( error );
            util.handleError( error );
        }
    };
    

    const columns = [
        {
            title: "Name",
            field: "name",
            render: ( rowData ) => (
                <span
                    style={{ cursor: "pointer", color:  "#007bff" }}
                    onClick={() =>  handleRoleUpdateClick( rowData )}
                >
                    {rowData.name}
                </span>
            ),
        },
        {
            title: "Email",
            field: "email",
        },
        {
            title: "Status",
            field: "status",
            render: ( rowData ) => (
                <Chip
                    size="small"
                    label={rowData.status === 0 ? "Invited" : "Accepted"}
                    className={`text-white ${rowData.status === 0 ? "bg-primary" : "bg-success"}`}
                    style={{ minWidth: "80px", textAlign: "center" }}
                />
            ),
        },
        {
            title: "Roles",
            field: "roles",
        },
        {
            title: "Actions",
            sorting: false,
            hidden: util.user.role === 18,
            render: ( rowData ) =>
                util.user.email !== rowData.email ? ( 
                    <div style={{ display: "flex", gap: "10px" }}>
                        <FontAwesomeIcon
                            icon={faTrash}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteClick( rowData )}
                        />
                    </div>
                ) : null, 
        },
    ];
    // const tableTitle = `Employer's Members List (${accountSettings.employee_count || 0} / ${accountSettings.employee_limit || 0})`;
    const employerCount = accountSettings?.employee_count || 0;
    const employerLimit = accountSettings?.employee_limit || 0;
    const employersRemaining = employerLimit > employerCount ? employerLimit - employerCount : 0;
    const isLimitReached = employersRemaining === 0;
    
    const tableTitle = (
        <Box display="flex" alignItems="center" gap={2} sx={{ p: 1, backgroundColor: "#ffff", borderRadius: "8px" }}>
            
    
            {/* Remaining Employers Display */}
            <Chip
                label={
                    isLimitReached 
                        ? "Member Limit Reached" 
                        : `You can invite ${employersRemaining} more member${employersRemaining === 1 ? "" : "s"}`
                }
                sx={{
                    backgroundColor: isLimitReached ? "#d32f2f" : "#388e3c", 
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "14px",
                    padding: "5px 10px",
                    borderRadius: "6px",
                }}
            />
    
            {/* Info Tooltip */}
            <Tooltip
                title={
                    <Typography variant="body2" textAlign="center">
                        You have invited <strong>{employerCount}</strong> members out of your limit of <strong>{employerLimit}</strong>.
                        {isLimitReached && (
                            <Box mt={1}>
                                To invite more members, contact us at +1 (800) 985-7240.
                            </Box>
                        )}
                    </Typography>
                }
                placement="top"
            >
                <InfoOutlined sx={{ color: "#1976d2", cursor: "pointer" }} />
            </Tooltip>
        </Box>
    );
    

    return (
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={columns}
                    title={tableTitle} // Use the updated title
                    data={members}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        sorting: true,
                        search: true,
                        showTitle: isEmployer  ? false : true,
                        // exportButton: true,
                        selection: true,
                        headerStyle: { backgroundColor: "#f5f5f5", fontWeight: "bold" },
                        maxBodyHeight: "60vh",
                    }}
                    actions={
                        util.user.role !== 18
                            ? [
                                {
                                    icon: () => (
                                        <div className="btn btn-secondary btn-sm activeBtn sizeBtn">
                                            <FontAwesomeIcon icon={faPlusCircle} /> Invite Member
                                        </div>
                                    ),
                                    tooltip: "Invite Members",
                                    isFreeAction: true,
                                    // onClick: () => setShowModal( true ),
                                    onClick: () => {
                                       

                                        if ( accountSettings.employee_count + 1 > accountSettings.employee_limit ) {
                                            setMemberAlert( true ); 
                                        } else {
                                            setShowModal( true );
                                        }
                                    }
                                },
                            ]
                            : ""
                    }
                />
            </MuiThemeProvider>

            <InviteModal
                showModal={showModal}
                handleAddMembers={handleAddMembers}
                handleModalClose={handleModalClose}
                emails={emails}
                setEmails={setEmails}
                error={error}
            />

            {showDeleteModal && (
                <SAlert
                    show={showDeleteModal}
                    msg={`Are you sure you want to delete this member: ${memberToDelete.email}?`}
                    hideAlert={() => setShowDeleteModal( false )}
                    onConfirm={handleDeleteMember}
                />
            )}
            {memberAlert && (
                <SAlert
                    show={memberAlert}
                    title="Limit Exceeded"
                    msg="Your member invitation limit has been reached. To increase it, contact us at +1 (800) 985-7240."
                    confirmText="OK"
                    typeButton="danger"
                    onConfirm={() => setMemberAlert( false )}
                    hideAlert={hideAlertLimitOne}
                />
            )}

            {/* Role Update Modal */}
            <Modal show={showRoleModal} onHide={() => setShowRoleModal( false )}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="memberEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={roleToUpdate?.email || ""}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="roleSelect">
                            <Form.Label>Select Role</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedRole}
                                onChange={( e ) => setSelectedRole( parseInt( e.target.value ) )}
                            >
                                <option value="">Select Role</option>
                                {roleOptions.map( ( role ) => (
                                    <option key={role.id} value={role.id}>
                                        {role.name}
                                    </option>
                                ) )}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRoleModal( false )}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleRoleUpdate}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Subordinate;
