import { faTrash, faPlus, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import util from "../../utils/miniUtils";
import AsseMakesModel from "./AssetsMakeModel";
import { Modal } from "react-bootstrap";

const AssetTypeMakes = ( { getAssignedMakes,makeList, selectedType ,setState,state } ) => {
    const [newMake, setNewMake] = useState( "" );
    const [allmodelList, setAllModelList] = useState( [] );
    const [formData, setFormData] = useState( {} );
    // Add a new Make
    useEffect( () => {
        if ( makeList?.length ) {
               
            getAllModel();
        }
    }, [state.makeId] );
    
    const addTypeMake = async () => {
        if ( !newMake.trim() || !selectedType ) return;
        try {
            await util.axios.post( "/amtypemakes", { type_id: selectedType.id, name: newMake } );
            setNewMake( "" ); // Reset input field

            util.toast().success( "Make added successfully" );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const [modelList, setModelList] = useState( [] );

    const getAllModel = async () => {
        try {
            const request = await util.axios.get( "/glmodel" );
            const { msg, error, data } = request.data;
            if( error ) throw msg;
            setAllModelList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const getAssignedModel = async ( id, name ) => {
        try {
            const request = await util.axios.get( `/glmodelByMake/${id}` );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;
    
            setModelList( data );
            
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const handleChange = ( e ) => {
        const { name, value } = e.target;
        if( value === "notOnList" ){
            setState( { ...state, addModel: true } );
        } else {
            setFormData( {
                [name]: value
            } );
        }
    };
    const saveMakeModel = async () => {
        let newType = {
            mk_id: state.makeIdId ,
            model_name: formData.status,
                   
        };
        try {
            const request = await util.axios.post( "/glmodel", { newType } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getAssignedMakes( state.typeId );
                 
            util.toast().success( msg );
               
        } catch ( error ) {
            util.handleError( error );
        }
    };
    
    const onSelect = ( e, id, name ) => {
        e.stopPropagation();
        getAssignedModel( id, name );
        setState( ( prev ) => ( {
            ...prev,
            makeId: id
        } ) );
    };
    // Delete a Make
    const deleteTypeMake = async ( makeId ) => {
        try {
            await util.axios.delete( `/amtypemakes/${makeId}` );
            util.toast().success( "Make deleted successfully" );
            await getAssignedMakes( state.typeId );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    return (
       
        <div className="row">
              
            {/* Type List (First Column) */}
        
            <div className="col-md-6 bg-white">
                <div className="w-100 border rounded p-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <b>Assets Makes</b>
                        <FontAwesomeIcon icon={faPlus} className="isCursor text-primary"
                            onClick={() => setState( { ...state, showAddMake: true, actionType: 1 } )}
                        />
                    </div>
                    <div className="list-group mt-2">
                        {makeList.length === 0 ? "" : (
                            makeList.map( ( make ) => (
                                <div key={make.id}    className={`d-flex justify-content-between align-items-center p-2 border rounded mt-1 mb-1 isCursor ${
                                    state.makeId === make.mk_id ? "activeBlock" : ""
                                }`}
                                onClick={( e ) => onSelect( e, make.mk_id, make.name )}>
                                    {make.make_name}
                                    <button className="btn btn-sm btn-outline-danger" onClick={() => deleteTypeMake( make.mk_id )}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            ) )
                        )}
                    </div>

                    {/* Add Make Input */}
                    {/* <div className="mt-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter new make"
                            value={newMake}
                            onChange={(e) => setNewMake(e.target.value)}
                        />
                        <button className="btn btn-sm btn-primary mt-1" onClick={addTypeMake}>
                            Add Make
                        </button>
                    </div> */}
                </div>
            </div> 
            {state.makeId && ( <div className="col-md-6 bg-white">
                <AsseMakesModel getAssignedModel={getAssignedModel} modelList={modelList} state={state} setState={setState} />
            </div> )}
            {state.showAddModel && (
                <AddMakesModal
                    show={state.showAddModel}
                    handleClose={() => {
                        setState( { ...state, showAddModel: false, addModel: false } );
                        setFormData( {} );
                    }}
                    data={allmodelList}
                    handleChange={handleChange}
                    inputModel={state.addModel}
                    cancelInput={() => setState( { ...state, addModel: false } )}
                    getAssignedModel={getAssignedModel}
                    saveMakeModel={saveMakeModel}
                    state={state}
                />
            )}
        </div>
    );
};

export default AssetTypeMakes;


const AddMakesModal = ( { state,data, show, handleChange, handleClose, saveMakeModel, inputModel, getAssignedModel, cancelInput } ) => {
    const [model, setModel] = useState( "" );
    function handleStatusInput ( e ) {
        const { value } = e.target;
        setModel( value );
    }

    async function submitModel() {
       
        let newModel = {  mk_id: state.makeId,
            model_name: model, };
        try {
            const request = await util.axios.post( '/glmodel', { newModel } );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            getAssignedModel(state.makeId);
            cancelInput();
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <div className="d-flex justify-content-center">
                    <b>Add Models</b>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {/* <div className="form-group">
                        <label htmlFor="statusSelector">Select Model</label>
                        <select className="form-control" id="statusSelector" name="status" onChange={handleChange}>
                            <option value=""></option>
                            {data.map( item => <option key={item.model_id} value={item.mk_id}>{item.model_name}</option> )}
                            <option className="bg-warning" value={"notOnList"}>Not on the List</option>
                        </select>
                    </div> */}
                    <div>
                        <div className="form-group">
                            <label htmlFor="statusSelector">Model Name</label>
                            <input className="form-control" name="model" value={model} onChange={handleStatusInput} />
                        </div>
                        <div className="d-flex justify-content-end">
                            <span className="btn btn-success sizeBtn" onClick={submitModel}>Save</span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn btn-outline-danger" onClick={handleClose}>
                    <FontAwesomeIcon
                        icon={faTimes}
                    />
                </span>
                <span className="btn btn-outline-primary" onClick={saveMakeModel}>
                    <FontAwesomeIcon
                        icon={faCheck}
                    />
                </span>
            </Modal.Footer>
        </Modal>
    );
};