import { Chip, CircularProgress, Drawer, Tooltip } from "@material-ui/core";
import React, { useEffect, useState, useCallback } from "react";
import ReactPlayer from "react-player";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faGlobe,
    faMapMarkedAlt,
    faMoneyBill,
    faMoneyBillAlt,
    faPhone,
    faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import DocumentViewer from "../../../utils/DocumentViewer";

const TalentDrawer = ( { show, handleClose, data } ) => {
    const [talentState, setTalentState] = useState( {} );
    const [skills, setSkills] = useState( [] );
    const [isPlaying, setIsPlaying] = useState( false );
    const [loading, setLoading] = useState( true );

    const handlePlayPause = () => setIsPlaying( !isPlaying );

    const getCandidateInformation = useCallback( async ( id ) => {
        setLoading( true );
        try {
            const response = await util.axios.get( `/candidates/${id}` );
            if ( response.data.error ) throw response.data.msg;
            setTalentState( response.data.candidate || {} ); // ✅ Ensure a default empty object
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    }, [] );

    const getCandidateSkills = useCallback( async ( candidate_id ) => {
        setLoading( true );
        try {
            const response = await util.axios.get( `/talent/skill_list/${candidate_id}` );
            if ( response.data.error ) throw response.data.msg;
            setSkills( response.data.data || [] ); // ✅ Ensure default empty array
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setLoading( false );
        }
    }, [] );

    const setProfilePicture = useCallback( async ( profile_picture, id ) => {
        const defaultAvatar = "/images/avatar.svg";
        const url = profile_picture ? `src/public/candidate/${id}/${profile_picture}` : defaultAvatar;
        try {
            const imageUrl = await util.fetchImageUrl( url );
            const profilePictureElement = document.getElementById( "candidateProfilePicture" );

            if ( profilePictureElement ) {
                profilePictureElement.setAttribute( "src", imageUrl || defaultAvatar );
            }
        } catch ( error ) {
            console.error( "Error setting profile picture:", error );
        }
    }, [] );

    useEffect( () => {
        if ( data?.candidate_id ) {
            getCandidateInformation( data.candidate_id );
            getCandidateSkills( data.candidate_id );
        }
    }, [data, getCandidateInformation, getCandidateSkills] );

    useEffect( () => {
        if ( talentState?.id ) {
            setProfilePicture( talentState.profile_picture, talentState.id );
        }
    }, [talentState, setProfilePicture] );

    return (
        <Drawer anchor="right" open={show} onClose={handleClose}>
            {loading ? (
                <div className="container pt-3 d-flex justify-content-center align-items-center" style={{ width: 0.45 * window.innerWidth }}>
                    <CircularProgress />
                </div>
            ) : (
                <div className="container pt-3" style={{ width: 0.45 * window.innerWidth }}>
                    {util.humanRole( util.user.role ) !== 'Partner' ? <a href={`/candidates/${data.candidate_id}?jp=true&job_id=${data.position_id}`} target="_blank" rel="noopener noreferrer">
                        <button className="btn btn-sm btn-secondary activeBtn sizeBtn mb-1">View more...</button>
                    </a> : "" }

                    {/* Video Profile */}
                    <div>
                        {talentState.video_profile ? (
                            <ReactPlayer url={talentState.video_profile} width="100%" height="15rem" controls playing={isPlaying} onPlay={handlePlayPause} onPause={handlePlayPause} />
                        ) : (
                            <strong>Video profile is not added!!</strong>
                        )}
                    </div>

                    {/* Personal Details */}
                    <div className="px-3 py-2 card my-1" id="personal-details-card" style={{
                        alignItems: "center",
                        justifyContent: "space-around"
                    }}>
                        <div className="d-flex text-start align-items-center" style={{ gap: "2rem" }}>
                            <div>
                                <img 
                                    width="150px"
                                    height="150px"
                                    alt="Profile"
                                    id="candidateProfilePicture"
                                    className="candidateProfile_picture"
                                    src=""
                                        
                                />
                                <div className="text-center">{talentState.name}</div>
                            </div>

                            <div className="text-start">
                                <Tooltip title="Job Title">
                                    <div>
                                        {talentState?.jobTitles?.length > 0
                                            ? talentState.jobTitles.map( ( el ) => <span key={el.id}>{el.name}</span> )
                                            : ""}
                                    </div>
                                </Tooltip>

                                <Tooltip title="Email">
                                    <div>
                                        <FontAwesomeIcon icon={faEnvelope} className="iconGray mr-1" />
                                        <a href={`mailto:${talentState.email ?? ""}`}>{talentState.email ?? ""}</a>
                                    </div>
                                </Tooltip>

                                <Tooltip title="Phone">
                                    <div>
                                        <FontAwesomeIcon icon={faPhone} className="iconGray mr-1" />
                                        <a href={`tel:${talentState.phone_mobile ?? ""}`}>{util.formatPhone( talentState.phone_mobile ?? "" )}</a>
                                    </div>
                                </Tooltip>

                                <Tooltip title="Location" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faMapMarkedAlt} className="iconGray mr-1"/>
                                        <span>{util.location( talentState.country, talentState.state, talentState.city )}</span>
                                    </div>
                                </Tooltip>

                                <Tooltip title="Relocation" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faGlobe} className="iconGray mr-1" />
                                        <span className='mr-1 text-secondary'>Relocation:</span>
                                        <span>{talentState.relocation ? "Yes": "No"}</span>
                                    </div>
                                </Tooltip>

                                {talentState.noticePeriod ? <Tooltip title="Relocation" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faSignOutAlt} className="iconGray mr-1" />
                                        <span className='mr-1 text-secondary'>Notice Period:</span>
                                        <span>{talentState.noticePeriod}</span>
                                    </div>
                                </Tooltip> : ""}

                                {util.humanRole( util.user.role ) !== 'Partner' && talentState.ctc ? <Tooltip title="Expected CTC" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faMoneyBillAlt} className="iconGray mr-1" />
                                        <span className='mr-1 text-secondary'>Expected CTC:</span>
                                        <span>{util.currencyFormat( talentState.ctc, talentState.country )}</span>
                                    </div>
                                </Tooltip> : ""}

                                {util.humanRole( util.user.role ) !== 'Partner' && talentState.current_salary ? <Tooltip title="Current Salary" placement="top">
                                    <div>
                                        <FontAwesomeIcon icon={faMoneyBill} className="iconGray mr-1" />
                                        <span className='mr-1 text-secondary'>Current Salary:</span>
                                        <span>{util.currencyFormat( talentState.current_salary, talentState.country )}</span>
                                        <span className="ml-1">{talentState.salary_mode}</span>
                                    </div>
                                </Tooltip> : ""}
                            </div>
                        </div>
                    </div>

                    {/* Skills */}
                    <div className="mt-2">
                        <h5>Skills:</h5>
                        <div className="d-flex flex-wrap">
                            {skills.length > 0 ? skills.map( ( el ) => <Chip key={el.catalog_skill_id} className="mr-1 mb-1" label={el.skill_name} /> ) : "No skills added"}
                        </div>
                    </div>

                    {/* Experience */}
                    {talentState?.workHistory?.length > 0 ? (
                        <div className="text-start w-100">
                            <h5>Experience:</h5>
                            {talentState.workHistory.map( ( el ) => (
                                <div className="card mb-1 d-flex" key={el.id}>
                                    <div className="card-body">
                                        <h5 className="card-title">{el.position}</h5>
                                        <p className="card-subtitle text-secondary">{el.company}</p>
                                        <p className="card-subtitle mb-2 text-secondary">{`${el.from} to ${el.to || "Present"}`}</p>
                                        <p className="card-text">{el.desc}</p>
                                    </div>
                                </div>
                            ) )}
                        </div>
                    ) : (
                        <strong>Experience is not added!!</strong>
                    )}

                    {talentState.resume ? <div>
                        <a href={util.getResume( talentState )} target="__blank" rel="noreferrer">
                           Download Resume
                        </a>
                        {( util.getResume( talentState ).split( '.' ).pop().toLowerCase() === "pdf" ? 
                            <DocumentViewer
                                fileType="pdf"
                                filePath={util.getResume( talentState )}
                            /> : 
                            <DocumentViewer
                                fileType="docx"
                                filePath={util.getResume( talentState )}
                            />
                        )}
                    </div>: <div><strong>Resume is not added!!</strong></div>}
                </div>
            )}
        </Drawer>
    );
};

export default TalentDrawer;
