import { Chip, MuiThemeProvider } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import util from "../utils/miniUtils";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import AddPaymentSchedule from "./AddPaymentSchedule";
import Swal from 'sweetalert2';

const PaymentSchedule = ( ) => {
    const [paymentSchedule, setPaymentSchedule] = useState( [] );
    const [paymentItems, setPaymentItems] = useState( [] );
    
    const [showModal, setShowModal] = useState( false );
    const [isEditing, setIsEditing] = useState( false );
    const [ memberName, setMemberName ] = useState( [] );
    const location = useLocation();
    const eliteData = location.state?.eliteData;
    const [paymentDetail, setPaymentDetail] = useState( {
        payment_item_id: null,
        value: "",
        comment: "",
        elite_connect_id:eliteData?.elite_connect_id,
        account_id: null,
    } );
    
    const fetchPaymentSchedule = async () => {
        try {
            const id = util.user.elite_connect_id || eliteData?.elite_connect_id;
            const response = await util.axios.get( `/elitepayment/${id}` );
            const { error, data } = response.data;

            if ( error ) {
                throw new Error( "Failed to fetch payment Schedule data" );
            }

            setPaymentSchedule( data );
        } catch ( err ) {
            console.error( err );
        }
    };
    useEffect( () => {
        fetchPaymentSchedule();
        getMembersName();
    }, [] );
    
    const handleModalClose = () => {
        setShowModal( false );
        
        setIsEditing( false );
    };
    const getMembersName = async() => {
        try {
            
            const elite_connect_id = util.user.elite_connect_id || eliteData?.elite_connect_id;
            const request = await util.axios.get( `eliteconnect/getmemberaccount/${elite_connect_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            
            setMemberName( data );
            
        } catch ( error ) {
            
            util.handleError( error );
        }
    };
    const handleChange = ( e ) => {

        const { name , value } = e.target;
        setPaymentDetail( {
            ...paymentDetail, [name]: value
        } );
    };
    const handleUpdatePayment = async () => {
        try{
            console.log( paymentDetail );    
            const request = await util.axios.put( `${util.url}/elitepayment/${paymentDetail.id}`, paymentDetail );
            const { error, message } = request.data;
            if ( error ) throw message;
            util.toast().success( message );
            setIsEditing( false );

            setPaymentDetail( {
                payment_item_id: null,
                value: "",
                comment: "",
                account_id: null
            } );
            setShowModal( false );
            fetchPaymentSchedule();
                    
        }catch( error ){
            util.handleError( error );
        }
    };
    const handleAddPayment =async () => {
        try{
            
            const request = await util.axios.post( `${util.url}/elitepayment/`, paymentDetail );
            const { error, message } = request.data;
            if ( error ) throw message;
            util.toast().success( message );
            // setLoading( false );
            // fetchPaymentItems();
            setPaymentDetail( {
                payment_item_id: null,
                value: "",
                comment: "",
                account_id: null
            } );
            setShowModal( false );
            fetchPaymentSchedule();
                    
        }catch( error ){
            util.handleError( error );
        }
    };

    const handleRowClick = ( e, rowData ) => {
        console.log( e, rowData );
        setIsEditing( true );
        setShowModal( true );
        setPaymentDetail( {
            ...rowData,
            account_id: memberName.find( member => member.name === rowData.memberName )?.id || null,
            payment_item_id: paymentItems.find( item => item.name === rowData.name )?.id || null
        } );
    };
    const deletePaymentItem = async ( id ) => {
        try {
            // setLoading( true );
            const request = await util.axios.delete( `${util.url}/elitepaymentschedule/${id}` );
                
            return request.data;
                
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const handleDelete = async ( id ) => {
        try {
            const result = await Swal.fire( {
                title: "Confirm Deletion",
                text: "Are you sure you want to delete this payment item?",
                // icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Delete",
            } );
                
            if ( result.value ) {
                const response = await deletePaymentItem( id );
                    
                if ( response.error ) {
                        
                    await Swal.fire( "Error", response.message , "error" );
                }else {
                    await Swal.fire( "Deleted", "The payment item has been deleted.", "success" );
                    fetchPaymentSchedule();
                }
        
                    
            }
        } catch ( error ) {
            await Swal.fire( "Error", "An error occurred while deleting the payment item.", "error" );
        }
    };
    const columns = [
        {
            title: "ID",
            field: "id",
            
        },
        // {
        //     title: "Elite Connect ID",
        //     field: "elite_connect_id",
        // },
        {
            title: "Member Name",
            field: "memberName",
        },
        // {
        //     title: "Payment Item ID",
        //     field: "elite_payment_item",
            
        // },
        {
            title: "Payment Item",
            field: "name",
            
        },
        
        {
            title: "Description",
            field: "description",
            
        },
        
        {
            title: "Value",
            field: "value",
            
        },
        {
            title: "Comment",
            field: "comment",
            
        },
        {
            title: "",
            field: "action",
            render: ( rowData ) => (
                util.user.role ===6  ? ( <div className="d-flex justify-content-space-between">
                    <div className="btn btn-link text-danger btn-sm" onClick={( e ) =>{
                        e.stopPropagation();
                        handleDelete( rowData.id );}}>
                        <FontAwesomeIcon icon={faTrash} /> 
                    </div>
                            
                            
                </div> ) : null
            )
                
        
        }
    ];
    const tableTitle = `Payment Schedule`;
    return (
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={columns}
                    title={tableTitle} // Use the updated title
                    data={paymentSchedule}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        sorting: true,
                        search: true,
                        // exportButton: true,
                        selection: true,
                        headerStyle: { backgroundColor: "#f5f5f5", fontWeight: "bold" },
                        maxBodyHeight: "60vh",
                    }}
                    onRowClick={handleRowClick}

                    actions={
                        [
                            util.user.role ===6 &&  {
                                icon: () => (
                                    <div className="btn btn-secondary btn-sm activeBtn ">
                                        <FontAwesomeIcon icon={faPlusCircle} /> Payment Schedule
                                    </div>
                                ),
                                tooltip: "Add Payment",
                                isFreeAction: true,
                                onClick: () => setShowModal( true ),
                            },
                        ]
                                                
                    }
                />
            </MuiThemeProvider>
            <AddPaymentSchedule 
                showModal={showModal}
                setShowModal={setShowModal}
                paymentDetail={paymentDetail}
                handleChange={handleChange}
                handleAddPayment={handleAddPayment}
                handleModalClose={handleModalClose}
                isEditing={isEditing}
                handleUpdatePayment={handleUpdatePayment}
                memberName={memberName}
                setPaymentDetail={setPaymentDetail}
                paymentItems={paymentItems}
                setPaymentItems={setPaymentItems}
            />
        </>
    );
};

export default PaymentSchedule;