import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import util from "../../utils/miniUtils";

const AsseMakesModel = ( { getAssignedModel,modelList, selectedType ,setState,state } ) => {
    const [newMake, setNewMake] = useState( "" );
  
     

    // Add a new Make
    const addTypeMake = async () => {
        if ( !newMake.trim() || !selectedType ) return;
        try {
            await util.axios.post( "/amtypemakes", { type_id: selectedType.id, name: newMake } );
            setNewMake( "" ); // Reset input field

            util.toast().success( "Make added successfully" );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Delete a Make
    const deleteMakeModel = async ( modelId ) => {
        try {
            await util.axios.delete( `/glmodel/${modelId}` );
            util.toast().success( "Make deleted successfully" );
            await getAssignedModel( state.makeId );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    return (
        <div className="w-100 border rounded p-2">
          
         
            <>
             
                <div className="d-flex justify-content-between align-items-center">
                    <b>Assets Model</b>
                    <FontAwesomeIcon icon={faPlus} className="isCursor text-primary"
                        onClick={() => setState( { ...state, showAddModel: true, actionType: 1 } )}
                    />
                </div>
                <div className="list-group mt-2">
                    {modelList.length === 0 ? "" : (
                        modelList.map( ( model ) => (
                            <div key={model.md_id} className="list-group-item d-flex justify-content-between align-items-center" 
                            >
                                {model.model_name}
                                <button className="btn btn-sm btn-outline-danger" onClick={() => deleteMakeModel( model.md_id )}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        ) )
                    )}
                </div>

                {/* Add Make Input */}
                {/* <div className="mt-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter new make"
                            value={newMake}
                            onChange={(e) => setNewMake(e.target.value)}
                        />
                        <button className="btn btn-sm btn-primary mt-1" onClick={addTypeMake}>
                            Add Make
                        </button>
                    </div> */}
            </>
          
            
        </div>
    );
};

export default AsseMakesModel;
