import React, { Fragment } from "react";
import { MuiThemeProvider, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faFilter, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import util from "../../../../utils/miniUtils";
import MaterialTable from "material-table";

export default function ListOfTalentsToSubmit( disabled, handleSelectChange, selectOption, job, checkBoxOptions, isResumeAvailable, talents, setSelectedTalents ) {
    
    return <>
        <div className="d-flex justify-content-center">
            <h3>Submit talents to</h3>
            <select disabled={disabled} onChange={handleSelectChange} value={selectOption} name="submit_type"
			        id="submit_type" className="form-control w-25 ml-2">
                <option value="0">Select user</option>
                <option value="2">Partner</option>
                <option value="1">Client</option> 
                <option value="3">Everest</option> 
            </select>
        </div> 
        {selectOption === 0 ?
            ""
            :
            <Fragment>
                {selectOption === 2 || ( job["client_id"] !== 0 && job.partner_id === 0 ) ?
                    <div>
                        <input onChange={checkBoxOptions} type="checkbox" name="sell_rate" id="sell_rate"
						       className="mr-2"/>
                        <label htmlFor="sell_rate">Sell Rate</label>
                        <Tooltip
                            title="By checking this option, sell rate for each talent will be included in the email">
                            <span className="text-primary"><FontAwesomeIcon icon={faQuestionCircle}
							                                                className="ml-2"/></span>
                        </Tooltip>
                    </div> : ""}
				
                <div>
                    <input onChange={checkBoxOptions} type="checkbox" name="contact_information"
					       id="contact_information" className="mr-2"/>
                    <label htmlFor="contact_information">Contact Information</label>
                    <Tooltip
                        title="Checking this option, contact information for each talent will be included in the email">
                        <span className="text-primary"><FontAwesomeIcon icon={faQuestionCircle}
						                                                className="ml-2"/></span>
                    </Tooltip>
                </div>
                <hr/>
                <MuiThemeProvider theme={util.defaultTableTheme}>
                    <MaterialTable
                        title="Jobs positions"
                        columns={[
                            {
                                title: "CB",
                                field: "resume",
                                render: ( row ) => {
                                    return (
                                        isResumeAvailable( row )
                                    );
                                },
                            },
                            {
                                title: "Name",
                                field: "name",
                                defaultSort: "asc",
                                render: ( row ) => {
                                    return (
                                        <span onClick={() => null}>
                                            {row.name}
                                        </span>
                                    );
                                },
                            },
                            {
                                title: "Email",
                                field: "email",
                                render: ( row ) => {
                                    return (
                                        <a href={`mailto:${row.email}`} className="text-decoration-none"
										   onClick={() => null}>
                                            {row.email}
                                        </a>
                                    );
                                },
                            },
                            {
                                title: "Location",
                                field: "name",
                                defaultSort: "asc",
                                render: ( row ) => {
                                    return (
                                        util.location( row.country, row.state, row.city )
                                    );
                                },
                            },
                            {
                                title: "Salary Expectation",
                                field: "sale_rate",
                                defaultSort: "asc",
                                render: ( row ) => {
                                    return (
                                        row.sale_rate
                                    );
                                },
                            },
                            {
                                title: "Partner",
                                field: "name",
                                hidden: job.deal_type === "Direct Client" || job.partner_id === 0,
                                defaultSort: "asc",
                                render: ( row ) => {
                                    return (
                                        row["partner_submit_date"] !== null ?
                                            <div className="text-center">
                                                <Tooltip
                                                    title={`Sent to partner on ${util.humanDate( row["partner_submit_date"] )}`}>
                                                    <span><FontAwesomeIcon icon={faCheckCircle}
													                       className="text-success"/></span>
                                                </Tooltip>
                                            </div>
                                            : ""
                                    );
                                },
                            },
                            {
                                title: "Client",
                                field: "name",
                                defaultSort: "asc",
                                render: ( row ) => {
                                    return (
                                        row["client_submit_date"] !== null ?
                                            <div className="text-center">
                                                <Tooltip
                                                    title={`Sent to client on ${util.humanDate( row["client_submit_date"] )}`}>
                                                    <span><FontAwesomeIcon icon={faCheckCircle}
													                       className="text-success"/></span>
                                                </Tooltip>
                                            </div>
                                            : ""
                                    );
                                },
                            },
                        ]}
                        data={talents}
                        options={{
                            showSelectAllCheckbox: false,
                            selection: true,
                            pageSize: 10,
                            sorting: true,
                            showTitle: false,
                            emptyRowsWhenPaging: false,
                            selectionProps: rowData => ( {
                                disabled: rowData[selectOption === 1 ? "client_submit_date" : "partner_submit_date"] !== null
                            } ),
                        }}
                        onSelectionChange={( row ) => {
                            // Check on every change on checkboxes
                            setSelectedTalents( Object.assign( [], row ) );
                        }}
                        // onRowClick={(event, row) => {
                        // 	openModalJD(row)
                        // }}
                        actions={[
                            {
                                icon: () => (
                                    <div className={"btn btn-default"}>
                                        <FontAwesomeIcon icon={faFilter} id="addRequirementIcon" className="mr-2"/>
										Filter
                                    </div>
                                ),
                                isFreeAction: true,
                                hidden: true,
                                onClick: () => null,
                            }
                        ]}
					
                    />
                </MuiThemeProvider>
            </Fragment>
        }
    </>;
}

