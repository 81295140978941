import React, { useEffect, useState } from 'react';
import util from '../utils/miniUtils';
import { CircularProgress, Paper } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import Register from './Register';
import mailImage from "../../assets/images/email_verification.png";

import TermsAndCondition from './TermsAndCondition';


const Verification = () => {
    const [isLoading, setIsLoading] = useState( true );
    const [eliteData, setEliteData] = useState( '' );
    const [ resend, setResend ] = useState( false );
    const location = useLocation();
    const [timer, setTimer] = useState( 30 );
    const [canResend, setCanResend] = useState( false );
    const [invitationId, setInvitationId] = useState( null );

    useEffect( () => {
        const queryParams = new URLSearchParams( location.search );
        const get_token = queryParams.get( "token" );
        if ( !get_token ) {
            
            throw new Error( 'Invalid Or Missing Token' );
        }
        const validateToken = async () => {
            try {
                const response = await util.axios.post( `/eliteconnect/invitation/validate/?token=${get_token}` );
                const { data, error, elite_data, msg, isResend  } = response.data;
                if( error ) {
                    if( !isResend ){
                        // window.history.replaceState( "", "", "/" );
                        // window.location.reload();
                        throw new Error( msg );
                    }
                    setResend( true );
                    setInvitationId( data );
                    util.toast().success( msg );
                    setIsLoading( false );
                }
                util.toast().success( msg );

                setEliteData( elite_data );
                setIsLoading( false );
            } catch ( error ) {
                util.handleError( error );
                setIsLoading( false );
                setIsLoading( false );
                window.history.replaceState( "", "", "/" );
                window.location.reload();
            }
        };
        validateToken();
    }, [] );
    useEffect( () => {
        let countdown;
        if ( timer > 0 ) {
            countdown = setInterval( () => setTimer( ( prev ) => prev - 1 ), 1000 );
        } else {
            setCanResend( true );
        }
        return () => clearInterval( countdown ); // Cleanup the interval on component unmount
    }, [timer] );
    const ResendEmail = async () => {
        try{
                
            const request = await util.axios.post( `${util.url}/eliteconnect_verification/resend_token`, { invitation_id: invitationId } );
            
            const { error, msg } = request.data;
            setTimer( 30 );
            setCanResend( false );
            if ( error ) throw msg;
            util.toast().success( msg );
        } catch ( e ) {
            // PositionsStore.update( s => { s.loading = false; } );
            util.handleError( e );
        }
           
    
    };
    if ( isLoading ) {
        return (
            <div className="loading-container">
                <CircularProgress className="loading-spinner" size={50} />
                <h1 className="loading-title">We're verifying your account...</h1>
                <p className="loading-description">
                This process may take a few moments. Please wait while we confirm your account details.
                </p>
            </div>
        );
    }
    if( resend ) {
        return (
            <div className="text-center mt-5">
                <Paper
                    elevation={4}
                    className="paper-container d-flex flex-column justify-content-center align-items-center"
                >
                    <div className="text-center">
                        <img
                            src={mailImage}
                            alt="Verification Email"
                            className="email-image mb-4"
                        />
                    </div>
                    <h2 className="verify-header">
                    Verify Your Email Address
                    </h2>
                    <p className="verify-description">
                    Click the resend button to get a new verification link and confirm your email address.
                    </p>
                    <div className="mb-3">
                        <button
                            className="btn btn-secondary sizeBtn activeBtn"
                            onClick={ResendEmail}
                            disabled={!canResend}
                        >
                        Resend Email
                        </button>
                        <div>{!canResend && `in ${timer}s`}</div>
                    </div>
                    <p className="verify-footer">
                    Didn’t receive the email? Check your spam folder or click the button above to resend it.
                    </p>
                </Paper>
            </div>
        );
        
    }
    return (
        <div>
            {eliteData.accepted_at ?   <Register email={eliteData.user_email} />:<TermsAndCondition eliteData={eliteData}/> }
            {/* <Register email={email} /> */}
            
        </div>
    );
};

export default Verification;